const exports = {
    " ": " ",
    go_back: "Go Back",
    notifications: "Notifications",
    settings: "Settings",
    logout: "Logout",
    logged_out_error: "You have been logged out, please sign in and try again.",
    logged_out_success: "You have been successfully logged out. See you again soon!",
    confirm_logout_text: "Are you sure you want to logout?",
    ok: "Ok",
    error: "Error",
    no: "No",
    yes: "Yes",
    language: "Language",
    account: "Account",
    login: "Sign in",
    cancel: "Cancel",
    store: "Store",
    products: "Products",
    restaurant: "Restaurant",
    groupsale: "Group Sale",
    travel: "Travel",
    shopping: "Shopping",
    service: "Service",
    save: "Save",
    confirm: "Confirm",
    success: "Success",
    loading: "Loading",
    warning: "Warning",
    general_error: "An unknown error has occurred, please try again later",
    orders: "My Orders",
    click_to_input_address: "Please click to input address",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    input: "Input",
    edit: "Edit",
    years: "Yrs",
    quarters: "Qts",
    months: "Mths",
    weeks: "Weeks",
    days: "Days",
    days_2: "Days",
    time: "Time",
    times: "Times",
    hours: "Hrs",
    minutes: "Mins",
    seconds: "Secs",
    milliseconds: "Ms",
    sort: "Sort",
    filter: "Filter",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    this_week: "This week",
    last_week: "Last week",
    this_month: "This Month",
    last_month: "Last Month",
    last: "Last",
    next: "Next",
    now: "Now",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    mo: "Mo",
    tu: "Tu",
    we: "We",
    th: "Th",
    fr: "Fr",
    sa: "Sa",
    su: "Su",
    share: "Share",
    dont_show_this_again: "Don't show this again",
    update_order: "Update Order",
    your_order: "Your Order",
    schedule_for_later: "Schedule for later",
    ready_in_asap: "Ready in {start}-{end} minutes",
    when_would_you_like_to_order: "When would you like to order",
    no_shipping_address_selected: "No shipping address selected.",
    change: "Change",
    start_order: "Start Order",
    order_for: "Order for",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    view_more: "View More",
    viewing: "Viewing",
    show_filters: "Show Filters",
    reward_history_filter: "Reward History Filter",
    date_range: "Date Range",
    my_referrals: "My Referrals",
    how_to_do_it: "How to receive reward points, and reward points rules",
    how_to_do_it_referrals:
        // eslint-disable-next-line no-template-curly-in-string
        "1. Share the referral link or QR code with your friends and on social media. They will become your referral if they were not previously referred by someone else and they make a purchase using your link. You will receive {v2pCustomerRef} reward points for every dollar on their purchase and future purchases. First time purchase made by your referral earns you reward points equal to value before tax * {v2pCombined} (e.g. if the before tax order amount is ${exampleBasePurchase}, your reward points will be: {exampleBasePurchase} * {v2pCombined} = {exampleResultPoints}). and each order made by your referral after the first will earn you reward points equivalent to the before tax dollar value of his order * {v2pCustomerRef}. If you share the link with someone who has been referred already you will receive one time reward points {v2pOrderRef} for every dollar they spend on their current purchase.{br}{br}2. For example: If A refers B, who has not been previously referred to by others, then A will receive the points from B's first order and will receive reward points for every order B makes afterwards equivalent to his order value before tax * {v2pCustomerRef}. If B has already been referred to by others, A will still receive a one-time reward point based on B's one time order.{br}{br}3. Every {p2v} reward point is earned from every $1 purchase at this store.",
    copy_ref_url: "Copy Referral URL",
    total: "Total",
    generate_qr_code: "Generate QR Code",
    ref_orders: "Orders",
    income: "Income",
    check_how_much_money_earned: "Check how much money you've earned",
    check_how_much_rewards_earned: "Check how many reward points you've earned",
    redeemable: "Redeemable",
    total_redeemed: "Total Redeemed",
    sales: "Sales",
    check_how_many_sales: "Check how many sales you've made",
    rewards_history: "Rewards History",
    check_daily_history: "Check your daily reward transactions",
    orders_info: "Check how many orders your friends have placed",
    referrals_info: "Referrals Information",
    referrals_info_desc: "This is your referrals information. Tap on each one to get orders and sales information",
    admin_reward_action: "Admin Reward Points",
    points_redeemed: "Redeemed Points",
    customer_invitation: "Customer Referral Points",
    order_invitation: "Order Referral Points",
    admin_credit: "Admin Credit",
    sales_rule: "Sales Rule Points",
    admin_revert_points: "Admin Revert Points",
    refunded_order: "Refunded Order Points",
    ref_successfully_copied: "Referral Code Copied Successfully",
    click_tips_with_helper_text: "Tips(click amount to update)",
    click_set_points: "Click to redeem points",
    click_to_search_address: "Click to search address",
    click_please_input_shipping_addresss: "Click to input shipping address",
    click_please_input_pickup_addresss: "Click to choose pick-up location",
    click_please_input_payment_method: "Click to Select Payment Method",
    click_to_show_on_map: "Click here to show on map",
    tap_to_search_address: "Tap to search address",
    sorry_search: "No search result for the specified keyword.",
    try_again_search: "Please try another search keyword",
    recent_searches: "Recent Searches",
    recommendations: "Recommendations",
    deals: "Deals",
    view_all: "View All",
    products_not_available: "The following item(s) are not available now, and will be removed from your cart:",
    shopping_cart_empty: "Your Shopping Cart is empty.",
    not_available_dialog_title: "Not Available Now",
    not_available_dialog_captain: "This item is only available in the following hours.",
    sorry_product_not_available: "Sorry, this item is only available in the following hours.",
    feedback: "Feedback",
    download_qr: "Download the QR Code",
    download_qr_mobile: "Long press the image to download",
    download_poster: "Download the poster",
    download_poster_mobile: "Long press the image to download",
    rate_the_product: "Rate The Product",
    are_you_sure_cancel_review: "Are you sure you want to discard this review?",
    submit: "Submit",
    review_order_placeholder: "Your review will help people make better choices",
    review_submitted_message: "Thanks for your review, it will be published once validated",
    please_input_or_select: "Please input or select your reason.",
    review_service: "Review Service",
    rate_hint: "Tap star icon to rate",
    not_available: "Not Available",
    current_not_available: "Currently not available",
    allow_reservation: "Allow Reservation",
    has_tv: "Has TV",
    has_free_wifi: "Has Free Wi-Fi",
    has_outdoor_seating: "Has Outdoor Seating",
    kid_friendly: "Kid Friendly",
    group_friendly: "Group Friendly",
    business_friendly: "Business Friendly",
    street_parking: "Street Parking",
    free_parking: "Free Parking",
    paid_parking: "Paid Parking",
    noise_level: "Noise Level",
    average: "Average",
    loud: "Loud",
    very_loud: "Very Loud",
    casual: "Casual",
    business_casual: "Business Casual",
    smart_casual: "Smart Casual",
    business: "Business",
    semi_formal: "Semi Formal",
    has_alcohol: "Has Alcohol",
    environment: "Environment",
    attire: "Attire",
    upscale: "Upscale",
    classy: "Classy",
    hipster: "Hipster",
    intimate: "Intimate",
    no_alcohol: "No Alcohol",
    beer_and_wine: "Beer & Wine",
    full_bar: "Full Bar",
    write_your_review_here: "Write your review here",
    rate_the_store: "Rate the store",
    more_information: "More Information",
    merchant_information: "Merchant Information",
    additional_pages: "Additional Pages",
    store_info: "Store Info",
    store_overview: "Overview",
    opens: "Opens",
    closes: "Closes",
    current_store: "Current Store",
    order: "Order",
    my_order: "My Order",
    deliver_to: "Deliver to",
    delivery: "Delivery",
    check_out: "Check out",
    options: "Options",
    add_to_cart: "Add To Cart",
    pick_one: "Required, pick 1",
    pick_amount: "Required, pick exactly {min}",
    pick_range_up_to: "Choose up to {max}",
    pick_range_required: "Required, pick {min}-{max}",
    pick_optional: "Optional",
    pick_one_with_name: "{nm} is required, pick 1",
    pick_amount_with_name: "{nm} is required, pick exactly {min}",
    pick_range_up_to_with_name: "{nm} choose up to {max}",
    pick_range_required_with_name: "{nm} is required, pick {min}-{max}",
    up: "Up",
    search_products: "Search Products",
    current_menu: "Current Menu",
    all_menus: "All Menus",
    search: "Search",
    discount_by: "{x} off on {y}",
    discount_by_without_price: "{x} off",
    store_closed: "Closed",
    store_closed_not_allow_order: "Store is closed and currently not accepting new orders.",
    order_items: "Order Items",
    are_you_sure_clear_cart: "Are you sure you want to clear your cart?",
    are_you_sure_keeping_cart: "There are saved items in the cart. Do you want to keep the items in the cart?",
    clear_the_cart: "Clear The Cart",
    keep_the_cart: "Keep",
    qr_code: "QR Code",
    poster: "Poster",
    clear: "Clear",
    results: "Results",
    search_results: "{x} search results",
    pending_acceptance: "Pending Acceptance",
    delivery_time: "Delivery time",
    pickup: "Pick-up",
    takeout: "Pick-up",
    eatin: "Dine in",
    order_pickup_time: "Pick-up time: {time}",
    order_delivery_time: "Delivery time: {time}",
    transferred_to: "Transferred to: {contact}",
    received_from: "Received from: {contact}",
    discount_amount: "{currentDiscount} off.",
    remaining: " Add {remaining} more to get {amount} off.",
    remaining_delivery: " Add {remaining} for delivery.",
    remaining_pickup: " Add {remaining} for pick-up.",
    menu_available_at: "This menu is only available @{available}…",
    please_input_name: "Please input name",
    no_address_head: "No Saved Addresses",
    no_address_message: "You currently do not have any address records.",
    select_branch: "Please Choose a Branch",
    store_on_hold: "Store is on hold and currently not taking any orders.",
    out_of_stock: "Out of stock",
    load_more: "Load More",
    no_order_message: "Orders will appear here after you have placed an order.",
    no_order: "No Orders",
    create_an_order: "Place an Order",
    no_more_data: "No more data",
    no_search_results: "No Search Results",
    group_purchase: "Group Purchase",
    show_more: "Show More",
    show_less: "Show Less",
    purchase_history: "Purchase History",
    start_time: "Start Time",
    end_time: "End Time",
    expired: "Expired",
    offer_expires_in: "Offer Expires In: {time}",
    offer_starts_in: "Offer Starts In: {time}",
    offer_starts_on: "Start Time: {time}",
    offer_ends_on: "End Time: {time}",
    remaining_stock: "{count} remaining",
    people_ordered: "{amt} people ordered",
    select_category: "Select Category",
    selection_error_min: "{min} selections is required for {option}.",
    no_giftcard_message: "Giftcards you own will appear here.",
    upsell_products: "You might also like...",
    switch_to_dine_in: "Confirm Dining In",
    switch_to_in_store: "Confirm In Store",
    switch_to_dine_in_message:
        "Please input your table number to proceed with dine in order, tap cancel to stay with {type} order type.",
    surcharge: "Surcharge",
    store_surcharge: "Online Surcharge Fee",
    order_surcharge: "Order Surcharge",
    item_total: "Item(s) Total",
    surcharge_paid: "Surcharge (Paid)",
    surcharge_unpaid: "Surcharge (Unpaid)",
    related_id: "Related Order #",
    related: "Related",
    surcharege_paid_message: "The surcharge amount is not included in this order. Please refer to order #{id}",
    surcharge_paid_to_message: "This order is to pay for the surcharge of order #{id}",
    switch_to_dine_in_confirm:
        "It seems you are currently not in the store, are you sure you want to do dine-in order?",
    switch_to_in_store_confirm:
        "It seems you are currently not in the store, are you sure you want to do in-store order?",
    pull_to_refresh: "Pull to Refresh",
    no_products_record: "No products found.",
    delivery_locations: "Delivery Zones / Schedule",
    pickup_locations: "Pick-up Locations",
    min_amount: "Min. Order",
    min_order_amount: "Min. Order Amount",
    free_delivery_amount: "Free Delivery Amt",
    delivery_times: "Delivery Times",
    pickup_times: "Pick-up Times",
    time_range: "Time Range",
    store_location: "Store Location",
    pickup_locations_schedule: "Pick-up Locations / Schedule",
    roll_over_image_to_zoom_in: "Roll over image to zoom in",
    about: "About",
    product_information: "Product Short Description",
    product_details: "Product Details",
    buy_now: "Buy Now",
    see_more_product_details: "See more product details",
    add_to_cart_success: "Product has been successfully added to the cart",
    video: "Video",
    page_of: "Page {page} of {totalNumOfPages}",
    warning_max_purchase_limit_reached: "Warning: Maximum purchase limit reached",
    max_purchase_limit_reached_msg: "You may only purchase {max_sale_qty} of this product.",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error: "The store has accepted this order. Please pay in person.",
    order_comment_status_error:
        "The store has started preparing this order; please contact the store for any change request. Tel: {phone}.",
    oos_items_cannot_be_added: "The item(s) {oos_items} are not available and could not be added to the cart.",
    order_cancelled: "Order Cancelled",
    pending_store_confirmation: "Pending Store Confirmation",
    pending_customer_confirmation: "Pending Customer Confirmation",
    store_accepted: "Order is accepted",
    order_preparing: "Order is being prepared",
    store_is_preparing: "Store preparing",
    ready_pickup: "Order is ready for pick-up",
    ready_courier_pickup: "Order is ready for delivery",
    rejected_by_courier: "Rejected by courier",
    courier_assigned: "Courier assigned",
    courier_accepted: "Courier accept the task",
    courier_heading_to_store: "Courier heading to the store",
    courier_arrived_at_store: "Courier arrived at the store",
    courier_collected_package: "Courier collected the package",
    courier_heading_to_customer: "Order is being delivered",
    courier_arrived_at_customer: "Courier arrived at customer",
    delivered_order: "Delivered order",
    order_changes_description:
        "Changes have been made to your order. Please refer to the suggested changes and confirm ASAP to avoid any potential delay of your order.",
    confirm_item_change: "Confirm Order Change",
    are_you_sure_reject:
        "Reject the suggested change will result in removing this item from your order, are you sure you want to continue?",
    accepted: "Accepted",
    rejected: "Rejected",
    added: "Added",
    removed: "Removed",
    updated: "Updated",
    add_back: "Add Back",
    to: "to",
    updated_an_item: "Updated an item",
    added_an_item: "Added an item",
    removed_an_item: "Removed an item",
    replaced_an_item: "Replaced an item",
    are_you_sure_change_order_confirm: "Are you sure you want to confirm the changes to this order?",
    order_change_return_price_delta:
        "The total amount for the updated order is {new_price}, you will be refunded the difference upon completing the order.",
    order_change_pay_price_delta:
        "The total amount for the updated order is {new_price}, the final charge to your card will be this amount upon completing the order.",
    order_change_pay_new_price:
        "The total amount for the updated order is {new_price}, you will need to pay the price difference of {price_delta}. Please make the payment for the surcharge to avoid delaying your order.",
    order_change_reject_all: "You have rejected all the suggested changes. Are you sure you want to cancel the order?",
    order_change_reject_all_refund:
        "You have rejected all the suggested changes. Are you sure you want to cancel the order? Upon cancelling you will be refunded {price_delta}.",
    order_change_refund:
        "The total amount for the updated order is {new_price}, you will be refunded the price difference of {price_delta}.",
    order_again_adds_items: "[Order Again] adds the same items to your cart automatically.",
    hide_menu: "Hide Item",
    show_menu: "Show Item",
    copy: "Copy",
    copied_success: "Copied Order # to Clipboard",
    shipping_time: "Shipping Time",
    order_time: "Order Time",
    pickup_time: "Pick-up Time",
    request_due_time: "Due Time",
    requested_pickup_time: "Req. Pick-up Time",
    requested_delivery_time: "Req. Delivery Time",
    ready_pickup_time: "Ready For Pick-up time",
    estimated_delivery_time: "Estimated Delivery Time",
    store_name: "Store Name",
    shipping_info: "Shipping Info",
    order_info: "Order Info",
    table_info: "Table Info",
    serve_time: "Estimated ready time",
    buzz: "Buzz",
    order_detail: "Order Detail",
    alipay: "Alipay",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Credit Card",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",

    paypal_complete_payments:"Credit Card",
    paypal_complete_payments_credit_card:"Credit Card",
    paypal_complete_payments_apple_pay:"Apple Pay",
    paypal_complete_payments_google_pay:"Google Pay",
    paypal_complete_payments_paypal:"PayPal",

    
    paypal_express: "PayPal Express",
    etransfer: "E-Transfer/EMT",
    emt_description: "Pay with E-Transfer/EMT after placing order",
    payment_received: "Payment Received",
    payment_pending: "Payment Pending",
    payment_cancelled: "Payment Cancelled",
    payment_failed: "Payment Failed",
    emt_payment_note_long:
        "You selected E-Transfer/EMT payment for this order. Please arrange payment to the store at your earliest convenience and notify the store to avoid delays with your order.",
    emt_payment_password: "Please send {amount} to {emt_account} with the payment password {emt_password}",
    emt_payment_account: "Please send {amount} to {emt_account}",
    free: "No payment required",
    dine_in: "Dine In",
    in_store: "In Store",
    flatrate: "Flat Rate",
    freeShipping: "Digital Asset",
    instantCheckout: "Instant Checkout",
    pending: "Pending Confirmation",
    processing: "Processing",
    payment_review: "Reviewing Payment",
    completed: "Completed",
    canceled: "Cancelled",
    closed: "Closed",
    pending_payment: "Pending Payment",
    holded: "Holded",
    cancel_order: "Cancel Order",
    order_number: "Order #",
    order_status: "Order Status",
    order_date: "Order Time",
    order_type: "Order Type",
    shipping_method: "Shipping Method",
    expected_time: "Due Time",
    customer: "Customer",
    table_number: "Table #",
    party_size: "Party Size",
    delivery_address: "Delivery Address",
    pickup_location: "Pick-up Location",
    store_discount: "Store Discount",
    coupon: "Coupon",
    shipping_and_handling: "Shipping & Handling",
    shipping_and_handling_with_distance: "Delivery Fee ({distance})",
    order_total: "Total",
    giftcard_payment: "Gift Card Payment",
    points_payment: "Points Payment",
    points_payment_with_points: "Points Payment ({x})",
    comments: "Comments",
    reorder: "RE-ORDER",
    general_order_error: "An error has occurred. Please go back to the order list page and try again.",
    order_unexist: "No order record was found.",
    you: "You",
    cancel_order_warning: "Are you sure you want to cancel this order?",
    cancel_order_success: "Your order #{order_id} with {store_name} has successfully been cancelled.",
    cancel_order_failed: "Failed to cancel order, please try again later",
    pay_now_unavailable: "Online Payment Unavailable",
    no_online_payment:
        "Sorry, the store doesn't accept online payments at the moment. Please try again later or contact the store directly.",
    pay_now: "Pay Now",
    reviews: "Reviews",
    write_review: "Write a Review",
    write_review_for: "Write your review for {name}.",
    update_review: "Update Review",
    overall: "Overall",
    add_a_review: "Add a review",
    rate_our_service: "Rate our service",
    rate_our_delivery: "Rate our delivery",
    post: "Post",
    requested_time: "Requested time",
    scheduled_time: "Scheduled time",
    //wechat
    exp_dt: "Due Time",
    ord_total: "Amount",
    s_method: "Order Type",
    p_method: "Payment Method",
    start_processing_message: "Are you sure you want to mark order(s) as processing?",
    mark_as_complete_message: "Are you sure you want to mark order(s) as complete?",
    cancel_order_message: "Are you sure you want to cancel order(s)?",
    complete: "Complete",
    reject: "Reject",
    total_refunded: "Total Refunded",
    refund_amount: "Amount",
    refund_all: "Refund All",
    remark: "Remark",
    "edit-shipping-fee-message":
        "Update shipping fee will result in cancellation of previous order and create a new order with updated shipping cost, do you want to continue?",
    edit_shipping_info: "Edit Shipping address",
    additional_info: "Additional Information",
    exp_time: "Requested Deli Time",
    estimatedDeliTime: "Estimated Deli Time",
    "create-new-order-message": "Are you sure? This order will be canceled and a new one will be created instead.",
    order_and_account_information: "Order & Accnt Info",
    address_info: "Address Information",
    shipping_address: "Shipping Address",
    payment_and_shipping_method: "Payment & Shipping Method",
    payment_info: "Payment Information",
    shipping_handling_info: "Shipping Handling Information",
    item_order: "Items Ordered",
    raw_total: "Raw Total",
    notes_for_this_order: "Notes for this order",
    alert_on_notification: "Alert on Notification",
    sub_comment: "Submit Comment",
    modify_discount: "Modify Discount",
    add_discount: "Add Discount",
    modify_tips: "Modify Tips",
    add_tips: "Add Tips",
    modify_shipping_fee: "Modify Shipping Fee",
    discount: "Discount",
    edit_payment: "Update Payment",
    edit_extra: "Update Info",
    edit_payment_method: "Update Payment",
    "select_receipt(s)_to_print": "Select Receipt(s) to print",
    change_payment_to: "Are you sure you want to change payment method to ",
    handling: "Handling",
    coupon_discount: "Coupon Discount",
    phone: "Phone Number",
    email: "Email",
    email_receipt: "Email Receipt",
    email_receipt_sent_to: "Email Receipt Sent To",
    unpaid: "Unpaid",
    admin: "Admin",
    cust: "Customer",
    other_payment: "Other payments",
    access_denied: "Sorry, you do not have permission to view this order",
    please_pay_now: "Order is not paid yet, you may pay now.",
    are_you_sure_cancel_order: "Are you sure you want to cancel the order?",
    please_pay_by_x: "Your order will be held for {x}. Pay now to prevent cancellation",
    reward: "Reward",
    reward_points: "Reward Points",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    shipment_tracking_info: "Shipment Tracking Information",
    carrier: "Carrier",
    tracking_number: "Tracking Number",
    register: "Sign up",
    vc_login: "Sign in via SMS",
    resend: "Resend",
    pwd_login: "Account sign in",
    email_phone_placeholder: "Phone number or email",
    password: "Password",
    vc: "Verification Code",
    get_vc: "Request Code",
    titleS: "Goopter",
    alert_login_succeed: "Login Successful",
    alert_phone_not_found: "This phone number has not been registered yet!",
    alert_too_many_request:
        "You have reached the limit for requesting the verification code. Please try again in 15 minutes.",
    alert_phone_length: "Invalid phone number!",
    alert_phone_vc_not_match: "Incorrect verification code, please try again.",
    alert_login_failed_general: "Login failed, please try again later.",
    alert_login_failed: "Invalid Username/Password.",
    forget_pwd: "Forget Password",
    state_reg:
        "If you have not registered on our website yet, our system will create an account for you after login via SMS",
    cos_privacy_statement: "By logging in, you agree to Goopter's {cos} and {privacy}",
    cos_privacy_statement_register: "By signing up, you agree to Goopter's {cos} and {privacy}",
    cos: "Terms of Use",
    privacy: "Privacy Notice",
    or_login_with_social: "or login with a social account",
    social_redirect_text:
        "In order to login with your social account, you will be redirected to the store's host website, do you wish to continue?",
    social_redirect_text_cart:
        "In order to login with your social account, you will be redirected to the store's host website and your cart will be cleared, do you wish to continue?",
    social_login_failed: "An error occurred while logging in with your {socialType} account. Please try again.",
    check_out_as_guest: "Check out as Guest",
    c_tn: "With Phone #",
    nickname: "Nickname/Display Name",
    alert_invalid_request: "Invalid Request",
    alert_phone_exists: "Sorry, this phone number already exists in our system.",
    alert_bind_phone_conflict_title: "Phone number already registered",
    alert_bind_phone_phone_exists:
        "This number has been registered with another account, please logout and login with the number if you want to use this number to place order;  alternatively you may use another phone number for your current account.",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "The number you try to use has been registered on another account, if want to use this number for ordering,  tap Continue button to bind your current social account with this number, if you want to use another number,  click cancel and try with another number.",
    alert_vc_input_time_expired:
        "If you have not received an SMS verification code within 1 minute that could mean your number is incorrect or your number is unable to properly receive SMS messages.{br}{br}Please make sure you are using the correct number or try again with another number instead.",
    alert_reg_success_text: "You can now use your phone number to login.",
    alert_reg_success_email: "You can now use your email to login.",
    alert_reg_failed: "Failed to register.",
    alert_email_length: "Invalid email",
    open: "Open",
    store_popup_sold: "Sold",
    distance: "Distance",
    prep_time: "Prep. Time",
    min_delivery_amnt: "Min Delivery Amount",
    max_delivery_dist: "Max Delivery Distance",
    public_notice: "Public Notice",
    discounts: "Discount(s)",
    discount_message: "{discount} off on {price}",
    discount_message_without_price: "{discount} off",
    pay_later_cash_description: "Pay with Cash in Person",
    pay_later_card_description: "Pay with Credit Card in Person",
    continue: "Continue",
    customer_info: "Customer Info",
    please_leave_your_comment: "Please leave your comment",
    order_now: "Order Now",
    order_success: "Order has been sent",
    view_order: "View Order",
    secure_connection: "Information is sent over a secure connection",
    confirm_order: "Confirm Order",
    back_to_store: "Back to Store",
    subtotal: "Subtotal",
    delivery_fee: "Delivery Fee",
    total_before_tax: "Total Before Tax",
    tax: "Tax",
    tips: "Tips",
    tips_with_helper_text: "Tips(tap amount to update)",
    quantity: "Quantity",
    gift_cards: "Gift Cards",
    total_paid: "Total Paid",
    total_due: "Amount Due",
    refund: "Refund",
    payment_method: "Payment Method",
    estimate_shipping_fee: "Estimate Shipping Fee",
    shipping_option: "Shipping Method",
    pick_shipping_option: "Pick a Shipping Method",
    shipping_option_title: "Shipping: ",
    pay_later: "Pay Later with Card",
    cash: "Pay Later with Cash",
    credit_card: "Credit Card",
    pay_by_credit_card: "Pay by Credit Card",
    giftcard: "Gift Card",
    points: "Points",
    points_and_giftcard: "Points and Gift Card",
    wechat_pay: "WeChat Pay",
    address: "Address",
    address_customer_info: "Address Customer Info",
    c2c_regular: "Regular Shipping",
    c2c_express: "Express Shipping",
    S01: "Free Shipping",
    S02: "Flat Rate",
    S03: "Table Rate",
    credit_card_added: "Credit card added",
    map_search_placeholder: "Enter street address",
    exceed_delivery_distance_select:
        "The selected delivery address exceeds the store's maximum delivery distance of {delivery_distance}{distance_unit}. Please pick another delivery address.",
    exceed_delivery_distance_input:
        "The provided delivery address exceeds the store’s maximum delivery distance of {delivery_distance}{distance_unit}. Please input another delivery address.",
    address_not_in_delivery_zone:
        "The selected delivery address is not in range of the store's delivery zones. Please pick another delivery address.",
    item_oos_all: "All the item(s) in the cart are out of stock, please add additional items to proceed.",
    error_occured: "Sorry, your account authentication has failed. Please re-login and try again.",
    transaction_declined: "Transaction declined. Invalid credit card, please pick another payment method.",
    invalid_first_name: "Invalid first name",
    payment_method_missing: "Payment method missing",
    billing_address_missing: "Billing address missing",
    giftcard_error: "Gift card error, please refresh the page and try again.",
    customer_info_missing: "Customer info missing",
    table_number_missing: "Please fill in your current table number",
    pickup_time_missing: "Delivery time missing",
    delivery_time_missing: "Delivery time missing",
    delivery_address_missing: "Delivery address missing",
    shipping_method_missing: "Shipping method missing",
    set: "Set",
    item_error:
        "A error has occurred. Your shopping cart has been cleared. Please add some items and try checking out again.",
    no_items_error: "There are no items added to your shopping cart. Please add some items and try checking out again.",
    use_profile_info: "Use info on profile",
    pick_up: "Pick-up",
    delivery_option: "Delivery Option",
    required: "Required",
    first_name_placeholder: "Enter your first name",
    last_name_placeholder: "Enter your last name",
    table_number_placeholder: "Enter your table #",
    unit_placeholder: "Enter your unit #",
    buzz_placeholder: "Enter your buzz #",
    street_placeholder: "Enter your street address",
    city_placeholder: "Enter your city",
    region_placeholder: "Enter your province or state",
    cntry_placeholder: "Enter your country",
    zipcd_placeholder: "Enter your zip code",
    table_number_required: "Table number is required",
    are_you_sure_remove_gift_card: "Are you sure you want to remove this gift card from your payment?",
    no_shipping_options: "No shipping method for the selected address. Please pick another shipping address.",
    giftcard_pays_order_total:
        "Selected gift card already pays for the order total. No additional payment methods needed.",
    delivery_time_changed: "Delivery time has been revised, please select another appropriate time.",
    gift_card_payment: "Gift Card Payment",
    expires: "Expires",
    allowed_periods_unavailable: "Please pick a suitable delivery address to get available delivery times.",
    delivery_method_unavailable: "Delivery is no longer available, please go back to the store and check out again.",
    takeout_method_unavailable: "Pick-up is no longer available, please go back to the store and check out again.",
    in_store_method_unavailable:
        "In store order is no longer available, please go back to the store and check out again.",
    agreement_notice: "By confirming the purchase, I agree to Goopter's {conditions_of_use} and {privacy_policy}",
    conditions_of_use: "Terms of Use",
    privacy_policy: "Privacy Policy",
    "secure-connection": "Information is sent over a secure connection",
    set_payment_method: "Set Payment Method",
    set_billing_address: "Set Billing Address",
    set_delivery_address: "Set Delivery Address",
    set_giftcard: "Select Gift Card",
    set_points: "Tap to redeem points",
    redeem_points_dialog_title: "Redeem Points",
    redeem_x: "Redeem: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "Input Customer Info",
    set_table_number: "Input Table Number",
    set_pickup_time: "Choose a Pick-up Time",
    set_delivery_time: "Choose a Delivery Time",
    set_shipping_method: "Set Shipping Method",
    address_book: "Address Book",
    "no-giftcard-text": "You do not have any gift cards for this store",
    delete_credit_card_failed: "Sorry, your card couldn't be deleted, please try again later.",
    checkout_success:
        "Thank you for your business, your order has been created: #{order_id}. You will be notified when the order's status is updated.",
    new_order_created: "New Order Created",
    distance_exceeded: "Distance Exceeded",
    min_delivery_amt_required:
        "The minimum delivery amount is {required} before tax and delivery fee, please add {gap} more for delivery.",
    insufficient_fund: "Insufficient fund, please try with another payment method.",
    invalid_payment_info: "Invalid payment information, please check your input and try again.",
    merchant_not_ready: "The merchant is not ready to accept your payment. Please try again later.",
    shipping_country_text: "Shipping available for addresses within the following countries: {countries}",
    apply: "Apply",
    enter_coupon_code: "Enter Coupon Code",
    remove_coupon_alert: "Are you sure you want to remove this coupon?",
    replace_coupon_alert: "Current active coupon will be replaced, do you wish to continue?",
    invalid_alipay_coupon: "This coupon code is only available for AliPay payment method",
    remove_current_alipay_coupon:
        "The current shopping cart contains a coupon that is only available for AliPay payment method, changing to another payment will result in losing the discount, do you still want to change the payment method?",
    use_wechat_dialog_message:
        "To use WeChatPay, please open the store on Wechat to proceed to order. {br}{br}Options to open the store on Wechat:{br}1. If you have the store QR code, use Wechat to scan it;{br}2. Tap the store name on top of the page to go back to the store page, then tap the top right corner icon to open the QR code pop up window, save the QR code, then open the QR code in Wechat to proceed to order.",
    discount_total: "Discount Total",
    first_name_missing: "Please enter your first name",
    phone_number_missing: "Please enter your phone number",
    payment: "Payment",
    select_payment: "Select a Payment Type",
    select_giftcards: "Select a Gift Card",
    special_request: "Special Request",
    place_order_with_x: "Place Order - {x}",
    min_type_required_message: "The minimum amount for {type} is {value}, please add more item to enable {type}.",
    select_pickup_location: "Select Pick-up Location",
    please_choose_pickup_location: "Please choose pick-up location",
    invalid_delivery_zone_title: "Out of delivery boundary",
    invalid_delivery_zone_content:
        "The provided address is out of delivery boundary. Please input another address. Delivery zones and schedules as following:",
    please_input_address: "Please input a valid address",
    please_input_pickup_time: "Please choose pick-up time",
    please_input_delivery_time: "Please choose delivery time",
    recommend_a_good_restaurant: "Recommended you a good store",
    recommend_a_good_merchat: "Recommended you a good merchant",
    recommend_a_good_product: "Recommended you a good product",
    guest_user: "Guest User",
    require_utensil: "Request utensils, etc.",
    already_have_account: "Checking out as Guest",
    order_success_table: "Thanks for your order. We'll bring your order to your table as soon as it's ready.",
    order_success_parking_lot: "Thanks for your order. We'll bring your order to your car as soon as it's ready.",
    order_success_kiosk: "Thanks for your order. We'll notify you as soon as it's ready.",
    view_special_deals: "View special deals",
    more_items_to_get_special_deals: "Current order amount does not qualify for special deals. Please add more items.",
    special_deals_for_you: "Special deals for you",
    add_items: "Add items",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "Menu",
    category: "Category",
    categories: "Categories",
    store_details: "Store Details",
    store_description: "Store Description",
    rank: "Rank",
    customer_reviews: "Customer Reviews",
    single_selection: "Single Selection",
    optional: "Optional",
    pick_varied_options: "Pick {count} items",
    sold: "{count} sold",
    only_x_left: "{count} left",
    sold_out: "Sold Out",
    pick_option: "Pick Option",
    contact_vendor: "Contact Vendor",
    call_vendor: "Call Vendor",
    self_input: "Self Input",
    with_option: "With Option(s)",
    coupons: "{couponCount} Coupon(s)",
    coupons_title: "Coupons",
    redeemed: "Applied",
    redeem: "Apply",
    remove: "Remove",
    valid_until: "Valid Until {endDate}",
    checkout: "Check out",
    shopping_cart: "Shopping Cart",
    empty_cart: "Clear",
    alert_empty_cart: "Are you sure you want to empty your shopping cart?",
    discount_title: "Discount",
    qrcode: "QRcode",
    m: "m",
    km: "km",
    min: "min",
    hour: "h",
    rating: "rating",
    rating_title: "Rating",
    base_delivery_fee: "Delivery Fee Within {flatDistance} {distanceUnit}",
    min_delivery_amount: "Min Delivery Amount",
    max_delivery_distance: "Max Delivery Distance",
    distance_title: "Distance",
    takes_reservations: "Takes Reservations",
    more_info: "More Info",
    announcement: "Public Notice",
    no_announcement: "No Public Notice",
    buy_x_discount_flat: "Buy {currency}{price}, {currency}{discount} off",
    buy_x_discount_percent: "Buy {currency}{price}, {discount}% off",
    search_products_title: "Search Products",
    search_products_input_placeholder: "Enter keyword to search...",
    search_products_no_match: "Your search '{text}' did not match any products.",
    alert_delete_product_with_options: "Product with options can only be removed from the shopping cart.",
    store_closed_allowed_preorder: "The store is currently closed, you may preorder.",
    store_closed_allowed_preorder_x_mins_before:
        "Store is currently closed. You may pre-order {mins} mins before the store is open.",

    store_closed_soon_not_allowed_order: "Store will be closing soon and is currently not accepting new orders.",
    store_closed_soon_allow_preorder_next_day:
        "Store will be closing soon and only accepting pre-orders for next business day.",
    store_closed_only_accepting_preorder_next_business_day: "Store is closed. You may pre-order for next business day.",
    results_colon: "Results: ",
    price: "Price",
    popularity: "Popularity",
    service_title: "Service",
    product_title: "Product",
    all_title: "All",
    satisfied_title: "Satisfied",
    unsatisfied_title: "Unsatisfied",
    discount_rate: "{rate}% off",
    invalid_option_selection: "Invalid Option Selection",
    no_product_options: "No Product Options",
    single_selection_error: "Please choose one option item from '{productOption}'",
    pick_ranged_options_error: "Minimum option selection for '{productOption}' is {count} items",
    pick_fixed_options_error: "Please choose {count} option selection from '{productOption}'",
    update_cart: "Update Cart",
    total_reviews: "Reviews",
    store_total_sold_count: "Sold",
    hot_products: "Hot Products",
    related_products: "Related Products",
    store_hours: "Store Hours",
    delivery_hours: "Delivery Hours",
    add_for_delivery: "Add {currency}{amount} for delivery",
    open_in_app: "Open in App",
    qrcode_message: "Scan this QR code to open the store",
    review_submitted_validate: "Thanks for submitting your review, it will be published once validated.",
    review_submitted: "Your review has been submitted.",
    add_update_review: "Please add or update your review",
    post_review_failed: "Review was not submitted. Please refresh your page and try again.",
    not_accepting_orders: "We are not accepting online orders at the moment",
    bookmark_sign_in_alert_store: "Sign in to bookmark this store!",
    bookmark_sign_in_alert_product: "Sign in to bookmark this product!",
    no_store_bookmarks: "No Store Bookmarks",
    no_store_bookmarks_message:
        "You have no bookmark for any store. Press the heart icon at the top right of a store home screen to bookmark a store.",
    no_store_bookmarks_button: "Add a Store Bookmark",
    no_product_bookmarks: "No Product Bookmarks",
    no_product_bookmarks_message:
        "You have no bookmarks for products. Press the heart icon associated with a product to bookmark a product.",
    no_product_bookmarks_button: "Add a Product Bookmark",
    no_group_bookmarks: "No Deal Bookmarks",
    no_group_bookmarks_message:
        "You have no bookmarks for deals. Press the heart icon associated with a deal to bookmark.",
    no_group_bookmarks_button: "Add a Deal Bookmark",
    add_item_another_menu_warning:
        "Your cart already contains items from another menu ({menu}), Would you like to clear the cart and add this item instead?",
    start_new_cart_question: "Start new cart?",
    change_menu: "Change Menu",
    direction: "Directions",
    website: "Website",
    we_also_recommend: "We also recommend...",
    diff_delivery_price: "Add {x} for delivery",
    discount_info_format: "Buy {x}",
    discount_add_x_get_y: "Add {x} more to get {y} off",
    discount_get_percentage_discount: " get {x} off",
    discount_get_value_discount: " get {x} off",
    discount_not_accepting_order: "Sorry, we are not accepting online order at the moment",
    discount_store_closed_preorder: "Store is closed, you may pre-order now",
    discount_x_off_add_y_get_z: "{x} off, add {y} more to get {z} off",
    discount_x_off: "{x} off",
    switch_menu_warning_message:
        "The selected menu supports a different order type ({new_order_type}). The items in the cart will be cleared if you proceed. Do you want to switch to the new menu?",
    logout_confirm_message: "Are you sure you want to logout?",
    logout_force_message:
        "Sorry, your account has been logged in from another device or has timed out. Please login again.",
    cannot_merge_account: "Your account already exists, please use another phone number.",
    duplicate_social_error:
        "The phone number has registered in another account, please try a different phone number. To use this number, please logout and login with this number.",
    store_has_no_products: "This store currently has no products.",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "Unable to load data",
    unstable_network: "Slow or unstable network, please check your network connection",
    incorrect_password: "Incorrect password",
    country_code_error: "Country code error",
    incorrect_username_password: "Incorrect username/password",
    verification_code_expired: "Verification code expired",
    verifcation_code_request_overlimit: "Verification code request overlimit",
    incorrect_verification_code: "Incorrect verification code",
    error_fetch_user_profile: "Failed to fetch user profile",
    login_failed: "Failed to login",
    profile_update_success: "Update profile success",
    email_update_success: "Update email success",
    phone_update_success: "Update phone number success",
    failed_fetch_gift_card_detail: "Failed to fetch gift card detail",
    gift_card_transfer_success: "Tranfered gift card success",
    gift_card_transfer_fail: "Failed to tranfer gift card",
    record_not_found: "Record not found",
    failed_fetch_points_detail: "Failed to fetch points details",
    profile_update_failed: "Failed to update user profile",
    email_update_failed: "Failed to update email",
    phone_update_failed: "Failed to update phone number",
    get_verification_code_success: "Verification code has been sent to your phone.",
    verification_too_many_requests: "Verification code was requested too many times, please try again later",
    invalid_table_number: "Please input a number",
    invalid_party_size: "Please input a number",
    email_required: "Email is required",
    password_required: "Password is required",
    phone_required: "Phone is required",
    vc_required: "Verification code is required",
    coupon_invalid: "Invalid coupon",
    coupon_valid: "Valid coupon",
    invalid_coupon: "Coupon code {couponCode} is invalid",
    coupon_applied: "Coupon has been applied to the cart.",
    invalid_address: "Invalid Address",
    remaining_stock_header: "Remaining Stock",
    item_oos: "Sorry, the following items do not have enough quantity in stock:",
    invalid_shipping_method: "The shipping method is not valid. Please try another shipping method",
    please_input_phone_number: "Please input a valid phone number",
    please_input_first_name: "Please input First Name",
    please_input_last_name: "Please input Last Name",
    please_input_at_least_two_characters: "Please input at least two characters",
    please_input_shipping_method: "Please input shipping method",
    please_input_sub_shipping_method: "Please input shipping method",
    please_input_shipping_addresss: "Tap to input shipping address",
    please_input_pickup_addresss: "Tap to choose pick-up location",
    please_input_table_number: "Please input table number",
    please_input_payment_method: "Tap to Select Payment Method",
    please_input_valid_credit_card: "Please input valid credit card",
    please_input_billing_address: "Please input billing address",
    tap_to_show_on_map: "Tap here to show on map",
    please_add_some_items: "Please add some items",
    please_confirm_tips: "Tap to Set Tip Amount",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is $\u200B{tips_amount} in order to secure a driver for your order.",
    bind_phone: "Verify Phone Number",
    please_bind_phone:
        "A verified phone number is required before placing an order. Do you want to verify your phone number now?",
    go_to_bind: "Go to Bind",
    bind_phone_success: "Bind phone success",
    invalid_credit_card: "Invalid credit card. Please pick another payment method",
    unsuccessful_checkout: "Unsuccessful check out. Please try again.",
    invalid_request: "Unsuccessful check out. Invalid request.",
    price_over_limit: "Unsuccessful check out. The price is over the payment limit.",
    invalid_shp_mtd: "Unsuccessful check out. The shipping method is not valid. Please try another shipping method",
    invalid_pay_mtd: "Unsuccessful check out. The payment method is not valid. Please try another payment method.",
    please_input_belows: "Please enter below missing fields",
    unable_to_change_here: "Unable to change here",
    please_select_on_previous_page: "Please select on previous page",
    invalid_card_number: "Invalid card number",
    invalid_zipcd: "Invalid postal code",
    click_one_more_time_to_deselect: "Click on more time to deselect",
    paypal_payment_success_title: "Payment Successful",
    paypal_payment_success_text: "Your payment has been received. You will be redirected to the order page.",
    error_payment_failed: "Payment Failed",
    internal_server_error: "Sorry, there has been a error, please try again later or contact the vendor directly.",
    unexpected_error: "An unexpected error has occurred, please try again later",
    points_pays_order_total: "Your points already pays for the order total. No additional payment methods needed.",
    giftcard_points_pays_order_total:
        "Your gift card and points already pays for the order total. No additional payment methods needed.",
    digit_only: "Please enter digits only",
    please_selected_belows: "Please complete all selections:",
    please_select_location_from_the_list: "Please select a street address from the list.",
    alert_password_reset_success:
        "Your password has been updated successfully, you may now login with the new password.",
    alert_email_not_found: "This Email has not been registered yet!",
    alert_password_reset_email_send: "Password reset email has been sent.",
    x_is_required: " is a required field",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "Location permission is blocked, this service may be affected. ",
    x_missing: "Please input {x} ",
    x_invalid: "Invalid {x}",
    x_select: "Please select {x}",
    do_you_want_to_receive_notification: "Do you want to receive notifications?",
    gift_cards_points_alert:
        "You are not allowed to use the gift card when you redeem points. To use a gift card, please remove the reward points first.",
    points_gift_cards_alert_message:
        "You are not allowed to use the reward points when you use gift card. To use reward points please remove the gift card first.",
    not_found_description: "Oops! Page Not Be Found",
    not_found_detail_description:
        "Sorry but the page you are looking for does not exist, has been removed/renamed, or is temporarily unavailable",
    back_to_home: "Back to homepage",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    please_input_valid_postal_code: "Please input a valid postal code/zip code.",
    please_input_valid_x: "Please input a valid {x}",
    too_many_card_attempt:
        "The provided card has been declined too many times; please try another card for the payment.",
    too_many_checkout_attempt: "Maximum retry limit exceeded. Please try again later.",
    payment_declined: "Payment Declined",
    credit_card_declined:
        "Please check the credit card information to make sure they were entered correctly and try again. If this does not resolve the problem, please call your card issuing bank to resolve.",
    ppcp_payment_declined: "Payment declined. Please try again or use another card to make payment.",
    no_giftcard: "You do not have available gift card",
    giftcard_insuffienct_fund: "Your Gift Card has an insufficient balance for the payment.",
    "invalid_request_body": "Invalid request",
    pay_order: "Pay Order",
    expiry_date: "Expiry date",
    postal_code: "Postal Code",
    postal_code_zipcd: "Postal Code/Zip Code",
    country: "Country",
    last_four: "Last Four",
    are_you_sure_delete_card: "Are you sure you want to delete this card",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "Confirm pay",
    unsuccessful_payment: "Unsuccessful payment. Please try again.",
    reset_password_title: "Reset Password",
    new_password: "New Password",
    password_reset_continue: "Submit",
    reset_by_text: "Reset by Text",
    reset_by_email: "Reset by Email",
    email_placeholder: "Email",
    alert_vc_expired:
        "Sorry, the verification code you provided is expired, please request another verification code and try again.",
    alert_password_min_length: "Password must be at least 6 characters",
    email_verification: "Email Verification",
    verification_success: "Email has been verified",
    verify_account: "Thanks for verifying for your account! {email}",
    verification_fail: "Email Verification Failed",
    verify_error: "An error has occurred, please try again by resending a verification email. {email}",
    view_account: "View Account",
    link_expired: "Couldn't verify email address",
    link_expired_message: "The verification link has expired or was already used. Please try again. {email}",
    email_already_verified: "Your account has already been verified.",
    resend_verification_email: "Resend Verification Email",
    recently_viewed: "Recently Viewed",
    by_phone: "By Phone",
    by_email: "By Email",
    by_password: "By Existing Password",
    op_incorrect: "Original password submitted is incorrect.",
    password_change_success: "Password successfully changed.",
    password_request_sent: "Change password request sent",
    social_binding: "Social Binding",
    linked: "Linked",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "or",
    connect_with_facebook: "Sign in with Facebook",
    connect_with_twitter: "Sign in with Twitter",
    connect_with_google: "Sign in with Google",
    connect_with_wechat: "Sign in with WeChat",
    connect_with_apple: "Sign in with Apple",
    dont_have_account: "Don't have an account?",
    vouchers: "Vouchers",
    gift_card: "Gift Card",
    bookmarks: "Bookmarks",
    bookmark_added: "Added bookmark",
    bookmark_deleted: "Removed bookmark",
    account_info: "Account Information",
    my_points: "My Points",
    my_giftcards: "My Gift Cards",
    my_bookmarks: "My Bookmarks",
    my_messages: "My Messages",
    my_orders: "My Orders",
    avatar: "Avatar",
    name: "Name",
    my_address: "My Address",
    phone_num: "Phone #",
    account_binding: "Account Binding",
    connect: "Connect",
    safety_settings: "Safety Settings",
    login_password: "Login Password",
    modify: "Modify",
    delete: "Delete",
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone #",
    pick_place_on_map: "Search on the map",
    done: "Done",
    enter_address_manually: "Enter address manually",
    use_name_account: "Use name on account",
    new_address: "New Address",
    unit: "Unit",
    street: "Street",
    city: "City",
    region: "Region",
    cntry: "Country",
    zipcd: "Zip Code",
    postalcd: "Postal Code",
    required_text: "* indicates a field is required",
    map_search: "Map Search",
    accept: "Accept",
    change_address: "Change Address",
    invalid_last_name: "Invalid last name",
    invalid_credit_card_number: "Invalid credit card number",
    invalid_credit_card_cvv: "Invalid CVV",
    invalid_cntry: "Invalid Country",
    Invalid_zipcd: "Invalid Zip Code",
    street_missing: "Street is empty",
    city_missing: "City is empty",
    region_missing: "Region is empty",
    cntry_missing: "Country is missing",
    zipcd_missing: "Zip code is missing",
    billing_address: "Billing Address",
    confirm_delete_address: "Confirm Delete Address",
    delete_address_message: "Are you sure you want to delete this address?",
    address_edit: "Address Edit",
    default: "Default",
    set_as_default: "Set as Default",
    nick_name: "Nick Name",
    update_profile_success: "Your profile has been successfully updated!",
    update_profile_failed: "Sorry, update profile failed. Please try again later.",
    last_name_missing: "Last name missing",
    nick_name_missing: "Nick name missing",
    update: "Update",
    user_info: "User Information",
    new_phone: "New Phone #",
    new_email: "New Email",
    phone_placeholder: "Phone number",
    alert_phone_updated: "Your phone number has been successfully updated!",
    alert_missing_fields: "Sorry, there are input fields missing.",
    alert_forbidden_action: "Sorry, this action is forbidden.",
    alert_email_updated: "Your email has been successfully updated!",
    alert_forbidden_email: "Sorry, this action is forbidden.",
    alert_email_exists_sign_up:
        "The email you inputted is already in use by another account. Please sign into that account or use another email.",
    alert_email_exists_update: "The email you inputted is already in use by another account. Please use another email.",
    wallet: "Wallet",
    confirm_delete_credit_card: "Confirm Delete Credit Card",
    confirm_delete_credit_card_message: "Are you sure you want to delete this credit card?",
    add_credit_card_title: "Add Credit Card",
    add_credit_card: "Add a credit card",
    save_credit_card: "Save credit card",
    card_number: "Card Number",
    name_on_card: "Name on Card",
    cvv: "CVV",
    set_payment_password: "Set payment password",
    old_password: "Old Password",
    confirm_new_password: "Confirm New Password",
    update_password_success: "Your password has been successfully updated!",
    password_not_detected: "The old password does not match, please revise and try again or reset your password.",
    server_error: "Sorry, a problem has occurred. Please try again later.",
    unmatched_passwords: "New password and confirm new password are not the same, please revise and try again.",
    social_bind_not_allowed:
        "Login or bind or unbind via social account except wechat is not allowed in WeChat, please use external browser",
    social_bind_success:
        "Your account has been successfully linked with your {socialType} account. You can now login with your {socialType} account!",
    social_bind_failed: "An error occurred while linking your {socialType} account. Please try again.",
    connected: "Connected",
    confirm_unbind_social_account: "Are you sure you want to unbind your {socialType} account?",
    unbind_social_success: "Your {socialType} account has been unbound.",
    unbind_social_error: "Sorry, we could not unbind your {socialType} account at this time. Please try again later.",
    create_new_vault_password: "Add a password to keep your credit card secure",
    confirm_vault_password: "Confirm your password",
    input_vault_password: "Input your payment password",
    vault_password: "Payment Password",
    password_instructions: "Password needs to be 4 - 6 numbers long",
    password_incorrect: "Password Incorrect",
    add_address_invalid_address: "Invalid address. Please try picking address on the map.",
    country_not_exists: "Invalid country. Please try picking address on the map.",
    map_select_address: "Please fill in the search bar and select a suggested address.",
    error_current_location:
        "There was an error getting your current location, please enter your address in the search bar.",
    map_address_accept_error: "Google maps error. Please try another address or enter the address manually.",
    invalid_name: "Please fill in the card holder's name.",
    invalid_cc_info: "The credit card information is not valid, please revise and try again.",
    invalid_expiry_date: "The credit card expiry date is invalid, please revise and try again.",
    alert_guest_account:
        "You checked out as a guest. Complete your profile to receive personalized promotions or discounts in the future.",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded: "Address list amount exceeded. You can only save 10 addresses maximum.",
    i_will_do_it_later: "I will do it later",
    finish_later: "Finish later",
    skip: "Skip",
    change_by_email: "By Email",
    change_by_text_message: "By SMS",
    change_by_existing_password: "By Existing Password",
    set_password: "Set Password",
    change_password: "Change Password",
    update_password: "Update Password",
    code: "Code",
    request_pass_reset: "Request Password Reset",
    confirm_password: "Confirm Password",
    set_a_password: "Set a password",
    set_a_password_caption: "To sign in quicker next time, set a password.",
    set_a_new_password: "Set a new password",
    set_a_new_password_caption: "Forgot your password? Set a new one to login quicker next time.",
    update_password_fail: "Update password fail, try again later",
    birthday: "Birthday",
    gender: "Gender",
    not_provided: "Not provided",
    currency: "Currency",
    male: "Male",
    female: "Female",
    none: "None",
    year: "Year",
    month: "Month",
    day: "Day",
    cannot_update_profile: "Cannot update profile.",
    updated_profile: "Updated profile.",
    giftcards: "Gift Cards",
    gift_card_details: "Gift Card Detail",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    active_giftcard: "Active",
    inactive_giftcard: "Inactive",
    used: "Used",
    balance: "Balance",
    book_value: "Book Value",
    purchase_date: "Purchase Date",
    status: "Status",
    reassign: "Reassign",
    reassign_giftcard: "Reassign Gift Card",
    receivers_email_phone: "Receiver's Email / Phone",
    giftcard_reassign_success: "Your gift card has been successfully transfered to {receiver}.",
    giftcard_reassign_missing_receiver: "Please fill in the receiver information",
    giftcard_reassign_missing_info: "You must have a valid receiver to send the gift card.",
    giftcard_reassign_failed:
        "Sorry, there has been an error trying to reassign your gift card. Please try again later.",
    giftcard_reassign_failed_phone:
        "This phone number does not belong to any registered user, please try another phone number.",
    giftcard_reassign_failed_email: "This email does not belong to any registered user, please try another email.",
    no_giftcards: "You don't have any gift cards",
    no_active_giftcards: "You don't have any active gift cards",
    no_active_giftcards_head: "No Active Gift Cards",
    no_used_giftcards_head: "No Used Gift Cards",
    no_used_giftcards: "You haven't used any gift cards",
    purchase_a_giftcard: "Purchase a Gift Card",
    invalid_phone: "The phone number is invalid, please make sure phone number has correct length.",
    giftcard_reassign_tip:
        "Gift card reassign allows you to send a purchased gift card to another user through their registered email or phone number",
    gift_card_purchased: "Gift card purchased",
    paid_with_gift_card: "Paid with gift card",
    refunded: "Refunded",
    invalid_phone_number: "Invalid phone number",
    comment: "Comment",
    transactions: "Transactions",
    details: "Details",
    date: "Date",
    balance_change: "Balance Change",
    points_balance: "Points Balance",
    no_points: "You currently don't have any reward points. Shop now to earn some points.",
    no_points_head: "You Have No Points",
    view_stores: "View Stores",
    last_updated: "Last updated: {time}",
    point_balance: "Balance: {balance}",
    point_store_name: "Store name: {name}",
    points_received: "Points Received",
    no_orders: "No orders have been placed before",
    no_past_orders: "No Past Orders",
    no_upcoming_orders: "No Upcoming Orders",
    shop_now: "Shop Now",
    pickup_method: "Pick-up Time",
    eatin_method: "Due Time",
    delivery_method: "Delivery Time",
    instantCheckout_method: "Instant Checkout",
    items: "Item(s)",
    ongoing: "Ongoing",
    history: "History",
    upcoming: "Upcoming",
    past_orders: "Past Orders",
    buzz_code: "Buzz",
    make_default: "Set as Default",
    add_address: "New Address",
    add_a_address: "Add a New Address",
    are_you_sure_delete_address: "Are you sure you want to delete this address?",
    select_address: "Select Address",
    search_address: "Search Address",
    current_location: "Current Location",
    use_name_on_account: "Use name on account",
    confirm_address: "Confirm Address",
    location_user_denied: "Location service is disabled, please enable location service",
    geolocation_not_supported: "Current Location services is not supported, please search address in the address bar",
    location_general_error: "Unable to get your current location, please try again later",
    self_checkout: "Quick Pay",
    amount: "Amount",
    add_comment: "Add a Comment",
    contact_merchant: "Contact Merchant",
    powered_by_goopter: "Powered by Goopter",
    pay: "Pay",
    self_checkout_disabled: "Quick pay is not available for this store.",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "Surcharge for order: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "We'll send a verification code to your phone",
    payment_success_title: "Payment Successful",
    payment_success_text: "Your payment has been received. You will be redirected to the order page.",
    go_to_order: "Go to order",
    qr_scanner: "Scan to Order",
    camera_instructor_title: "Camera access required",
    camera_instructor_description_dine_in:
        "The Goopter app will open your camera next. Please point the camera towards the QR code to proceed with your order.",
    camera_instructor_button_text_dine_in: "Scan to order",
    camera_error_title: "Camera access denied",
    camera_error_description_dine_in: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text: "Enable Camera",
    error_invalid_code_dine_in: "Invalid QR code for dine-in order for this store. Please scan the correct QR code.",
    error_invalid_code_in_store: "Invalid QR code for in-store order for this store. Please scan the correct QR code.",
    parking_lot: "Parking Lot #",
    kiosk: "Kiosk #",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "Tap camera icon to switch",
    scan_again: "Try Again",
    scanner_title: "Align QR Code/barcode within frame to scan",
    orders_today: "Orders Today",
    orders_total: "Orders Total",
    points_today: "Points Today",
    points_total: "Points Total",
    check_how_many_reward_points_from_referrals: "Check how many reward points you received from referrals",
    no_referrals_message:
        "You don’t have any referrals yet. Start referring to earn reward points to spend on your favourite stores!",
    order_id: "Order #",
    no_referral_orders_found: "No referral orders found",
    from: "From",
    pts: "pts",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    the_comment_is_copied_to_clipboard: "The comment is copied to clipboard!",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Full payment of a self-service order",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "{orderType} Order #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    pls_input_number: "Please input number",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
    deactivate_account: "Account deactivation & deletion",
    msg_deactivation: "Your account will be permanently deleted. After you delete your Goopter account, you will not be able to log in with your current account and retrieve any past orders or address records.",
    account_deactivated: "Account has been deactivated & deleted"
};

export default exports;
