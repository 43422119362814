const exports = {
    " ": " ",
    go_back: "戻る",
    notifications: "通知",
    settings: "セッティング",
    logout: "ログアウト",
    logged_out_error: "ログアウトしました。再度ログインしてください。",
    logged_out_success: "ログアウトに成功しました。 See you again soon!",
    confirm_logout_text: "ログアウトしますか?",
    ok: "はい",
    error: "エラー",
    no: "いいえ",
    yes: "はい",
    language: "言語",
    account: "アカウント",
    login: "ログイン",
    cancel: "キャンセル",
    store: "ストア",
    products: "商品",
    restaurant: "レストラン",
    groupsale: "グループセール",
    travel: "トラベル",
    shopping: "ショッピング",
    service: "サービス",
    save: "保存",
    confirm: "確認",
    success: "成功",
    loading: "読み込み中",
    warning: "警告",
    general_error: "不明なエラーが発生しました。しばらくしてからもう一度お試しください",
    orders: "注文",
    click_to_input_address: "住所を入力するにはクリックしてください",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    input: "入力",
    edit: "編集",
    years: "年",
    quarters: "個数",
    months: "月",
    weeks: "週",
    days: "日",
    days_2: "日",
    time: "時",
    times: "時",
    hours: "時間",
    minutes: "分",
    seconds: "秒",
    milliseconds: "ミリ秒",
    sort: "ソート",
    filter: "フィルター",
    today: "今日",
    tomorrow: "明日",
    yesterday: "昨日",
    this_week: "今週",
    last_week: "先週",
    this_month: "今月",
    last_month: "先月",
    last: "先",
    next: "次",
    now: "今",
    mon: "月",
    tue: "火",
    wed: "水",
    thu: "木",
    fri: "金",
    sat: "土",
    sun: "日",
    mo: "月",
    tu: "火",
    we: "水",
    th: "木",
    fr: "金",
    sa: "土",
    su: "日",
    share: "共有",
    dont_show_this_again: "今後表示しない",
    jan: "1月",
    feb: "2月",
    mar: "3月",
    apr: "4月",
    may: "5月",
    jun: "6月",
    jul: "7月",
    aug: "8月",
    sep: "9月",
    oct: "10月",
    nov: "11月",
    dec: "12月",
    view_more: "もっと見る",
    viewing: "表示",
    show_filters: "フィルターを表示",
    reward_history_filter: "報酬履歴フィルター",
    date_range: "日付範囲",
    my_referrals: "私の紹介者",
    how_to_do_it: "報酬ポイントを受け取る方法とルール",
    how_to_do_it_referrals:
        "紹介リンクまたはQRコードを友達やソーシャルメディアで共有します。以前に他の誰かから紹介されておらず、あなたのリンクを使用して購入した場合、それらはあなたの紹介になります。購入および将来の購入で、1ドルごとに{v2pCustomerRef}の報酬ポイントを受け取れます。紹介者が初めて購入すると、税引前の価値に等しい報酬ポイントが獲得されます * {v2pCombined} （たとえば、税引前の注文額が$ {exampleBasePurchase}の場合、報酬ポイントは次のようになります：{exampleBasePurchase} * {v2pCustomerRef} + {v2pOrderRef} = {exampleResultPoints）。それ以降の注文に対しては、その注文の税引前のドル価値に相当する報酬ポイントを獲得します * {v2pCustomerRef}。 すでに誰かから紹介されている人とリンクを共有した場合、現在の購入に費やした1ドルごとに1回の報酬ポイント{v2pOrderRef}を受け取ります。例：Aが以前に他の人から紹介されたことのないBを参照した場合、AはBの最初の注文から2倍のポイントを受け取り、その後Bが行うすべての注文に対して税引前の注文額に相当する報酬ポイントを受け取ります。Bがすでに他の人から紹介されている場合でも、AはABの1回の注文に基づいて1回限りの報酬ポイントを受け取ります。すべての{p2v}報酬ポイントは、1ドルの購入ごとに獲得できます。",
    copy_ref_url: "紹介URLのコピー",
    total: "合計",
    generate_qr_code: "QRコードの生成",
    ref_orders: "注文",
    income: "収益",
    check_how_much_money_earned: "獲得した金額の確認",
    check_how_much_rewards_earned: "獲得した報酬ポイントの数の確認",
    redeemable: "換金可能",
    total_redeemed: "償還合計",
    sales: "売上高",
    check_how_many_sales: "売上高の確認",
    rewards_history: "報酬履歴",
    check_daily_history: "毎日の報酬取引の確認",
    orders_info: "友達の注文数の確認",
    referrals_info: "紹介者情報",
    referrals_info_desc: "これはあなたの紹介者情報です。それぞれをタップすると,注文と販売情報を取得できます。",
    admin_reward_action: "管理者報酬ポイント",
    points_redeemed: "交換ポイント",
    customer_invitation: "顧客紹介ポイント",
    order_invitation: "注文紹介ポイント",
    admin_credit: "管理者クレジット",
    sales_rule: "販売ルールポイント",
    admin_revert_points: "管理者復帰ポイント",
    refunded_order: "返金された注文ポイント",
    ref_successfully_copied: "紹介コードが正常にコピーされました。",
    click_tips_with_helper_text: "チップ(値をアップデートするにはクリックしてください)",
    click_set_points: "クリックしてポイントを交換する",
    click_to_search_address: "クリックしてアドレスを検索する",
    click_please_input_shipping_addresss: "クリックして配送先アドレスを入力する",
    click_please_input_pickup_addresss: "クリックしてピックアップ場所を指定する",
    click_please_input_payment_method: "クリックして支払い方法を選択する",
    click_to_show_on_map: "クリックして地図で表示する",
    tap_to_search_address: "タップをするとアドレスを検索できます",
    sorry_search: "指定されたキーワードの検索結果がありません。",
    try_again_search: "別の検索キーワードを試してください",
    recent_searches: "最近の検索",
    recommendations: "推奨事項",
    deals: "取引",
    view_all: "すべて表示",
    products_not_available: "次のアイテムは現在利用できませんのでカートから削除されます:",
    shopping_cart_empty: "ショッピングカートは空です。",
    not_available_dialog_title: "現在利用できません。",
    not_available_dialog_captain: "このアイテムは次の時間にのみ利用できます。",
    sorry_product_not_available: "申し訳ありませんが、このアイテムは次の時間にのみ利用できます。",
    feedback: "フィードバック",
    download_qr: "QRコードをダウンロード",
    download_qr_mobile: "ダウンロードするためには画像を長押しします",
    download_poster: "ポスターをダウンロード",
    download_poster_mobile: "ダウンロードするためには画像を長押しします",
    rate_the_product: "商品の評価",
    are_you_sure_cancel_review: "このレビューを破棄してもよろしいですか？",
    submit: "送信",
    review_order_placeholder: "レビューはみなさんがより良い選択をするのに役立ちます",
    review_submitted_message: "レビューをありがとうございます。 検証の後公開されます",
    please_input_or_select: "理由を入力または選択してください",
    review_service: "レビューサービス",
    rate_hint: "星のアイコンをタップして評価",
    not_available: "利用不可",
    current_not_available: "現在利用不可",
    allow_reservation: "予約を許可",
    has_tv: "テレビあり",
    has_free_wifi: "無料Wi-Fiあり",
    has_outdoor_seating: "屋外席あり",
    kid_friendly: "子供に優しい",
    group_friendly: "グループ向き",
    business_friendly: "ビジネス向き",
    street_parking: "ストリートパーキング",
    free_parking: "無料駐車場",
    paid_parking: "有料駐車場",
    noise_level: "ノイズレベル",
    average: "平均",
    loud: "大きい",
    very_loud: "非常に騒々しい",
    casual: "カジュアル",
    business_casual: "ビジネスカジュアル",
    smart_casual: "スマートカジュアル",
    business: "ビジネス",
    semi_formal: "セミフォーマル",
    has_alcohol: "アルコール",
    environment: "環境",
    attire: "服装",
    upscale: "高級",
    classy: "上品",
    hipster: "ヒップスター",
    intimate: "親密な",
    no_alcohol: "アルコールなし",
    beer_and_wine: "ビール＆ワイン",
    full_bar: "フルバー",
    write_your_review_here: "ここにあなたのレビューを書いてください",
    rate_the_store: "店を評価してください",
    more_information: "詳細情報",
    merchant_information: "販売元情報",
    additional_pages: "追加ページ",
    store_info: "店情報",
    store_overview: "概要",
    opens: "開店",
    closes: "閉店",
    current_store: "現在の店",
    order: "注文",
    my_order: "私の注文",
    deliver_to: "Deliver to",
    delivery: "配達",
    check_out: "チェックアウト",
    options: "オプション",
    add_to_cart: "カートに追加",
    pick_one: "必須。1つ以上追加してください",
    pick_amount: "必須。{min}以上追加してください",
    pick_range: "{min}以上{max}以下で追加してください",
    pick_range_up_to: "{max}までで選択してください",
    pick_range_required: "必須。{min}以上{max}以下で追加してください",
    pick_optional: "オプション",
    pick_one_with_name: "{nm} is required, pick 1",
    pick_amount_with_name: "{nm} is required, pick exactly {min}",
    pick_range_up_to_with_name: "{nm} choose up to {max}",
    pick_range_required_with_name: "{nm} is required, pick {min}-{max}",
    up: "アップ",
    search_products: "商品の検索",
    search: "探す",
    discount_by: "{y}より{x}オフ",
    discount_by_without_price: "{x} オフ",
    store_closed: "営業時間外",
    store_closed_not_allow_order: "本日の営業は終了しました。 開店時にご注文ください。",
    order_items: "商品の注文",
    are_you_sure_clear_cart: "カートをクリアしてもよろしいですか？",
    are_you_sure_keeping_cart: "There are saved items in the cart. Do you want to keep the items in the cart?",
    clear_the_cart: "Clear The Cart",
    keep_the_cart: "Keep",
    qr_code: "QRコード",
    poster: "ポスター",
    clear: "クリア",
    results: "結果",
    search_results: "{x} search results",
    pending_acceptance: "保留中のものを受け入れ",
    delivery_time: "配達時間",
    pickup: "ピックアップ",
    takeout: "テイクアウト",
    eatin: "イートイン",
    order_pickup_time: "集荷時間: {time}",
    order_delivery_time: "配達時間: {time}",
    transferred_to: "転送先：{contact}",
    received_from: "受信者：{contact}",
    discount_amount: "{currentDiscount} オフ",
    remaining: " あと {remaining} で {amount} オフになります.",
    remaining_delivery: " 配達を利用するにはあと {remaining} 追加してください。",
    remaining_pickup: " ピックアップを利用するにはあと{remaining} 追加してください。",
    menu_available_at: "このメニューは @{available}でのみ利用可能です…",
    please_input_name: "名前を入力してください",
    no_address_head: "保存された住所はありません",
    no_address_message: "現在住所レコードはありません",
    select_branch: "店舗を選択してください。",
    store_on_hold: "ストアは保留中で、現在注文を受け付けていません。",
    out_of_stock: "在庫切れ",
    load_more: "さらに読み込む",
    no_order_message: "注文を作成/完了するとここに注文が表示されます。",
    no_order: "注文なし",
    create_an_order: "注文の作成",
    no_more_data: "データなし",
    no_search_results: "検索結果なし",
    group_purchase: "グループ購入",
    show_more: "表示を増やす",
    show_less: "表示を減らす",
    purchase_history: "購入履歴",
    start_time: "開始時間",
    end_time: "終了時間",
    expired: "期限切れ",
    offer_expires_in: "オファーの有効期限: {time}",
    offer_starts_in: "オファーの開始日: {time}",
    offer_starts_on: "開始時間: {time}",
    offer_ends_on: "終了時間: {time}",
    remaining_stock: "残り{count}",
    people_ordered: "{amt}人が注文しました",
    select_category: "カテゴリの選択",
    selection_error_min: "最小{min} の選択が {option}には必要です。.",
    no_giftcard_message: "所有しているギフトカードがここに表示されます。",
    upsell_products: "おすすめ",
    switch_to_dine_in: "ダイニングインの確認",
    switch_to_in_store: "ストアでの確認",
    switch_to_dine_in_message:
        "テーブル番号を入力して順番に食事を続行し、キャンセルをタップして{type}の注文タイプを維持してください。",
    surcharge: "追加料金",
    store_surcharge: "オンライン追加料金",
    order_surcharge: "注文追加料金",
    item_total: "アイテム合計",
    surcharge_paid: "追加料金（支払済）",
    surcharge_unpaid: "追加料金（未払い）",
    related_id: "関連注文番号 #",
    related: "関連",
    surcharege_paid_message: "追加料金はこの注文には含まれていません。注文番号{id}を参照してください。",
    surcharge_paid_to_message: "この注文は注文番号{id}の追加料金を支払うためのものです。",
    switch_to_dine_in_confirm: "現在店舗にいないようですが,食事の注文を受け付けてよろしいですか？",
    switch_to_in_store_confirm: "現在店舗にいないようですが,店舗注文を受け付けてよろしいですか？",
    pull_to_refresh: "プルして更新",
    no_products_record: "商品が見つかりません。",
    delivery_locations: "配送ゾーン/スケジュール",
    pickup_locations: "集荷場所",
    min_amount: "最小注文",
    min_order_amount: "最小注文数",
    free_delivery_amount: "無料配達金額",
    delivery_times: "配達時間",
    pickup_times: "集荷時間",
    time_range: "時間範囲",
    store_location: "店舗の場所",
    pickup_locations_schedule: "集荷場所/スケジュール",
    roll_over_image_to_zoom_in: "画像にマウスを合わせると拡大されます",
    about: "概要",
    product_information: "商品説明",
    product_details: "商品詳細",
    buy_now: "今すぐ購入する",
    see_more_product_details: "商品詳細をもっと見る",
    video: "ビデオ",
    page_of: "{page} / {totalNumOfPages} ページ",
    warning_max_purchase_limit_reached: "警告: 購入限度額到達のお知らせ",
    max_purchase_limit_reached_msg: "この商品は{max_sale_qty}個までしか購入できません。",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error: "ストアはこの注文を受け付けました。直接お支払いください。",
    order_comment_status_error:
        "ストアはこの注文の準備を開始しました。変更のリクエストについては、ストアにお問い合わせください。電話：{phone}。",
    oos_items_cannot_be_added: "アイテム{oos_items}は利用できず、カートに追加できませんでした。",
    order_cancelled: "注文はキャンセルされました",
    pending_store_confirmation: "保留中のストアの確認",
    pending_customer_confirmation: "保留中の顧客の確認",
    store_accepted: "注文は受け付けられました",
    order_preparing: "注文準備中",
    store_is_preparing: "店舗準備中",
    ready_pickup: "ご注文はピックアップの準備ができています",
    ready_courier_pickup: "ご注文は配送可能です",
    rejected_by_courier: "配達人に拒否されました",
    courier_assigned: "配達人の割り当て",
    courier_accepted: "配達人はタスクを受け入れました",
    courier_heading_to_store: "配達人が店舗に向かっています",
    courier_arrived_at_store: "配達人が店舗に到着しました",
    courier_collected_package: "配達人が荷物を受け取りました",
    courier_heading_to_customer: "注文品を配送中",
    courier_arrived_at_customer: "配達人が顧客に到着しました",
    delivered_order: "配達済の注文",
    order_changes_description:
        "ご注文内容が変更されました。お手数ですが、配達遅延防止のため、変更内容をご確認の上、早急にご注文内容をご確認ください。",
    confirm_item_change: "注文変更の確認",
    are_you_sure_reject: "提案された変更を拒否すると、この商品が注文から削除されますが、本当に続けますか？",
    accepted: "受入れられました",
    rejected: "拒否されました",
    added: "追加されました",
    removed: "削除されました",
    updated: "更新されました",
    add_back: "戻されました",
    to: "へ",
    updated_an_item: "アイテムの更新",
    added_an_item: "アイテムの追加",
    removed_an_item: "アイテムの削除",
    replaced_an_item: "アイテムの入れ替え",
    are_you_sure_change_order_confirm: "この注文の変更を確認してよろしいですか？",
    order_change_return_price_delta: "更新された注文の合計金額は{new_price}です。注文完了時に差額を返金します。",
    order_change_pay_price_delta:
        "更新された注文の合計金額は{new_price}です。注文完了時にお客様のカードへの最終請求額が表示されます。",
    order_change_pay_new_price:
        "更新された注文の合計金額は{new_price}であり、{price_delta}の価格差をお支払いいただく必要があります。ご注文が遅れないように、追加料金のお支払いをお願いします。",
    order_change_reject_all: "提案された変更をすべて拒否しました。本当に注文をキャンセルしてよろしいですか？",
    order_change_reject_all_refund:
        "あなたは提案された変更をすべて拒否しました。本当に注文をキャンセルしてもよろしいですか？キャンセルすると、{price_delta}が返金されます。",
    order_change_refund: "更新された注文の合計金額が{new_price}であるため、{price_delta}の価格差が返金されます。",
    order_again_adds_items: "[Order Again] 同じアイテムをカートに自動的に追加します。",
    hide_menu: "Hide Item",
    show_menu: "Show Item",
    copy: "コピー",
    copied_success: "注文番号をクリップボードにコピー",
    shipping_time: "出荷時刻",
    order_time: "オーダー時間",
    pickup_time: "集荷時間",
    request_due_time: "支払期限",
    requested_pickup_time: "受け取り希望時間",
    requested_delivery_time: "配達希望時間",
    ready_pickup_time: "受け取り可能時間",
    estimated_delivery_time: "お届け予定時間",
    store_name: "店舗名",
    shipping_info: "配送情報",
    order_info: "注文情報",
    table_info: "テーブル情報",
    serve_time: "準備期間の目安",
    buzz: "バズ",
    order_detail: "オーダー詳細",
    alipay: "アリペイ",
    union_pay: "ユニオンペイ",
    union_pay_express: "ユニオンペイエクスプレス",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "クレジットカード",
    braintree_paypal: "ペイパル",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "イートランスファー/EMT",
    emt_description: "注文確定後にイートランスファー/EMTで支払う",
    payment_received: "支払い領収済み",
    payment_pending: "支払い保留中",
    payment_cancelled: "Payment Cancelled",
    payment_failed: "Payment Failed",
    emt_payment_note_long:
        "この注文に対してイートランスファー/ EMT支払いが選択されました。ご注文の遅れを避けるため、お早めに店舗へのお支払い手続きを済ませ、店舗にご連絡ください。",
    emt_payment_password:
        " お支払いパスワードを{emt_password}にご設定の上、{amount}を {emt_account} にお支払いください",
    emt_payment_account: "{amount}を {emt_account} にお支払いください",
    free: "お支払いは不要です。",
    dine_in: "お店で食べる",
    in_store: "店舗で",
    flatrate: "フラットレート",
    freeShipping: "Digital Asset",
    instantCheckout: "インスタントチェックアウト",
    pending: "確認中",
    processing: "処理中",
    payment_review: "支払いの確認",
    completed: "完了",
    canceled: "キャンセル",
    closed: "営業時間外",
    pending_payment: "保留中のお支払い",
    holded: "ホールド",
    cancel_order: "注文のキャンセル",
    order_number: "注文番号",
    order_status: "注文状況",
    order_date: "注文時刻",
    order_type: "注文タイプ",
    shipping_method: "配送方法",
    expected_time: "支払期限",
    customer: "お客様",
    table_number: "テーブル番号",
    party_size: "パーティサイズ",
    delivery_address: "配送先住所",
    pickup_location: "お受け取り場所",
    store_discount: "ストア割引",
    coupon: "クーポン",
    shipping_and_handling: "送料・手数料",
    shipping_and_handling_with_distance: "配送料{distance}",
    order_total: "合計",
    giftcard_payment: "ギフトカード決済",
    points_payment: "ポイント支払い",
    points_payment_with_points: "ポイント支払い（{x}）",
    comments: "コメント",
    reorder: "再注文",
    general_order_error: "エラーが発生しました。注文リストページに戻って、もう一度お試しください。",
    order_unexist: "注文記録が見つかりませんでした。",
    you: "あなた",
    cancel_order_warning: "この注文をキャンセルしてもよろしいですか？",
    cancel_order_success: "{store_name}での注文＃{order_id}は正常にキャンセルされました。",
    cancel_order_failed: "注文のキャンセルに失敗しました。後でもう一度やり直してください。",
    pay_now_unavailable: "オンライン支払いは利用できません",
    no_online_payment:
        "申し訳ございません。現在、ストアはオンライン支払いを受け付けていません。後でもう一度試すか、ストアに直接お問い合わせください。",
    pay_now: "今すぐ支払う",
    reviews: "レビュー",
    write_review: "レビューを書く",
    write_review_for: "{name}のレビューを書いてください。",
    update_review: "レビューの更新",
    overall: "全体的な",
    add_a_review: "レビューを追加する",
    rate_our_service: "サービスを評価する",
    rate_our_delivery: "配達を評価する",
    post: "投稿",
    requested_time: "希望時間",
    scheduled_time: "予定時間",
    exp_dt: "期限",
    ord_total: "金額",
    s_method: "注文タイプ",
    p_method: "支払い方法",
    start_processing_message: "注文を処理としてマークしてもよろしいですか？",
    mark_as_complete_message: "注文を完了としてマークしてもよろしいですか？",
    cancel_order_message: "注文をキャンセルしてもよろしいですか？",
    complete: "コンプリート",
    reject: "拒否",
    total_refunded: "合計返金",
    refund_amount: "金額",
    refund_all: "全額返金",
    remark: "備考",
    "edit-shipping-fee-message":
        "送料を更新すると、以前の注文がキャンセルされ、送料が更新された新しい注文が作成されます。続けますか？",
    edit_shipping_info: "配送先住所の編集",
    additional_info: "追加情報",
    exp_time: "希望配達時間",
    estimatedDeliTime: "推定配達時間",
    "create-new-order-message": "この注文はキャンセルされ、代わりに新しい注文が作成されます。よろしいですか？",
    order_and_account_information: "注文とアカウント情報",
    address_info: "住所情報",
    shipping_address: "お届け先の住所",
    payment_and_shipping_method: "お支払いと配送方法",
    payment_info: "支払情報",
    shipping_handling_info: "配送処理情報",
    item_order: "注文したアイテム",
    raw_total: "小計",
    notes_for_this_order: "この注文に関する注意事項",
    alert_on_notification: "通知に関するアラート",
    sub_comment: "コメントを送信",
    modify_discount: "割引の変更",
    add_discount: "割引の追加",
    modify_tips: "チップの変更",
    add_tips: "チップの追加",
    modify_shipping_fee: "送料の変更",
    discount: "ディスカウント",
    edit_payment: "支払い方法の更新",
    edit_extra: "更新情報",
    edit_payment_method: "支払いの更新",
    "select_receipt(s)_to_print": "印刷する領収書を選択してください",
    change_payment_to: "お支払い方法を以下の通り変更してもよろしいですか？",
    handling: "取り扱い",
    coupon_discount: "クーポン割引",
    phone: "電話番号",
    email: "Eメール",
    email_receipt: "メールの領収書",
    email_receipt_sent_to: "Email領収書の送信先",
    unpaid: "未払い",
    admin: "管理者",
    cust: "お客様",
    other_payment: "その他の支払い",
    access_denied: "申し訳ありませんが、この注文を表示する権限がありません。",
    please_pay_now: "注文はまだ支払われていません、今お支払いすることができます。",
    are_you_sure_cancel_order: "注文をキャンセルしてもよろしいですか？",
    please_pay_by_x: "ご注文は{x}の間保留することができ、その後自動的にキャンセルされます。",
    reward: "特典",
    reward_points: "特典ポイント",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    shipment_tracking_info: "配達追跡情報",
    carrier: "配送業者",
    tracking_number: "追跡番号",
    register: "登録",
    vc_login: "テキストメッセージでサインイン",
    resend: "再送",
    pwd_login: "アカウントのサインイン",
    email_phone_placeholder: "メール/電話",
    password: "パスワード",
    vc: "認証コード",
    get_vc: "リクエストコード",
    titleS: "Goopter",
    alert_login_succeed: "ログイン成功",
    alert_phone_not_found: "この電話番号はまだ登録されていません",
    alert_too_many_request: "確認コードのリクエスト回数が上限に達しました。15分後にもう一度お試しください。",
    alert_phone_length: "無効な電話番号です",
    alert_phone_vc_not_match: "確認コードが正しくありません。もう一度お試しください。",
    alert_login_failed_general: "ログインに失敗しました。しばらくしてからもう一度お試しください。",
    alert_login_failed: "無効なユーザー名、またはパスワードです。",
    forget_pwd: "パスワードを忘れた",
    state_reg:
        "まだこのウェブサイトに登録していない場合、テキストメッセージを介してログインした後、私たちのシステムはあなたのアカウントを作成します",
    cos_privacy_statement: "ログインすると、Goopterの{cos}と{privacy}に同意したことになります。",
    cos_privacy_statement_register: "サインアップすると、Goopterの{cos}と{privacy}に同意したことになります。",
    cos: "利用規約",
    privacy: "プライバシー通知",
    or_login_with_social: "またはソーシャルアカウントでログインする",
    social_redirect_text:
        "ソーシャルアカウントでログインするために、ストアのホストWebサイトにリダイレクトされます。続行しますか？",
    social_redirect_text_cart:
        "ソーシャルアカウントでログインするには、ストアのホストWebサイトにリダイレクトされ、カートがクリアされます。続行しますか？",
    social_login_failed: "{socialType}アカウントでログイン中にエラーが発生しました。もう一度やり直してください。",
    check_out_as_guest: "ゲストとしてチェックアウト",
    email_placeholder: "Eメール",
    c_tn: "電話番号で",
    nickname: "ニックネーム/表示名",
    alert_invalid_request: "無効なリクエスト",
    alert_phone_exists: "申し訳ありませんが、この電話番号はすでにシステムに存在しています。",
    alert_bind_phone_conflict_title: "すでに登録されている電話番号",
    alert_bind_phone_phone_exists:
        "この番号は別のアカウントに登録されています。この番号を使用して注文する場合は、ログアウトして、再度その番号でログインしてください。または、現在のアカウントに別の電話番号を使用することもできます。",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "使用しようとしている番号が別のアカウントに登録されています。この番号を注文に使用する場合は、[続行]ボタンをタップして、現在のソーシャルアカウントをこの番号に紐付けしてください。別の番号を使用する場合は、[キャンセル]をクリックして、別の番号で試してください。",
    alert_vc_input_time_expired:
        "1分以内にSMS確認コードを受信できなかった場合、番号が正しくないか、番号がSMSメッセージを正しく受信できない可能性があります。{br}{br}正しい番号を使用していることを確認するか、代わりに別の番号で再試行してください。",
    alert_reg_success_text: "これで、電話番号を使用してログインできます。",
    alert_reg_success_email: "これで、電子メールを使用してログインできます。",
    alert_reg_failed: "登録に失敗しました。",
    alert_email_length: "無効なメールアドレスです",
    alert_password_min_length: "パスワードは最低でも6文字必要です",
    open: "営業中",
    store_popup_sold: "売り切れ",
    distance: "距離",
    prep_time: "準備時間",
    min_delivery_amnt: "最小配達額",
    max_delivery_dist: "最大配達距離",
    public_notice: "お知らせ",
    discounts: "割引",
    discount_message: "{price}より{discount}オフ",
    discount_message_without_price: "{discount}オフ",
    pay_later_cash_description: "現金で直接支払う",
    pay_later_card_description: "クレジットカードで直接支払う",
    continue: "続ける",
    please_leave_your_comment: "コメントを残してください",
    order_now: "今すぐ注文",
    order_success: "注文が送信されました",
    view_order: "注文を表示する",
    conditions_of_use: "Terms of Use",
    secure_connection: "情報は安全な接続を介して送信されます",
    confirm_order: "注文を確認する",
    customer_info: "顧客情報",
    back_to_store: "ストアに戻る",
    subtotal: "小計",
    delivery_fee: "配送料",
    total_before_tax: "税別合計",
    tax: "税金",
    tips: "チップ",
    tips_with_helper_text: "チップ（更新にはタップしてください）",
    quantity: "量",
    total_paid: "支払総額",
    total_due: "料金",
    refund: "払い戻し",
    payment_method: "支払方法",
    estimate_shipping_fee: "配送料の見積もり",
    shipping_option: "配送方法",
    pick_shipping_option: "配送方法を選択してください",
    shipping_option_title: "配達方法： ",
    pay_later: "カードで後で支払う",
    cash: "後で現金で支払う",
    credit_card: "クレジットカード",
    pay_by_credit_card: "クレジットカードで支払う",
    giftcard: "ギフトカード",
    points: "ポイント",
    points_and_giftcard: "ポイントとギフトカード",
    wechat_pay: "WeChat Pay",
    address_customer_info: "顧客情報の参照",
    c2c_regular: "通常配送",
    c2c_express: "お急ぎ便",
    S01: "送料無料",
    S02: "定額制料金",
    S03: "料金表",
    credit_card_added: "クレジットカード追加",
    map_search_placeholder: "住所を入力してください",
    exceed_delivery_distance_select:
        "選択された配達先住所はストアの配達可能距離、{delivery_distance}{distance_unit}を超えています。他の住所を選択してください。",
    exceed_delivery_distance_input:
        "選択された配達先住所はストアの配達可能距離、{delivery_distance}{distance_unit}を超えています。他の住所を選択してください。",
    address_not_in_delivery_zone:
        "選択された配達先住所はストアの配達可能地域の範囲外です。他の住所を選択してください。",
    item_oos_all: "カート内のすべてのアイテムが在庫切れです。続行するには、アイテムを追加してください。",
    error_occured: "申し訳ありませんが、アカウント認証に失敗しました。再ログインして再試行してください。",
    transaction_declined: "取引は拒否されました。クレジットカードが無効です。別のお支払い方法を選択してください。",
    invalid_first_name: "無効な名前",
    payment_method_missing: "お支払い方法が空欄です",
    billing_address_missing: "請求先住所が空欄です",
    giftcard_error: "ギフトカードエラーが発生しました。ページを更新してもう一度お試しください。",
    customer_info_missing: "顧客情報が空欄です",
    table_number_missing: "現在のテーブル番号を入力してください",
    pickup_time_missing: "ピックアップ時間が空欄です",
    delivery_time_missing: "配達時間が空欄です",
    delivery_address_missing: "配送先住所が空欄です",
    shipping_method_missing: "配送方法が空欄です",
    set: "設定する",
    invalid_request: "チェックアウトに失敗しました。無効なリクエストです。",
    item_error: "エラーが発生しました。ショッピングカートがクリアされました。アイテムを追加して、再度お試しください。",
    no_items_error: "ショッピングカートにアイテムが追加されていません。アイテムを追加して、もう一度お試しください。",
    use_profile_info: "プロフィールの情報を使用する",
    pick_up: "ピックアップ",
    delivery_option: "配送オプション",
    required: "必須",
    first_name_placeholder: "名前を入力してください",
    last_name_placeholder: "姓を入力してください",
    table_number_placeholder: "テーブル番号を入力してください",
    unit_placeholder: "ユニット番号を入力してください",
    buzz_placeholder: "バズナンバーを入力してください",
    street_placeholder: "通りを入力してください",
    city_placeholder: "市を入力してください",
    region_placeholder: "州を入力してください",
    cntry_placeholder: "国を入力してください",
    zipcd_placeholder: "郵便番号を入力してください",
    table_number_required: "テーブル番号が必要です",
    are_you_sure_remove_gift_card: "このギフトカードをお支払いから削除してもよろしいですか？",
    no_shipping_options: "選択した住所には配送方法がありません。別の配送先住所を選択してください。",
    delivery_time_changed: "配達時間が変更されました。 別の時間を選択してください。",
    gift_card_payment: "ギフトカード決済",
    expires: "期限が切れます",
    allowed_periods_unavailable: "利用可能な配達時間を取得するには、適切な配達先住所を選択してください。",
    delivery_method_unavailable: "配送はご利用いただけなくなりました。店に戻ってもう一度チェックアウトしてください。",
    takeout_method_unavailable:
        "ピックアップはご利用いただけなくなりました。店に戻ってもう一度チェックアウトしてください。",
    in_store_method_unavailable:
        "店頭注文はご利用いただけなくなりました。店に戻ってもう一度チェックアウトしてください。",
    agreement_notice: "購入を確認することにより、Goopterの{terms_of_use}と{privacy_policy}に同意します。",
    terms_of_use: "利用規約",
    privacy_policy: "個人情報保護方針",
    "secure-connection": "情報は安全な接続を介して送信されます",
    set_payment_method: "お支払い方法を設定する",
    set_billing_address: "請求先住所を設定する",
    set_delivery_address: "配送先住所を設定する",
    set_giftcard: "ギフトカードを選択する",
    set_points: "タップしてポイントを交換する",
    redeem_points_dialog_title: "ポイントの交換",
    redeem_x: "引き換え：{x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "顧客情報を入力する",
    set_table_number: "テーブル番号を入力する",
    set_pickup_time: "ピックアップ時間を選択する",
    set_delivery_time: "配達時間を選択する",
    set_shipping_method: "配送方法を設定する",
    address_book: "住所録",
    "no-giftcard-text": "このストアのギフトカードはありません。",
    delete_credit_card_failed: "申し訳ございません、カードを削除できませんでした。後でもう一度やり直してください。",
    checkout_success:
        "お買い上げいただきましてありがとうございます。 注文が作成されました：＃{order_id}。注文のステータスが更新されると通知されます。",
    new_order_created: "新しい注文が作成されました",
    distance_exceeded: "距離が超過しました",
    min_delivery_amt_required:
        "最小配送料は、{required}（税、配送料加算前）です。配送サービスを利用するためにはさらに{gap}分を追加してください。",
    insufficient_fund: "残高が不足しています。別のお支払い方法をお試しください。",
    invalid_payment_info: "無効な支払い情報です。入力事項を確認して、再試行してください。",
    merchant_not_ready: "販売人はあなたの支払いを受け入れる準備ができていません。後でもう一度やり直してください。",
    shipping_country_text: "次の国の住所で配送可能：{countries}",
    apply: "適用",
    enter_coupon_code: "クーポンコードを入力してください",
    remove_coupon_alert: "このクーポンを削除してもよろしいですか？",
    replace_coupon_alert: "現在有効なクーポンは置き換えられますが、続行しますか？",
    invalid_alipay_coupon: "このクーポンコードは、AliPay支払い方法でのみ使用できます",
    remove_current_alipay_coupon:
        "現在のショッピングカートには、AliPayの支払い方法でのみ利用できるクーポンが含まれています。別の支払いに変更すると、割引が失われます。それでも支払い方法を変更しますか？",
    use_wechat_dialog_message:
        "WeChatPayを使用するには、Wechatでストアを開いて注文に進んでください。 {br} {br} Wechatでストアを開くためのオプション：{br} 1。ストアのQRコードがある場合は、Wechatを使用してスキャンします; {br} 2。ページ上部のストア名をタップしてストアページに戻り、右上のアイコンをタップしてQRコードポップアップウィンドウを開き、QRコードを保存してから、WechatでQRコードを開いて注文に進みます。",
    discount_total: "割引合計",
    first_name_missing: "名前を入力してください",
    phone_number_missing: "電話番号を入力してください",
    payment: "支払い",
    select_payment: "支払いタイプを選択してください",
    select_giftcards: "ギフトカードを選択してください",
    special_request: "特別リクエスト",
    place_order_with_x: "{x}を注文する",
    min_type_required_message:
        "{type}の最小量は{value}です。{type}を有効にするには、さらにアイテムを追加してください。",
    select_pickup_location: "ピックアップ場所を選択してください",
    please_choose_pickup_location: "ピックアップ場所を選択してください",
    invalid_delivery_zone_title: "配達範囲外",
    invalid_delivery_zone_content:
        "指定されたアドレスが配達範囲外です。別のアドレスを入力してください。配達エリアとスケジュールは次のとおりです。",
    please_input_address: "有効な住所を入力してください",
    please_input_pickup_time: "ピックアップ時間を選択してください",
    please_input_delivery_time: "配達時間を選択してください",
    recommend_a_good_restaurant: "おすすめのお店",
    recommend_a_good_merchat: "おすすめの販売人",
    recommend_a_good_product: "おすすめの商品",
    guest_user: "ゲストユーザー",
    require_utensil: "食器などのリクエスト",
    already_have_account: "ゲストとしてチェックアウト",
    order_success_table: "ご注文ありがとうございます。準備が整い次第、ご注文をテーブルにお届けします。",
    order_success_parking_lot: "ご注文ありがとうございます。準備が整い次第、ご注文をお車にお送りします。",
    order_success_kiosk: "ご注文ありがとうございます。準備ができ次第お知らせします。",
    view_special_deals: "View special deals",
    more_items_to_get_special_deals: "Current order amount does not qualify for special deals. Please add more items.",
    special_deals_for_you: "Special deals for you",
    add_items: "Add items",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "メニュー",
    category: "カテゴリー",
    categories: "カテゴリー",
    store_details: "ストアの詳細",
    store_description: "ストアの説明",
    rank: "ランキング",
    customer_reviews: "カスタマーレビュー",
    single_selection: "シングルセレクション",
    optional: "オプション",
    pick_varied_options: "アイテムを{count}選択",
    sold: "{カウント}売れました",
    only_x_left: "残りは{count}だけ",
    sold_out: "完売",
    pick_option: "オプションを選択",
    contact_vendor: "販売者に連絡してください",
    call_vendor: "販売者に電話してください",
    self_input: "自己入力",
    with_option: "オプションを使用する",
    coupons: "{couponCount}クーポン",
    coupons_title: "クーポン",
    redeemed: "適用されました",
    redeem: "適用",
    remove: "削除する",
    valid_until: "{endDate}まで有効",
    checkout: "チェックアウト",
    shopping_cart: "ショッピングカート",
    empty_cart: "空にする",
    alert_empty_cart: "ショッピングカートを空にしてもよろしいですか？",
    discount_title: "ディスカウント",
    qrcode: "QRコード",
    m: "m",
    km: "km",
    min: "分",
    hour: "時",
    rating: "評価",
    rating_title: "評価",
    base_delivery_fee: "{flatDistance} {distanceUnit}内の配送料",
    min_delivery_amount: "最小配達額",
    max_delivery_distance: "最大配達距離",
    distance_title: "距離",
    takes_reservations: "予約を取る",
    more_info: "より詳しい情報",
    announcement: "お知らせ",
    no_announcement: "お知らせなし",
    buy_x_discount_flat: "{currency}{price}の購入、{currency}{discount}オフ",
    buy_x_discount_percent: "{currency}{price}の購入、 {discount}％オフ",
    search_products_title: "商品の検索",
    search_products_input_placeholder: "検索キーワードを入力してください...",
    search_products_no_match: "'{text}'はどの製品とも一致しませんでした。",
    alert_delete_product_with_options: "オプション付きの商品は、ショッピングカートからのみ削除できます。",
    store_closed_allowed_preorder: "現在営業時間外ですが、事前注文できます。",
    store_closed_allowed_preorder_x_mins_before:
        "このストアは現在営業時間外です。開店の{mins}分前から先行予約できます。",
    store_closed_soon_not_allowed_order: "商家即将打烊，目前不接受新订单。",
    results_colon: "結果： ",
    price: "価格",
    popularity: "人気",
    service_title: "サービス",
    product_title: "製品",
    all_title: "すべて",
    satisfied_title: "満足",
    unsatisfied_title: "不満",
    discount_rate: "{rate}％オフ",
    invalid_option_selection: "無効なオプションの選択",
    no_product_options: "製品オプションなし",
    single_selection_error: "'{productOption}'からオプション項目を1つ選択してください。",
    pick_ranged_options_error: "'{productOption}'の最小オプション選択は{count}アイテムです。",
    pick_fixed_options_error: "'{productOption}'から{count}オプションの選択を選択してください。",
    update_cart: "カートの更新",
    total_reviews: "レビュー",
    store_total_sold_count: "売り切れ",
    hot_products: "人気商品",
    related_products: "関連商品",
    store_hours: "営業時間",
    delivery_hours: "配達時間",
    add_for_delivery: "配達用に{currency}{amount}を追加する",
    open_in_app: "アプリで開く",
    qrcode_message: "このQRコードをスキャンしてストアを開く",
    review_submitted_validate: "レビューを送信していただきありがとうございます。承認後公開されます。",
    review_submitted: "レビューが送信されました。",
    add_update_review: "レビューを追加または更新してください。",
    post_review_failed: "レビューは提出されませんでした。ページを更新して、もう一度お試しください",
    not_accepting_orders: "現在、オンライン注文は受け付けておりません。",
    bookmark_sign_in_alert_store: "サインインしてこのストアをブックマークしましょう！",
    bookmark_sign_in_alert_product: "サインインしてこの製品をブックマークしましょう！",
    no_store_bookmarks: "ストアブックマークなし",
    no_store_bookmarks_message:
        "どのストアにもブックマークはありません。ストアのホーム画面の右上にあるハートのアイコンを押すと、ストアをブックマークできます。",
    no_store_bookmarks_button: "ストアブックマークを追加する",
    no_product_bookmarks: "商品のブックマークはありません",
    no_product_bookmarks_message:
        "商品のブックマークはありません。商品に関連付けられているハートのアイコンを押すと、商品をブックマークできます。",
    no_product_bookmarks_button: "商品ブックマークを追加する",
    no_group_bookmarks: "取引ブックマークなし",
    no_group_bookmarks_message:
        "取引ブックマークはありません。取引に関連付けられているハートのアイコンを押すと、取引をブックマークできます。",
    no_group_bookmarks_button: "取引ブックマークを追加する",
    add_item_another_menu_warning:
        "カートにはすでに別のメニュー（{menu}）のアイテムが含まれています。カートをクリアして、代わりにこのアイテムを追加しますか？",
    start_new_cart_question: "新しいカートにしますか？",
    change_menu: "メニューの変更",
    direction: "経路",
    website: "ウェブサイト",
    we_also_recommend: "こちらもおすすめ...",
    diff_delivery_price: "配達用に{x}を追加します。",
    discount_info_format: "{x}を購入する",
    discount_add_x_get_y: "さらに{x}追加すると、{y}オフになります。",
    discount_get_percentage_discount: " {x}オフをゲットする",
    discount_get_value_discount: " {x}オフをゲットする",
    discount_not_accepting_order: "申し訳ございません。現在、オンライン注文は受け付けておりません。",
    discount_store_closed_preorder: "営業時間外ですが予約は今すぐできます",
    discount_x_off_add_y_get_z: "{x}オフ、{y}をさらに追加すると{z}をフになります。",
    discount_x_off: "{x}オフ",
    switch_menu_warning_message:
        "選択したメニューは、異なる注文タイプ（{new_order_type}）をサポートしています。続行すると、カート内のアイテムはクリアされます。新しいメニューに切り替えますか？",
    logout_confirm_message: "ログアウトしてもよろしいですか?",
    logout_force_message:
        "ごめんなさい、アカウントが別のデバイスからログインしているか、タイムアウトしました。もう一度ログインしてください。",
    cannot_merge_account: "アカウントは既に存在します。別の電話番号を使用してください。",
    duplicate_social_error:
        "この電話番号は別のアカウントに登録されています。別の電話番号を試してください。この番号を使用するには、ログアウトして、この番号で再度ログインしてください。",
    store_has_no_products: "このストアには現在在庫がありません。",
    error_with_code: "[エラー{code}]: {error}",
    unable_to_load_any_data: "データを読み込めません",
    unstable_network: "ネットワークが遅い、または不安定です。ネットワーク接続を確認してください",
    incorrect_password: "パスワードが正しくありません。",
    country_code_error: "国コードエラー",
    incorrect_username_password: "ユーザー名/パスワードが正しくありません",
    verification_code_expired: "確認コードの有効期限が切れました。",
    verifcation_code_request_overlimit: "確認コードリクエスト回数が超過しました",
    incorrect_verification_code: "不正な確認コード",
    error_fetch_user_profile: "ユーザープロファイルの取得に失敗しました。",
    login_failed: "ログインに失敗しました",
    profile_update_success: "プロファイルの更新に成功しました",
    email_update_success: "メールの更新に成功しました",
    phone_update_success: "電話番号の更新に成功しました",
    failed_fetch_gift_card_detail: "ギフトカードの詳細を取得できませんでした。",
    gift_card_transfer_success: "ギフトカードの転送に成功しました。",
    gift_card_transfer_fail: "ギフトカードの転送に失敗しました。",
    record_not_found: " 記録が見当たりませんでした",
    failed_fetch_points_detail: "ポイントの詳細を取得できませんでした。",
    profile_update_failed: "ユーザープロファイルの更新に失敗しました。",
    email_update_failed: "メールの更新に失敗しました",
    phone_update_failed: "電話番号の更新に失敗しました",
    get_verification_code_success: "確認コードが携帯電話に送信されました。",
    verification_too_many_requests:
        "確認コードの送信リクエスト回数が上限を超えました。後でもう一度やり直してください。",
    invalid_table_number: "番号を入力してください",
    invalid_party_size: "番号を入力してください",
    email_required: "メールが必要です",
    password_required: "パスワードが必要です",
    phone_required: "電話が必要です",
    vc_required: "確認コードが必要です",
    coupon_invalid: "無効なクーポン",
    coupon_valid: "有効なクーポン",
    invalid_coupon: "クーポンコード{couponCode}が無効です。",
    coupon_applied: "カートにクーポンが適用されました。",
    invalid_address: "無効なアドレス",
    remaining_stock_header: "Remaining Stock",
    item_oos: "申し訳ございません。こちらの商品{oos_items}は在庫切れです。",
    invalid_shipping_method: "配送方法が無効です。別の配送方法をお試しください。",
    please_input_phone_number: "有効な電話番号を入力してください",
    please_input_first_name: "名前を入力してください",
    please_input_at_least_two_characters: "2文字以上入力してください。",
    please_input_last_name: "苗字を入力してください",
    please_input_shipping_method: "配送方法を入力してください",
    please_input_sub_shipping_method: "配送方法を入力してください",
    please_input_shipping_addresss: "タップして配送先住所を入力してください",
    please_input_pickup_addresss: "タップして集荷場所を選択してください",
    please_input_table_number: "テーブル番号を入力してください",
    please_input_payment_method: "タップしてお支払い方法を選択してください",
    please_input_valid_credit_card: "有効なクレジットカードを入力してください",
    please_input_billing_address: "請求先住所を入力してください",
    tap_to_show_on_map: "地図に表示するには、ここをクリックしてください。",
    please_add_some_items: "アイテムを追加してください",
    please_confirm_tips: "Tap to Set Tip Amount",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is $\u200B{tips_amount} in order to secure a driver for your order.",
    bind_phone: "電話番号を確認してください",
    please_bind_phone: "ご注文の前に、確認済みの電話番号が必要です。今すぐ電話番号を確認しますか？",
    go_to_bind: "紐付けをする",
    bind_phone_success: "電話番号の紐付けに成功しました",
    invalid_credit_card: "クレジットカードが無効です。別のお支払い方法を選択してください。",
    unsuccessful_checkout: "チェックアウトに失敗しました。再度お試しください",
    price_over_limit: "チェックアウトに失敗しました。価格が支払い限度額を超えています。",
    invalid_shp_mtd: "チェックアウトに失敗しました。配送方法が無効です。別の配送方法をお試しください。",
    invalid_pay_mtd: "チェック��ウトに失敗しました。お支払い方法が無効です。別のお支払い方法をお試しください。",
    please_input_belows: "未入力の箇所があります。",
    unable_to_change_here: "ここでは変更できません",
    please_select_on_previous_page: "前のページで選択してください",
    invalid_card_number: "無効なカード番号",
    invalid_zipcd: "無効な郵便番号",
    click_one_more_time_to_deselect: "選択を解除するには、もう一度クリックしてください。",
    paypal_payment_success_title: "支払い完了",
    paypal_payment_success_text: "お支払いを受け取りました。注文ページにリダイレクトされます。",
    error_payment_failed: "お支払い失敗",
    internal_server_error:
        "申し訳ございません。エラーが発生しました。後でもう一度試すか、販売者に直接お問い合わせください。",
    unexpected_error: "予期しないエラーが発生しました。後でもう一度やり直してください。",
    giftcard_pays_order_total:
        "選択したギフトカードで、すでに注文総額を支払い済です。追加のお支払い方法は必要ありません。",
    points_pays_order_total: "ポイントで、すでに注文総額を支払い済です。追加のお支払い方法は必要ありません。",
    giftcard_points_pays_order_total:
        "選択したギフトカードとポイントで、すでに注文総額を支払い済です。追加のお支払い方法は必要ありません。",
    digit_only: "数字のみを入力してください",
    please_selected_belows: "すべての選択を完了してください：",
    please_select_location_from_the_list: "リストから住所を選択してください。",
    alert_password_reset_success: "パスワードは正常に更新されました。これで、新しいパスワードでログインできます。",
    alert_email_not_found: "このメールはまだ登録されていません",
    alert_password_reset_email_send: "パスワードのリセットメールが送信されました。",
    x_is_required: " 必須項目です",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "位置情報の許可がブロックされました。このサービスが正しく動作しない可能性があります。",
    x_missing: "{x}を入力してください",
    x_invalid: "無効な{x}",
    x_select: "{x}を選択してください",
    do_you_want_to_receive_notification: "通知を受け取りますか？",
    gift_cards_points_alert:
        "ポイントの交換時にギフトカードを使用することはできません。ギフトカードを使用するには、最初にポイントを削除してください。",
    points_gift_cards_alert_message:
        "ギフトカードをご利用の際は、ポイントをご利用いただけません。ポイントを利用するには、まずギフトカードを削除してください。",
    not_found_description: "申し訳ございません。ページが見つかりません",
    not_found_detail_description:
        "申し訳ありませんが、お探しのページは存在しないか、削除されました。名前が変更されたか、一時的に利用できなくなりました。",
    back_to_home: "ホームページに戻ります",
    braintree_credit_card_invalid_message: "不具合があったようです。カードの詳細を確認して、もう一度お試しください。",
    please_input_valid_postal_code: "有効な郵便番号/郵便番号を入力してください。",
    please_input_valid_x: "有効な{x}を入力してください",
    too_many_card_attempt:
        "The provided card has been declined too many times; please try another card for the payment.",
    too_many_checkout_attempt: "Maximum retry limit exceeded. Please try again later.",
    payment_declined: "Payment Declined",
    credit_card_declined:
        "Please check the credit card information to make sure they were entered correctly and try again. If this does not resolve the problem, please call your card issuing bank to resolve.",
    ppcp_payment_declined: "支払いが拒否されました。もう一度お試しいただくか、別のカードでお支払いください。",
    no_giftcard: "You do not have available gift card",
    giftcard_insuffienct_fund: "Your Gift Card has an insufficient balance for the payment.",
    invalid_request_body: "Invalid request",
    pay_order: "注文の支払い",
    expiry_date: "有効期限",
    postal_code: "郵便番号",
    postal_code_zipcd: "郵便番号",
    country: "国",
    last_four: "最後の4つ",
    are_you_sure_delete_card: "このカードを削除してもよろしいですか",
    visa: "ビザ",
    master_card: "マスターカード",
    american_express: "アメリカンエキスプレス",
    discover: "ディスカバリー",
    jcb: "JCB",
    maestro: "マエストロ",
    credit_card_description: "ビザ、マスターカード、アメリカンエキスプレス",
    confirm_pay: "お支払いの確認",
    unsuccessful_payment: "お支払いに失敗しました。もう一度やり直してください。",
    reset_password_title: "パスワードを再設定する",
    new_password: "新しいパスワード",
    password_reset_continue: "送信",
    reset_by_text: "テキストメッセージでリセットする",
    reset_by_email: "Eメールによってリセットする",
    alert_vc_expired:
        "申し訳ございません。ご提供いただいた認証コードの有効期限が切れています。別の確認コードをリクエストして、もう一度お試しください。",
    email_verification: "メール認証",
    verification_success: "アカウントが確認されました",
    verify_account: "アカウントを確認していただきありがとうございます。{{email}。",
    verification_fail: "メールの確認に失敗しました",
    verify_error: "エラーが発生しました。認証メールを再送して、もう一度お試しください。{email}",
    view_account: "アカウントをみる",
    link_expired: "メールアドレスの確認ができません",
    link_expired_message:
        "認証リンクは有効期限が切れているか、すでに使用されています。もう一度お試しください。 {email}",
    email_already_verified: "お客様のアカウントはすでに認証されています。",
    resend_verification_email: "認証メールを再送する。",
    recently_viewed: "最近見たもの",
    by_phone: "電話で",
    by_email: "メールで",
    by_password: "既存のパスワードで",
    op_incorrect: "送信された元のパスワードが正しくありません。",
    password_change_success: "パスワードが正常に変更されました。",
    password_request_sent: "パスワード変更リクエストが送信されました",
    social_binding: "SNSとの紐付け",
    linked: "Linked",
    facebook: "フェイスブック",
    twitter: "ツイッター",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    or: "または",
    connect_with_facebook: "Facebookでサインインする",
    connect_with_twitter: "Twitterでログインする",
    connect_with_google: "Googleでサインインする",
    connect_with_wechat: "WeChatでサインインする",
    connect_with_apple: "Appleでサインインする",
    dont_have_account: "アカウントをお持ちではありませんか？",
    vouchers: "商品券",
    gift_card: "ギフトカード",
    bookmarks: "ブックマーク",
    bookmark_added: "ブックマークを追加しました",
    bookmark_deleted: "ブックマークを削除しました",
    account_info: "アカウント情報",
    my_points: "保有ポイント",
    my_giftcards: "保有ギフトカード",
    my_bookmarks: "ブックマーク",
    my_messages: "メッセージ",
    my_orders: "注文",
    avatar: "アバター",
    name: "名前",
    my_address: "住所",
    account_binding: "アカウントの紐付け",
    connect: "接続",
    safety_settings: "セキュリティ設定",
    login_password: "ログインパスワード",
    modify: "変更",
    delete: "削除",
    first_name: "名前",
    last_name: "苗字",
    phone_number: "電話番号",
    address: "住所",
    pick_place_on_map: "地図上で検索",
    done: "完了",
    enter_address_manually: "住所を手入力する",
    use_name_account: "アカウントで名前を使用する",
    new_address: "新しいアドレス",
    unit: "単位",
    street: "通り",
    city: "市",
    region: "地区",
    cntry: "国",
    zipcd: "郵便番号",
    postalcd: "郵便番号",
    required_text: "*は入力必須項目です。",
    map_search: "地図検索",
    accept: "受け入れる",
    invalid_last_name: "無効な苗字",
    invalid_credit_card_number: "無効なクレジットカード番号です",
    invalid_credit_card_cvv: "無効なCVV",
    invalid_cntry: "国がみつかりません",
    Invalid_zipcd: "無効な郵便番号",
    street_missing: "通りが空欄です",
    city_missing: "都市が空欄です",
    region_missing: "地区が空欄です",
    cntry_missing: "国がみつかりません",
    zipcd_missing: "郵便番号がみつかりません",
    billing_address: "請求先住所",
    confirm_delete_address: "削除アドレスを確認してください",
    delete_address_message: "このアドレスを削除してもよろしいですか？",
    address_edit: "住所編集",
    default: "デフォルト",
    set_as_default: "デフォルトとして設定",
    nick_name: "ニックネーム",
    update_profile_success: "プロファイルが正常に更新されました",
    update_profile_failed: "申し訳こざいません。プロファイルの更新に失敗しました。後でもう一度やり直してください。",
    last_name_missing: "苗字が空欄です",
    nick_name_missing: "ニックネームが空欄です",
    update: "更新",
    user_info: "ユーザー情報",
    new_phone: "新しい電話番号",
    new_email: "新しいメールアドレス",
    phone_placeholder: "電話番号",
    alert_phone_updated: "電話番号が正常に更新されました",
    alert_missing_fields: "申し訳ございません。未入力のフィールドがあります",
    alert_forbidden_action: "申し訳ございません、この行為は禁止されています。",
    alert_email_updated: "メールが正常に更新されました",
    alert_forbidden_email: "申し訳ございません、この行為は禁止されています。",
    alert_email_exists_sign_up: "申し訳ございません、このメールはすでにシステムに存在します。",
    alert_email_exists_update: "申し訳ございません、このメールはすでにシステムに存在します。",
    wallet: "ウォレット",
    confirm_delete_credit_card: "クレジットカードの削除を確認する",
    confirm_delete_credit_card_message: "このクレジットカードを削除してもよろしいですか？",
    add_credit_card_title: "クレジットカードを追加する",
    add_credit_card: "クレジットカードを追加する",
    save_credit_card: "クレジットカードを保存する",
    card_number: "カード番号",
    name_on_card: "カード記載の名前",
    cvv: "CVV",
    set_payment_password: "支払いパスワードを設定する",
    old_password: "以前のパスワード",
    confirm_new_password: "新しいパスワードを確認",
    password_not_detected: "旧パスワードが一致しません。修正して再試行するか、パスワードをリセットしてください。",
    server_error: "申し訳ございません、問題が発生しました。後でもう一度やり直してください。",
    unmatched_passwords: "新しいパスワードと確認パスワードが異なります。修正して再試行してください。",
    social_bind_not_allowed:
        "WeChatにおいて、SNSアカウント（WeChatを除く）を介してのログイン、紐付け、紐付けの解除は許可されていません。外部ブラウザを使用してください。",
    social_bind_success:
        "アカウントは{socialType}アカウントに正常にリンクされました。これで、{socialType}アカウントでログインできます！",
    social_bind_failed: "{socialType}アカウントのリンク中にエラーが発生しました。もう一度やり直してください。",
    connected: "接続済み",
    confirm_unbind_social_account: "{socialType}アカウントの紐付けを解除してもよろしいですか？",
    unbind_social_success: "{socialType}アカウントは紐付けされていません。",
    unbind_social_error:
        "申し訳ございません、現時点では、{socialType}アカウントのバインドを解除できませんでした。後でもう一度やり直してください。",
    create_new_vault_password: "クレジットカードを安全に保つためにパスワードを追加する",
    confirm_vault_password: "パスワードを確認する",
    input_vault_password: "お支払いパスワードを入力する",
    vault_password: "支払いパスワード",
    password_instructions: "パスワードは4〜6の数字である必要があります。",
    password_incorrect: "パスワードが正しくありません",
    add_address_invalid_address: "無効なアドレス。地図上で住所を選んでみてください。",
    country_not_exists: "国がみつかりません。地図上で住所を選んでみてください。",
    map_select_address: "検索バーに入力して、提案されたアドレスを選択してください。",
    error_current_location: "現在地の取得中にエラーが発生しました。検索バーに住所を入力してください",
    map_address_accept_error: "グーグルマップエラー。別のアドレスを試すか、アドレスを手動で入力してください。",
    invalid_name: "カード所有者の名前を入力してください。",
    invalid_cc_info: "クレジットカード情報が無効です。修正して再試行してください。",
    invalid_expiry_date: "クレジットカードの有効期限が無効です。修正して再試行してください。",
    alert_guest_account:
        "現在、ゲストユーザーとしてサインインしています。お名前、メールアドレス、電話番号をご記入いただくと、常連客となり、今後はパーソナライズされたサービスや店舗割引が受けられます。続行しますか？",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded: "アドレスリストの金額を超えました。保存できるアドレスは最大10個までです。",
    i_will_do_it_later: "あとでやります",
    finish_later: "後で終わらせる",
    skip: "スキップ",
    change_by_email: "メールで",
    change_by_text_message: "テキストメッセージで",
    change_by_existing_password: "既存のパスワードで",
    set_password: "パスワードを設定する",
    change_password: "パスワードを変更する",
    update_password: "パスワードを更新する",
    code: "コード",
    request_pass_reset: "パスワードリセットのリクエスト",
    confirm_password: "パスワードを認証する",
    set_a_password: "パスワードを設定する",
    set_a_password_caption: "今後のログインを高速化するためにパスワードを設定する",
    update_password_success: "パスワードの更新の成功しました。",
    update_password_fail: "パスワードの更新に失敗しました。あとでもう一度試してみてください",
    birthday: "誕生日",
    gender: "性別",
    not_provided: "設定されていません",
    currency: "通貨",
    male: "男性",
    female: "女性",
    none: "無し",
    year: "年",
    month: "月",
    day: "日",
    cannot_update_profile: "プロファイルを更新できません。",
    updated_profile: "プロファイルを更新しました。",
    giftcards: "ギフトカード",
    gift_card_details: "ギフトカードの詳細",
    all: "すべて",
    active: "有効",
    inactive: "無効",
    active_giftcard: "有効",
    inactive_giftcard: "無効",
    used: "使用済み",
    balance: "残高",
    book_value: "帳簿価格",
    purchase_date: "購入日",
    status: "状態",
    reassign: "再割り当て",
    reassign_giftcard: "ギフトカードの再割り当て",
    receivers_email_phone: "受信者の電子メール/電話",
    giftcard_reassign_success: "ギフトカードが{receiver}に正常に転送されました。",
    giftcard_reassign_missing_receiver: "受信者情報を入力してください",
    giftcard_reassign_missing_info: "ギフトカードを送信するには有効な受信者が必要です。",
    giftcard_reassign_failed:
        "申し訳ございません。ギフトカードを再割り当てしようとしてエラーが発生しました。後でもう一度やり直してください。",
    giftcard_reassign_failed_phone: "この電話番号は登録ユーザーのものではありません。別の電話番号を試してください。",
    giftcard_reassign_failed_email: "このメールは登録ユーザーのものではありません。別のメールをお試しください。",
    no_giftcards: "ギフトカードはありません",
    no_active_giftcards: "有効なギフトカードがありません。",
    no_active_giftcards_head: "有効なギフトカードはありません。",
    no_used_giftcards_head: "使用済みギフトカードはありません",
    no_used_giftcards: "ギフトカードの使用履歴はございません",
    purchase_a_giftcard: "ギフトカードを購入する",
    invalid_phone: "この電話番号は無効です。 電話番号の長さが正しいことを確認してください。",
    giftcard_reassign_tip:
        "ギフトカードの再割り当てにより、購入したギフトカードを登録済みのメールアドレスまたは電話番号を介して別のユーザーに送信できます。",
    gift_card_purchased: "購入済ギフトカード",
    paid_with_gift_card: "ギフトカードでの支払い",
    refunded: "返金",
    invalid_phone_number: "無効な電話番号",
    comment: "コメント",
    transactions: "売買",
    details: "詳細",
    date: "日付",
    balance_change: "残高の変化",
    points_balance: "ポイント残高",
    no_points: "現在、ポイントはありません。ショッピングをしてポイントを獲得しましょう。",
    no_points_head: "ポイントがありません",
    view_stores: "ストアを表示",
    last_updated: "最終更新日：{time}",
    point_balance: "残高：{balance}",
    point_store_name: "店名：{name}",
    points_received: "受け取ったポイント",
    no_orders: "注文履歴はありません",
    no_past_orders: "過去の注文はありません",
    no_upcoming_orders: "今後の注文はありません",
    shop_now: "今すぐ購入",
    pickup_method: "ピックアップ時間",
    delivery_method: "配達時間",
    instantCheckout_method: "インスタントチェックアウト",
    eatin_method: "Due Time",
    items: "アイテム",
    ongoing: "配達中",
    history: "履歴",
    upcoming: "次の",
    past_orders: "過去の注文",
    buzz_code: "バズコード",
    make_default: "デフォルトとして設定",
    add_address: "新しいアドレス",
    add_a_address: "新しいアドレスを追加",
    are_you_sure_delete_address: "このアドレスを削除してもよろしいですか？",
    select_address: "住所を選択する",
    search_address: "アドレスを検索する",
    current_location: "現在位置",
    use_name_on_account: "アカウントに名前を使用する",
    confirm_address: "アドレスを確認してください",
    location_user_denied: "位置情報サービス",
    geolocation_not_supported:
        "現在の位置情報サービスはサポートされていません。アドレスバーでアドレスを検索してください。",
    location_general_error: "現在地を取得できません。後でもう一度やり直してください",
    self_checkout: "クイックペイ",
    amount: "金額",
    add_comment: "コメントを追加する",
    contact_merchant: "販売者に連絡する",
    powered_by_goopter: "Powered by Goopter",
    pay: "支払い",
    self_checkout_disabled: "この店舗では、クイックペイはご利用いただけません。",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "注文の追加料金: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "確認コードをSMS送信します",
    payment_success_title: "お支払いは成功しました",
    payment_success_text: "お支払いを受け取りました。注文ページにリダイレクトされます。",
    go_to_order: "注文ページにいく",
    qr_scanner: "スキャンして注文",
    camera_instructor_title: "カメラへのアクセスが必要です",
    camera_instructor_description_dine_in:
        "Goopterアプリは次にカメラを開きます。注文を続行するにはカメラをQRコードに向けてください。",
    camera_instructor_button_text_dine_in: "スキャンして注文する",
    camera_error_title: "カメラアクセスが拒否されました",
    camera_error_description_dine_in: "注文のためにQRコードをスキャンするためにカメラアクセスを許可してください。",
    camera_error_button_text: "カメラを有効にする",
    error_invalid_code_dine_in: "食事のQRコードが無効です。正しいQRコードをスキャンしてください。",
    error_invalid_code_in_store: "このストアの店内注文のQRコードが無効です。正しいQRコードをスキャンしてください。",
    parking_lot: "駐車場番号",
    kiosk: "売店番号",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "カメラアイコンをタップして切り替え",
    scan_again: "再試行する",
    scanner_title: "スキャンするフレーム内のQRコード/バーコードを合わせます",
    orders_today: "今日の注文",
    orders_total: "注文合計",
    points_today: "今日のポイント",
    points_total: "ポイント合計",
    check_how_many_reward_points_from_referrals: "紹介者から受け取った報酬ポイントを確認",
    no_referrals_message:
        "まだあなたの紹介者はいません。あなたのお気に入りの店を友達に紹介して、そのお店で使える報酬ポイントをゲットしましょう！",
    order_id: "注文番号",
    no_referral_order: "紹介注文が見つかりません",
    from: "From",
    pts: "pts",
    update_order: "Update Order",
    your_order: "Your Order",
    schedule_for_later: "Schedule for later",
    ready_in_asap: "Ready in {start}-{end} minutes",
    when_would_you_like_to_order: "When would you like to order",
    no_shipping_address_selected: "No shipping address selected.",
    change: "Change",
    start_order: "Start Order",
    order_for: "Order for",
    current_menu: "Current Menu",
    all_menus: "All Menus",
    add_to_cart_success: "Product has been successfully added to the cart",
    gift_cards: "Gift Cards",
    phone_num: "Phone #",
    change_address: "Change Address",
    set_a_new_password: "Set a new password",
    set_a_new_password_caption: "Forgot your password? Set a new one to login quicker next time.",
    no_referral_orders_found: "No referral orders found",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Self-serve order",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "Payment for {orderType} #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
    deactivate_account: "Account deactivation & deletion",
    msg_deactivation: "Your account will be permanently deleted. After you delete your Goopter account, you will not be able to log in with your current account and retrieve any past orders or address records.",
    account_deactivated: "Account has been deactivated & deleted"
};

export default exports;
