import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import pageHelper from "./helper";

const initInputState = {
    card: null,
    inputCard: null,
    tips: 0,
    tipsPercent: null,
    nonce: null,
};

const initialState = {
    ...initInputState,
    userProfile: {},
    orderInstance: {},
    storeInstance: {},
};

const orderDetail = createSlice({
    name: "payNow",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = initialState;
        },
        updateTips: (state, { payload }) => {
            let { amount, type } = payload;

            if (type === "flat") {
                state.tips = _.isNaN(amount) ? 0 : amount;
                state.tipsPercent = null;
            } else if (type === "percent") {
                state.tipsPercent = amount;
            }
        },
        updatePrice: (state, { payload }) => {
            const grandTotal = state.grandTotal;
            const tipsPercent = state.tipsPercent;
            let tips = 0;
            if (tipsPercent || tipsPercent === 0) {
                tips = _.round(grandTotal * (tipsPercent * 0.01), 2);
                state.tips = tips;
            } else {
                tips = _.round(state.tips, 2);
            }
            state.totalDue = grandTotal + tips;
        },
        //from service
        initDataSuccess: (state, { payload }) => {
            state.userProfile = _.get(payload, "userProfileResponse.records", {});
            state.orderInstance = _.get(payload, "orderDetailResponse.records", {});
            state.storeInstance = _.get(payload, "storeResponse.records", {});
            state = Object.assign(state, pageHelper.getInitStateFromOrder(state.orderInstance));
            state.confirmCheckoutSuccess = false;
        },
        paySuccess: (state, { payload }) => {
            const RC = Number(_.get(payload, "RC", 400));
            const records = _.get(payload, "records", 400);
            if (RC === 200) {
                const payMethod = _.get(_.cloneDeep(state), "paymentMethod", "");
                const redirectUrl = `${window.location.origin}/account/orders/detail/${records.ord_id}`;
                const wechatPayUrl = _.get(records, "payment.wechat.pay_url", "")
                    ? `${_.get(records, "payment.wechat.pay_url", "")}&directpay=true`
                    : false;
                const aliPayUrl = _.get(records, "payment.alipay.pay_url", "");
                const unionPayUrl = _.get(records, "payment.unionpay.pay_url", "");
                const unionPayExpresUrl = _.get(records, "payment.unionpayexpress.pay_url", "");
                const channelPayUrl = _.get(records, "payment.alipay.channel_pay_url", "");
                const payPalPayUrl = payMethod === "paypal_express" && `/paypal?orderId=${records.ord_id}`;
                const payUrl = aliPayUrl || wechatPayUrl || unionPayUrl || unionPayExpresUrl || payPalPayUrl || "";

                state = Object.assign(state, initInputState);
                state.newOrderId = records.ord_id;
                state.newOrderStoreName = records.s_nm;
                state.payUrl = payUrl ? payUrl + `&redirect=${redirectUrl}` : "";
                state.aliPayChannelPayUrl = channelPayUrl ? channelPayUrl + `&return_url=${redirectUrl}` : "";
                state.confirmCheckoutSuccess = true;
            } else {
                if (RC === 400) {
                    state.displayModal = true;
                    state.displayModalContent = "invalid_request";
                } else if (RC === 399) {
                    state.displayModal = true;
                    state.displayModalContent = "order_payment_status_error";
                    state.displayModalHandlingType = "go_back";
                } else if (RC === 456) {
                    state.displayModal = true;
                    state.displayModalContent = "transaction_declined";
                } else if (RC === 459) {
                    state.displayModal = true;
                    state.displayModalContent = "price_over_limit";
                } else if (RC === 460) {
                    state.displayModal = true;
                    state.displayModalContent = "invalid_shp_mtd";
                } else if (RC === 461) {
                    state.displayModal = true;
                    state.displayModalContent = "invalid_credit_card";
                } else if (RC === 463) {
                    state.displayModal = true;
                    state.displayModalContent = "invalid_pay_mtd";
                } else if (RC === 464) {
                    state.displayModal = true;
                    state.displayModalContent = "invalid_shp_mtd";
                } else if (RC === 1000) {
                    let oosItems = [];

                    Object.keys(records.oos).forEach((responsePid) => {
                        state.productList.forEach((cartItem) => {
                            if (String(cartItem.pid) === String(responsePid)) {
                                oosItems.push(cartItem.nm);
                            }
                        });
                    });
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "item_oos";
                    state.displayModalHandlingType = "go_back";
                    state.displayModalKeywords = { oos_items: oosItems.join(", ") };
                } else if (RC === 4600) {
                    state.displayModal = true;
                    state.displayModalTitle = "payment_declined";
                    state.displayModalContent = "too_many_card_attempt";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 4601) {
                    state.displayModal = true;
                    state.displayModalTitle = "payment_declined";
                    state.displayModalContent = "too_many_checkout_attempt";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 4599) {
                    state.displayModal = true;
                    state.displayModalTitle = "payment_declined";
                    state.displayModalContent = "credit_card_declined";
                    state.displayModalHandlingType = "continue";
                } else {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "unsuccessful_payment";
                    state.displayModalHandlingType = "continue";
                }
            }
        },
        toastPPCPCheckOutError: (state, { payload }) => {
            state.displayModal = true;
            state.displayModalTitle = "payment_declined";
            state.displayModalContent = "ppcp_payment_declined";
            state.displayModalHandlingType = "continue";
        },
    },
});

export const {
    setState,
    updatePrice,
    updateTips,
    //to service
    initDataSuccess,
    paySuccess,
    toastPPCPCheckOutError,
} = orderDetail.actions;

export default orderDetail.reducer;
