const exports = {
    " ": " ",
    go_back: "Zurück gehen",
    notifications: "Benachrichtigungen",
    settings: "Einstellungen",
    logout: "Ausloggen",
    logged_out_error: " Sie wurden ausgeloggt, bitte melden Sie sich an und versuchen Sie es erneut.",
    logged_out_success: " Sie wurden erfolgreich abgemeldet. Bis zum nächsten Mal!",
    confirm_logout_text: " Sind Sie sicher, dass Sie sich ausloggen wollen?",
    ok: "Ok",
    error: "Fehler",
    no: "Nein",
    yes: "Ja",
    language: "Sprache",
    account: "Konto",
    login: "Anmelden",
    cancel: "Abbrechen",
    store: "Geschäft",
    products: "Produkte",
    restaurant: "Restaurant",
    groupsale: " Gruppenangebot",
    travel: "Reisen",
    shopping: "Shoppen",
    service: "Service",
    save: "Speichern",
    confirm: "Bestätigen",
    success: "Erfolg",
    loading: "Laden",
    warning: "Warnung",
    general_error: " Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal.",
    orders: "Meine Aufträge",
    click_to_input_address: "Zur Eingabe der Adresse bitte anklicken",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    input: " Eingabe",
    edit: "Bearbeiten",
    years: "Jahre",
    quarters: "Quartale",
    months: "Monate",
    weeks: "Wochen",
    days: "Tage",
    days_2: "Tage",
    time: "Zeit",
    times: "Zeiten",
    hours: "Std.",
    minutes: "Min.",
    seconds: "Sek.",
    milliseconds: "Ms.",
    sort: "Sortieren",
    filter: "Filter",
    today: "Heute",
    tomorrow: "Morgen",
    yesterday: "Gestern",
    this_week: "Diese Woche",
    last_week: "Letzte Woche",
    this_month: "Dieser Monat",
    last_month: "Letzter Monat",
    last: "Letzte",
    next: "Nächste",
    now: "Jetzt",
    mon: "Mon",
    tue: "Di",
    wed: "Mi",
    thu: "Do",
    fri: "Fr",
    sat: "Sa",
    sun: "So",
    mo: "Mo",
    tu: "Di",
    we: "Mi",
    th: "Do",
    fr: "Fr",
    sa: "Sa",
    su: "So",
    share: "Teilen",
    dont_show_this_again: "Nicht mehr anzeigen",
    update_order: "Auftrag aktualisieren",
    your_order: "Dein Auftrag",
    schedule_for_later: " Für später einplanen ",
    ready_in_asap: "Bereit in {start}-{end} Minuten",
    when_would_you_like_to_order: "Wann möchten Sie bestellen?",
    no_shipping_address_selected: "Keine Lieferadresse ausgewählt",
    change: "Ändern",
    start_order: "Auftrag starten",
    order_for: "Bestellen für",
    jan: "Januar",
    feb: "Februar",
    mar: "März",
    apr: "April",
    may: "Mai",
    jun: "Juni",
    jul: "Juli",
    aug: "August",
    sep: "September",
    oct: "Oktober",
    nov: "November",
    dec: "Dezember",
    view_more: "Mehr anzeigen",
    viewing: "Ansicht",
    show_filters: "Filter anzeigen",
    reward_history_filter: "Filter für die Prämienhistorie",
    date_range: "Datumsbereich",
    my_referrals: "Meine Empfehlungen",
    how_to_do_it: "Wie man Prämienpunkte bekommt, und Regeln für Prämienpunkte",
    how_to_do_it_referrals:
        "1. Teilen Sie den Empfehlungslink oder QR-Code mit Ihren Freunden und in den sozialen Medien. Diese werden zu Ihrer Empfehlung, sofern sie nicht zuvor von jemand anderem empfohlen wurden und einen Kauf über Ihren Link tätigen. Sie erhalten {v2pCustomerRef} Reward-Punkte für jeden Dollar ihres Einkaufs und zukünftige Einkäufe. Bei Ihrem ersten Kauf durch Ihre Empfehlung erhalten Sie Reward-Punkte, die dem Wert vor der Steuer * {v2pCombined} entsprechen (z.B. wenn der Bestellwert vor der Steuer$ {exampleBasePurchase} ist, werden Ihre Reward-Punkte sein: {exampleBasePurchase} * {v2pCombined} = {exampleResultPoints}). und für jede Bestellung, die Ihr Geworbener anschließend tätigt, erhalten Sie Reward-Punkte, die dem Dollarwert vor Steuern seiner Bestellung entsprechen * {v2pCustomerRef}. Sollten Sie den Link mit jemandem teilen, der bereits geworben wurde, erhalten Sie einmalig Reward-Punkte {v2pOrderRef} für jeden Dollar, den der Geworbene für seinen aktuellen Einkauf ausgibt.{br}{br}2. Ein Beispiel: Empfiehlt A einen B, der zuvor noch nicht von anderen geworben wurde, erhält A die Punkte für die erste Bestellung von B und erhält für jede weitere Bestellung von B Prämienpunkte in Höhe seines Bestellwerts vor Steuern * {v2pCustomerRef}. Wurde B bereits von anderen geworben, erhält A dennoch einen einmaligen Prämienpunkt auf der Grundlage von Bs einmaliger Bestellung.{br}{br}3. Jeder {p2v} Prämienpunkt wird für jeden $1-Einkauf in diesem Geschäft verdient.",
    copy_ref_url: "Empfehlungs-URL kopieren",
    total: "Gesamt",
    generate_qr_code: "QR-Code generieren",
    ref_orders: "Aufträge",
    income: "Einkommen",
    check_how_much_money_earned: "Sehen Sie, wie viel Geld Sie verdient haben",
    check_how_much_rewards_earned: "Sehen Sie, wie viele Prämienpunkte Sie gesammelt haben",
    redeemable: "Einlösbar",
    total_redeemed: "Gesamt eingelöst",
    sales: "Verkäufe",
    check_how_many_sales: "Prüfen Sie, wie oft Sie schon verkauft haben",
    rewards_history: "Prämienhistorie",
    check_daily_history: "Überprüfen Sie Ihre täglichen Prämientransaktionen",
    orders_info: "Sehen Sie, wie viele Bestellungen Ihre Freunde aufgegeben haben",
    referrals_info: "Informationen über Empfehlungen",
    referrals_info_desc:
        "Dies sind die Informationen über Ihre Empfehlungen. Klicken Sie auf jeden einzelnen, um Informationen über Bestellungen und Verkäufe zu erhalten.",
    admin_reward_action: "Admin-Prämienpunkte",
    points_redeemed: "Eingelöste Punkte",
    customer_invitation: "Kundenempfehlungspunkte",
    order_invitation: " Empfehlungspunkte von Aufträgen ",
    admin_credit: "Admin Credit",
    sales_rule: "Punkte der Verkaufsregeln",
    admin_revert_points: "Admin Revert-Punkte",
    refunded_order: "Erstattete Auftragspunkte",
    ref_successfully_copied: "Empfehlungscode erfolgreich kopiert",
    click_tips_with_helper_text: "Trinkgelder (zum Aktualisieren Betrag anklicken)",
    click_set_points: "Klicken, um Punkte einzulösen",
    click_to_search_address: "Klicken Sie, um die Adresse zu suchen",
    click_please_input_shipping_addresss: "Zur Eingabe der Lieferadresse klicken",
    click_please_input_pickup_addresss: "Klicken Sie, um den Abholort zu wählen",
    click_please_input_payment_method: "Klicken Sie, um die Zahlungsmethode auszuwählen",
    click_to_show_on_map: "Hier klicken, um auf der Karte anzuzeigen",
    tap_to_search_address: "Tippen Sie, um die Adresse zu suchen",
    sorry_search: "Kein Suchergebnis für den angegebenen Suchbegriff.",
    try_again_search: "Bitte versuchen Sie einen anderen Suchbegriff",
    recent_searches: "Letzte Suchanfragen",
    recommendations: "Empfehlungen",
    deals: "Angebote",
    view_all: "Alle anzeigen",
    products_not_available:
        "Der/die folgende(n) Artikel ist/sind derzeit nicht verfügbar und wird/werden aus Ihrem Warenkorb entfernt:",
    shopping_cart_empty: "Ihr Einkaufswagen ist leer.",
    not_available_dialog_title: "Jetzt nicht verfügbar",
    not_available_dialog_captain: "Dieser Artikel ist nur in den folgenden Stunden verfügbar.",
    sorry_product_not_available: "Dieser Artikel ist leider nur in den folgenden Stunden verfügbar.",
    feedback: "Rückmeldung",
    download_qr: "Laden Sie den QR-Code herunter",
    download_qr_mobile: "Langes Drücken auf das Bild zum Herunterladen",
    download_poster: "Laden Sie das Poster herunter",
    download_poster_mobile: "Drücken Sie lange auf das Bild, um es herunterzuladen",
    bewerte_das_produkt: "Bewerten Sie das Produkt",
    are_you_sure_cancel_review: "Sind Sie sicher, dass Sie diese Bewertung verwerfen wollen?",
    submit: "Absenden",
    review_order_placeholder: "Ihre Bewertung wird Menschen helfen, eine bessere Wahl zu treffen",
    review_submitted_message: "Vielen Dank für Ihre Rezension, sie wird veröffentlicht, sobald sie bestätigt wurde",
    Bitte_Eingabe_oder_Auswahl: "Bitte wählen Sie einen Grund aus oder geben Sie ihn ein.",
    review_service: "Bewertungsservice",
    rate_hint: "Tippen Sie auf das Sternsymbol, um zu bewerten",
    not_available: "Nicht verfügbar",
    current_not_available: "Derzeit nicht verfügbar",
    allow_reservation: "Reservierung zulassen",
    has_tv: "Hat einen Fernseher",
    has_free_wifi: "Verfügt über kostenloses Wi-Fi",
    has_outdoor_seating: "Verfügt über Sitzgelegenheiten im Freien",
    kid_friendly: "Kinderfreundlich",
    group_friendly: "Gruppenfreundlich",
    business_friendly: "Geschäftsfreundlich",
    street_parking: "Straßenparkplatz",
    free_parking: "Kostenloses Parken",
    paid_parking: "Bezahltes Parken",
    noise_level: "Lärmpegel",
    average: "Durchschnitt",
    loud: "Laut",
    very_loud: "Sehr laut",
    casual: "Leger",
    business_casual: "Business-Leger",
    smart_casual: "Smart-Leger",
    business: "Geschäftlich",
    semi_formal: "Semi Formal",
    has_alcohol: "Hat Alkohol",
    environment: "Umgebung",
    attire: "Kleidung",
    upscale: "Gehoben",
    classy: "Stilvoll",
    hipster: "Hipster",
    intim: "Intim",
    no_alcohol: "Kein Alkohol",
    beer_and_wine: "Bier und Wein",
    full_bar: "Volle Bar",
    write_your_review_here: "Schreiben Sie Ihre Rezension hier",
    rate_the_store: "Den Store bewerten",
    more_information: "Mehr Informationen",
    merchant_information: "Informationen über den Händler",
    additional_pages: "Zusätzliche Seiten",
    store_info: "Laden-Infos",
    store_overview: "Übersicht",
    opens: "Öffnet",
    closes: "Schließt",
    current_store: "Aktueller Laden",
    order: "Bestellung",
    my_order: "Meine Bestellung",
    deliver_to: "Liefern an",
    delivery: "Lieferung",
    check_out: "Auschecken",
    options: "Optionen",
    add_to_cart: "In den Warenkorb",
    pick_one: "Erforderlich, wählen Sie 1",
    pick_amount: "Erforderlich, wählen Sie genau {min}",
    pick_range_up_to: "Wählen Sie bis zu {max}",
    pick_range_required: "Erforderlich, wählen Sie {min}-{max}",
    pick_optional: "Optional",
    pick_one_with_name: "{nm} ist erforderlich, wählen Sie 1",
    pick_amount_with_name: "{nm} ist erforderlich, wählen Sie genau {min}",
    pick_range_up_to_with_name: "{nm} wähle bis zu {max}",
    pick_range_required_with_name: "{nm} ist erforderlich, wählen Sie {min}-{max}",
    up: "Hoch",
    search_products: "Produkte suchen",
    current_menu: "Aktuelles Menü",
    all_menus: "Alle Menüs",
    search: "Suche",
    discount_by: "{x} Rabatt auf {y}",
    discount_by_ohne_Preis: "{x} Rabatt",
    store_closed: "Laden geschlossen",
    store_closed_not_allow_order: "Geschlossen",
    order_items: "Artikel bestellen",
    are_you_sure_clear_cart: "Sind Sie sicher, dass Sie Ihren Warenkorb leeren möchten?",
    are_you_sure_keeping_cart:
        "Es befinden sich gespeicherte Artikel im Warenkorb. Möchten Sie die Artikel im Warenkorb behalten?",
    clear_the_cart: "Warenkorb leeren",
    keep_the_cart: "Behalten",
    qr_code: "QR-Code",
    poster: "Poster",
    clear: "Löschen",
    results: "Ergebnisse",
    search_results: "{x} Suchergebnisse",
    pending_acceptance: "Ausstehende Akzeptanz",
    delivery_time: "Lieferfrist",
    pickup: "Abholung",
    takeout: "Abholung",
    eatin: "Im Restaurant essen",
    order_pickup_time: "Abholzeit: {time}",
    order_delivery_time: "Lieferzeit: {time}",
    transferred_to: "Übertragen an: {contact}",
    received_from: "Empfangen von: {contact}",
    discount_amount: "{currentDiscount} aus.",
    remaining: "Fügen Sie {remaining} mehr hinzu, um {amount} Rabatt zu erhalten.",
    remaining_delivery: "Fügen Sie {remaining} für die Lieferung hinzu.",
    remaining_pickup: " Addieren Sie {remaining} für die Abholung.",
    menu_available_at: "Dieses Menü ist nur am @{available} verfügbar...",
    please_input_name: "Bitte Name eingeben",
    no_address_head: "Keine gespeicherten Adressen",
    no_address_message: "Sie haben derzeit keine Adressdatensätze.",
    select_branch: "Bitte eine Filiale auswählen",
    store_on_hold: "Die Filiale ist in der Warteschleife und nimmt derzeit keine Bestellungen entgegen.",
    out_of_stock: "Nicht auf Lager",
    load_more: "Mehr laden",
    no_order_message: "Bestellungen werden hier angezeigt, nachdem Sie eine Bestellung erstellt/abgeschlossen haben.",
    no_order: "Keine Bestellungen",
    create_an_order: "Eine Bestellung erstellen",
    no_more_data: "Keine weiteren Daten",
    no_search_results: "Keine Suchergebnisse",
    group_purchase: "Gruppenkauf",
    show_more: "Mehr anzeigen",
    show_less: "Weniger anzeigen",
    purchase_history: "Kaufhistorie",
    start_time: "Startzeit",
    end_time: "Endzeit",
    expired: "Abgelaufen",
    offer_expires_in: "Angebot läuft ab in: {time}",
    offer_starts_in: "Angebot beginnt in: {time}",
    offer_starts_on: "Startzeit: {time}",
    offer_ends_on: "Endzeit: {time}",
    remaining_stock: "{count} verbleibend",
    personen_bestellt: "{amt} bestellte Personen",
    select_category: "Kategorie auswählen",
    selection_error_min: "{min} Auswahlen ist erforderlich für {option}.",
    no_giftcard_message: "Geschenkkarten, die Sie besitzen, werden hier angezeigt.",
    upsell_products: "Das könnte Sie auch interessieren...",
    switch_to_dine_in: "Bestätigen Sie das Essen im Restaurant",
    switch_to_in_store: "Bestätigen Sie Im Laden",
    switch_to_dine_in_message:
        "Bitte tippen Sie Ihre Tischnummer ein, um mit der Bestellung im Restaurant fortzufahren, tippen Sie auf Abbrechen, um bei der Bestellart {type} zu bleiben.",
    surcharge: "Zuschlag",
    store_surcharge: "Online-Zuschlagsgebühr",
    order_surcharge: "Bestell-Zuschlag",
    item_total: "Artikel gesamt",
    surcharge_paid: "Aufpreis (bezahlt)",
    surcharge_unpaid: "Zuschlag (unbezahlt)",
    related_id: "Zugehörige Bestellnummer",
    related: "Zugehörig",
    surcharege_paid_message:
        "Der Zuschlagsbetrag ist in dieser Bestellung nicht enthalten. Bitte beziehen Sie sich auf die Bestellung #{id}",
    surcharge_paid_to_message: "Mit dieser Bestellung wird der Zuschlag für die Bestellung #{id} bezahlt",
    switch_to_dine_in_confirm:
        "Es wurde festgestellt, dass Sie derzeit nicht im Geschäft sind, sind Sie sicher, dass Sie eine Bestellung im Restaurant aufgeben möchten?",
    switch_to_in_store_confirm:
        "Sie scheinen gerade nicht in der Filiale zu sein, sind Sie sicher, dass Sie eine Bestellung im Laden aufgeben möchten?",
    pull_to_refresh: "Zum Aktualisieren ziehen",
    no_products_record: "Keine Produkte gefunden.",
    delivery_locations: "Lieferzonen / Zeitplan",
    pickup_locations: "Abholorte",
    min_amount: "Min. Bestellung",
    min_order_amount: "Min. Bestellbetrag",
    free_delivery_amount: "Betrag für kostenlose Lieferung",
    delivery_times: "Lieferzeiten",
    pickup_times: "Abholzeiten",
    time_range: "Zeitbereich",
    store_location: "Standort der Filiale",
    abholorte_zeitplan: "Abholorte / Zeitplan",
    roll_over_image_to_zoom_in: "Zum Vergrößern über das Bild rollen",
    about: "Über",
    product_information: "Produkt-Kurzbeschreibung",
    product_details: "Produkt-Details",
    buy_now: "Jetzt kaufen",
    see_more_product_details: "Weitere Produktdetails anzeigen",
    add_to_cart_success: "Das Produkt wurde erfolgreich in den Warenkorb gelegt",
    video: "Video",
    page_of: "Seite {page} von {totalNumOfPages}",
    warning_max_purchase_limit_reached: "Achtung: maximale bestellmenge erreicht",
    max_purchase_limit_reached_msg: "maximale Bestellmenge für dieses Produkt: {max_sale_qty}",
    warning_max_purchase_qty_exceeded_msg:
        "The following items have exceeded the maximum purchase quantity. Item quantities will be modified to fit within the limit.",
    max_purchase_qty: "Maximum Purchase Quantity: {qty}",
    warning_min_sale_qty_not_met_msg: "The following items do not fulfill the minimum purchase quantity.",
    min_purchase_qty: "Minimum Purchase Quantity: {qty}",
    remove_items: "Remove items",
    increase_item_quantities: "Increase item quantities",
    store_offline_message: "Sorry, we are currently not accepting online orders. Please check back later.",
    order_payment_status_error: "Der Shop hat diese Bestellung akzeptiert. Bitte zahlen Sie persönlich.",
    order_comment_status_error:
        "Die Filiale hat begonnen, diese Bestellung vorzubereiten; bitte kontaktieren Sie die Filiale für Änderungswünsche. Tel: {phone}.",
    oos_items_cannot_be_added:
        "Der/die Artikel {oos_items} sind nicht verfügbar und konnten nicht in den Warenkorb gelegt werden.",
    order_cancelled: "Bestellung storniert",
    pending_store_confirmation: "Warten auf die Bestätigung der Bestellung durch das Geschäft",
    pending_customer_confirmation: "Ausstehende Kundenbestätigung",
    store_accepted: "Bestellung wird akzeptiert",
    order_preparing: "Bestellung wird vorbereitet",
    store_is_preparing: "Filiale bereitet vor",
    ready_pickup: "Bestellung ist bereit zur Abholung",
    ready_courier_pickup: "Bestellung ist bereit für die Lieferung",
    rejected_by_courier: "Abgelehnt durch Kurier",
    courier_assigned: "Kurier zugewiesen",
    courier_accepted: "Kurier hat die Aufgabe angenommen",
    courier_heading_to_store: "Kurier auf dem Weg zur Filiale",
    courier_arrived_at_store: "Kurier ist in der Filiale angekommen",
    courier_collected_package: "Der Kurier hat das Paket abgeholt",
    courier_heading_to_customer: "Die Bestellung wird zugestellt",
    courier_arrived_at_customer: "Kurier ist beim Kunden eingetroffen",
    delivered_order: "Auftrag ausgeliefert",
    order_changes_description:
        "Es sind Änderungen an Ihrer Bestellung vorgenommen worden. Bitte lesen Sie die vorgeschlagenen Änderungen und bestätigen Sie diese so schnell wie möglich, um eine mögliche Verzögerung Ihrer Bestellung zu vermeiden.",
    confirm_item_change: "Bestätigen Sie die Auftragsänderung",
    are_you_sure_reject:
        "Wenn Sie die Änderungsvorschläge ablehnen, wird dieser Artikel aus Ihrer Bestellung entfernt, sind Sie sicher, dass Sie fortfahren möchten?",
    accepted: "Angenommen",
    rejected: "Abgelehnt",
    added: "Hinzugefügt",
    removed: "Entfernt",
    updated: "Aktualisiert",
    add_back: "Zurückfügen",
    to: "bis",
    updated_an_item: "Aktualisierte einen Artikel",
    added_an_item: "Ein Element hinzugefügt",
    removed_an_item: "Ein Element wurde entfernt",
    replaced_an_item: "Einen Artikel ersetzt",
    are_you_sure_change_order_confirm:
        "Sind Sie sich sicher, dass Sie die Änderungen an dieser Bestellung bestätigen möchten?",
    order_change_return_price_delta:
        "Der Gesamtpreis für die aktualisierte Bestellung beträgt {new_price}, die Differenz wird Ihnen bei Abschluss der Bestellung erstattet.",
    order_change_pay_price_delta:
        "Der Gesamtbetrag für die aktualisierte Bestellung ist {new_price}, Ihre Karte wird nach Abschluss der Bestellung mit diesem Betrag belastet.",
    order_change_pay_new_price:
        "Der Gesamtpreis für die aktualisierte Bestellung ist {new_price}, Sie müssen die Preisdifferenz von {preis_delta} bezahlen. Bitte begleichen Sie den Aufpreis, damit sich Ihre Bestellung nicht verzögert.",
    order_change_reject_all:
        "Sie haben alle vorgeschlagenen Änderungen abgelehnt. Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
    order_change_reject_all_refund:
        "Sie haben alle vorgeschlagenen Änderungen abgelehnt. Sind Sie sicher, dass Sie die Bestellung stornieren möchten? Bei Stornierung erhalten Sie eine Rückerstattung von {price_delta}.",
    order_change_refund:
        "Der Gesamtbetrag der aktualisierten Bestellung beträgt {new_price}, Sie erhalten die Preisdifferenz von {price_delta} erstattet.",
    order_again_adds_items: "[Order Again] fügt Ihrem Warenkorb automatisch dieselben Artikel hinzu.",
    hide_menu: "Artikel ausblenden",
    show_menu: "Artikel anzeigen",
    copy: "Kopieren",
    copied_success: "Kopierte Bestellung # in die Zwischenablage",
    shipping_time: "Versandzeit",
    order_time: "Bestellzeitpunkt",
    pickup_time: "Abholzeit",
    request_due_time: "Fälligkeitszeit",
    requested_pickup_time: "Gewünschte Abholzeit",
    requested_delivery_time: "Gewünschte Zustellzeit",
    ready_pickup_time: "Zeit für Abholbereitschaft",
    estimated_delivery_time: "Voraussichtliche Lieferfrist",
    store_name: "Name der Filiale",
    shipping_info: "Versandinformationen",
    order_info: "Bestellinformationen",
    table_info: "Tabelleninfo",
    serve_time: "Geschätzte Bereitschaftszeit",
    buzz: "Klingel",
    order_detail: "Bestellungsdetail",
    alipay: "Alipay",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Kreditkarte",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "Online-Banküberweisung",
    emt_description: "Bezahlen Sie mit Online-Banküberweisung nach Auftragserteilung",
    payment_received: "Zahlung erhalten",
    payment_pending: "Zahlung ausstehend",
    payment_cancelled: "Zahlung storniert",
    payment_failed: "Zahlung fehlgeschlagen",
    emt_payment_note_long:
        "Für diesen Auftrag haben Sie die Zahlungsart Online-Banküberweisung/EMT gewählt. Bitte veranlassen Sie die Zahlung an die Filiale so bald wie möglich und benachrichtigen Sie die Filiale, um Verzögerungen bei Ihrer Bestellung zu vermeiden.",
    emt_payment_password: "Bitte senden Sie {amount} an {emt_account} mit dem Zahlungskennwort {emt_password}",
    emt_payment_account: "Bitte senden Sie {amount} an {emt_account}",
    free: "Keine Zahlung erforderlich",
    dine_in: "Im Restaurant essen",
    in_store: "Im Laden",
    flatrate: "Pauschalpreis",
    freeShipping: "Digitales Asset",
    instantCheckout: "Sofortige Kaufabwicklung",
    pending: "Ausstehende Bestätigung",
    processing: "Bearbeitung",
    payment_review: "Zahlung wird geprüft",
    completed: "Abgeschlossen",
    canceled: "Abgebrochen",
    closed: "Geschlossen",
    pending_payment: "Ausstehende Zahlung",
    holded: "Angehalten",
    cancel_order: "Bestellung stornieren",
    order_num: "Bestellung #",
    order_status: "Bestellstatus",
    order_date: "Uhrzeit der Bestellung",
    order_type: "Bestellungsart",
    shipping_method: "Versandart",
    expected_time: "Fälligkeitszeit",
    customer: "Kunde",
    table_number: "Tabelle #",
    party_size: "Parteigröße",
    delivery_address: "Lieferadresse",
    pickup_location: "Abholort",
    store_discount: "Ladenrabatt",
    coupon: "Gutschein",
    shipping_and_handling: "Versand & Bearbeitung",
    shipping_and_handling_with_distance: "Liefergebühr ({distance})",
    order_total: "Gesamtbetrag",
    giftcard_payment: "Geschenkkartenzahlung",
    points_payment: "Punkte-Zahlung",
    points_payment_with_points: "Punktezahlung ({x})",
    comments: "Kommentare",
    reorder: "ERNEUT BESTELLEN",
    general_order_error:
        "Es ist ein Fehler aufgetreten. Bitte kehren Sie zur Bestellliste zurück und versuchen Sie es erneut",
    order_unexist: "Es wurde kein Bestelldatensatz gefunden.",
    you: "Sie",
    cancel_order_warning: "Sind Sie wirklich sicher, dass Sie diese Bestellung stornieren wollen?",
    cancel_order_success: "Ihre Bestellung #{order_id} bei {store_name} wurde erfolgreich storniert.",
    cancel_order_failed: "Bestellung konnte nicht storniert werden, bitte versuchen Sie es später erneut",
    pay_now_unavailable: "Online-Zahlung nicht verfügbar",
    no_online_payment:
        "Leider nimmt das Geschäft im Moment keine Online-Zahlungen an. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie den Laden direkt.",
    pay_now: "Jetzt bezahlen",
    reviews: "Bewertungen",
    write_review: "Eine Bewertung schreiben",
    write_review_for: "Schreiben Sie Ihre Bewertung für {name}.",
    update_review: "Rezension aktualisieren",
    overall: "Insgesamt",
    add_a_review: "Eine Bewertung hinzufügen",
    rate_our_service: "Bewerten Sie unseren Service",
    rate_our_delivery: "Bewerten Sie unsere Lieferung",
    post: "Beitrag",
    requested_time: "Angeforderte Zeit",
    planned_time: "Geplante Zeit",
    exp_dt: "Fällige Zeit",
    ord_total: "Betrag",
    s_method: "Auftragsart",
    p_method: "Zahlungsmethode",
    start_processing_message: "Sind Sie sicher, dass Sie Ihre Bestellung(en) als in Bearbeitung markieren wollen?",
    mark_as_complete_message: "Sind Sie sicher, dass Sie die Bestellung(en) als abgeschlossen markieren wollen?",
    cancel_order_message: "Sind Sie sicher, dass Sie die Bestellung(en) stornieren möchten?",
    complete: "Vollständig",
    reject: "Ablehnen",
    total_refunded: "Insgesamt zurückerstattet",
    refund_amount: "Betrag",
    refund_all: "Alle Erstattungen",
    remark: "Bemerkung",
    "edit-shipping-fee-message":
        "Die Aktualisierung der Versandkosten wird zur Stornierung der vorherigen Bestellung führen und eine neue Bestellung mit den aktualisierten Versandkosten erstellen, möchten Sie fortfahren?",
    edit_shipping_info: "Versandadresse bearbeiten",
    additional_info: "Zusätzliche Informationen",
    exp_time: "Gewünschte Lieferzeit",
    estimatedDeliTime: "Geschätzte Lieferzeit",
    "create-new-order-message":
        "Sind Sie sicher? Diese Bestellung wird storniert und stattdessen eine neue Bestellung erstellt.",
    order_and_account_information: "Bestell- und Kontoinformationen",
    adress_info: "Adressinformationen",
    shipping_address: "Versandadresse",
    payment_and_shipping_method: "Zahlungs- und Versandmethode",
    payment_info: "Zahlungsinformationen",
    shipping_handling_info: "Informationen zur Versandabwicklung",
    item_order: "Bestellte Artikel",
    raw_total: "Rohsumme",
    notes_for_this_order: "Anmerkungen zu dieser Bestellung",
    alert_on_notification: "Warnung bei Benachrichtigung",
    sub_comment: "Kommentar abgeben",
    modify_discount: "Rabatt ändern",
    add_discount: "Rabatt hinzufügen",
    modify_tips: "Tipps ändern",
    add_tips: "Tipps hinzufügen",
    modify_shipping_fee: "Versandgebühr ändern",
    discount: "Rabatt",
    edit_payment: "Zahlung aktualisieren",
    edit_extra: "Info aktualisieren",
    edit_payment_method: "Zahlung aktualisieren",
    "select_receipt(s)_to_print": "Zu druckende Quittung(en) auswählen",
    change_payment_to: "Sind Sie sicher, dass Sie die Zahlungsmethode auf ",
    handling: "Bearbeitung",
    coupon_discount: "Coupon-Rabatt",
    phone: "Telefonnummer",
    email: "E-Mail",
    email_receipt: "E-Mail-Quittung",
    email_receipt_sent_to: "E-Mail-Quittung gesendet an",
    unpaid: "Unbezahlt",
    admin: "Admin",
    cust: "Kunde",
    other_payment: "Andere Zahlungen",
    access_denied: "Sie haben leider keine Berechtigung, diese Bestellung einzusehen",
    please_pay_now: "Die Bestellung ist noch nicht bezahlt, Sie können jetzt bezahlen.",
    are_you_sure_cancel_order: "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
    please_pay_by_x:
        "Ihre Bestellung wird für {x} zurückgehalten. Bezahlen Sie jetzt, um die Stornierung zu verhindern",
    reward: "Belohnung",
    reward_points: "Prämienpunkte",
    reward_points_extra_text: "Der Punktestand wird nach Abschluss der Bestellung auf Ihrem Konto aktualisiert.",
    shipment_tracking_info: "Informationen zur Sendungsverfolgung",
    carrier: "Anbieter",
    tracking_number: "Sendungsverfolgungsnummer",
    register: "Anmelden",
    vc_login: "Per SMS anmelden",
    resend: "Erneut senden",
    pwd_login: "Kontoanmeldung",
    email_phone_placeholder: "Telefonnummer oder E-Mail",
    password: "Kennwort",
    vc: "Verifizierungscode",
    get_vc: "Anfrage-Code",
    titleS: "Goopter",
    alert_login_succeed: "Anmeldung erfolgreich",
    alert_phone_not_found: "Diese Rufnummer wurde noch nicht registriert!",
    alert_too_many_request:
        "Sie haben das Limit für die Abfrage des Verifizierungscodes erreicht. Bitte versuchen Sie es in 15 Minuten erneut.",
    alert_phone_length: "Ungültige Rufnummer!",
    alert_phone_vc_not_match: "Falscher Verifizierungscode, bitte versuchen Sie es erneut.",
    alert_login_failed_general: "Anmeldung fehlgeschlagen, bitte versuchen Sie es später noch einmal.",
    alert_login_failed: "Ungültiger Benutzername/Passwort.",
    forget_pwd: "Passwort vergessen",
    state_reg:
        "Falls Sie sich noch nicht auf unserer Website registriert haben, wird unser System nach der Anmeldung per SMS ein Konto für Sie erstellen",
    cos_privacy_statement: "Mit dem Einloggen erklären Sie sich mit Goopters {cos} und {privacy} einverstanden",
    cos_privacy_statement_register:
        "Mit der Anmeldung erklären Sie sich mit Goopters {cos} und {privacy} einverstanden",
    cos: "Nutzungsbedingungen",
    privacy: "Datenschutzhinweis",
    or_login_with_social: "oder mit einem sozialen Konto anmelden",
    social_redirect_text:
        "Um sich mit Ihrem sozialen Konto anzumelden, werden Sie zur Host-Website des Shops weitergeleitet, möchten Sie fortfahren?",
    social_redirect_text_cart:
        "Um sich mit Ihrem sozialen Konto anzumelden, werden Sie auf die Host-Website des Shops weitergeleitet und Ihr Warenkorb wird geleert, möchten Sie fortfahren?",
    social_login_failed:
        "Beim Einloggen mit Ihrem {socialType}-Konto ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    check_out_as_guest: "Warenkorb als Gast-Benutzer auschecken",
    register1: "Registrieren",
    email_placeholder: "Mit E-Mail",
    c_tn: "Mit Telefon-Nr.",
    nickname: "Spitzname/Anzeigename",
    alert_invalid_request: "Ungültige Anfrage",
    alert_phone_exists: "Leider existiert diese Telefonnummer bereits in unserem System",
    alert_bind_phone_conflict_title: "Rufnummer bereits registriert",
    alert_bind_phone_phone_exists:
        "Diese Nummer wurde mit einem anderen Konto registriert, bitte loggen Sie sich aus und melden Sie sich mit der Nummer an, wenn Sie diese Nummer für eine Bestellung verwenden möchten; alternativ können Sie eine andere Telefonnummer für Ihr aktuelles Konto verwenden.",
    alert_bind_phone_phone_exists_guests:
        "This number has been registered with another account, please sign in with the number if you want to use this number to place an order; alternatively you may use another phone number for your current account",
    alert_bind_phone_conflict:
        "Die Nummer, die Sie zu verwenden versuchen, ist auf einem anderen Konto registriert, wenn Sie diese Nummer für die Bestellung verwenden möchten, tippen Sie auf die Schaltfläche Weiter, um Ihr aktuelles Sozialkonto mit dieser Nummer zu verbinden, wenn Sie eine andere Nummer verwenden möchten, klicken Sie auf Abbrechen und versuchen Sie es mit einer anderen Nummer.",
    alert_vc_input_time_expired:
        "Wenn Sie innerhalb von 1 Minute keinen SMS-Verifizierungscode erhalten haben, kann dies bedeuten, dass Ihre Nummer falsch ist oder dass Ihre Nummer nicht in der Lage ist, SMS-Nachrichten ordnungsgemäß zu empfangen.{br}{br}Bitte vergewissern Sie sich, dass Sie die richtige Nummer verwenden, oder versuchen Sie es stattdessen mit einer anderen Nummer erneut.",
    alert_reg_success_text: "Sie können jetzt Ihre Telefonnummer für die Anmeldung verwenden.",
    alert_reg_success_email: "Sie können sich jetzt mit Ihrer E-Mail anmelden.",
    alert_reg_failed: "Die Registrierung ist fehlgeschlagen.",
    alert_email_length: "Ungültige E-Mail",
    open: "Offen",
    store_popup_sold: "Verkauft",
    distance: "Entfernung",
    prep_time: "Vorbereitungszeit",
    min_delivery_amnt: "Mindestliefermenge",
    max_delivery_dist: "Maximale Lieferentfernung",
    public_notice: "Öffentliche Bekanntmachung",
    discounts: "Rabatt(e)",
    discount_message: "{discount} auf {price}",
    discount_message_ohne_preis: "{discount} aus",
    pay_later_cash_description: "Mit Bargeld persönlich bezahlen",
    pay_later_card_description: "Persönlich mit Kreditkarte bezahlen",
    continue: "Weiter",
    customer_info: "Kundeninfo",
    please_leave_your_comment: "Bitte hinterlassen Sie Ihren Kommentar",
    order_now: "Jetzt bestellen",
    order_success: "Bestellung wurde abgeschickt",
    view_order: "Bestellung ansehen",
    secure_connection: "Informationen werden über eine sichere Verbindung gesendet",
    confirm_order: "Bestellung bestätigen",
    back_to_store: "Zurück zum Shop",
    subtotal: "Zwischensumme",
    delivery_fee: "Liefergebühr",
    total_before_tax: "Gesamtbetrag vor Steuern",
    tax: "Steuer",
    tips: "Trinkgelder",
    tips_with_helper_text: "Trinkgeld (tippen Sie auf den zu aktualisierenden Betrag)",
    quantity: "Menge",
    gift_card: "Geschenkkarten",
    total_paid: "Bezahlter Gesamtbetrag",
    total_due: "Fälliger Betrag",
    refund: "Erstattung",
    payment_method: "Zahlungsmethode",
    estimated_shipping_fee: "Schätzung der Versandgebühr",
    shipping_option: "Versandart",
    pick_shipping_option: "Wählen Sie eine Versandart",
    shipping_option_title: "Versand: ",
    pay_later: "Später mit Karte bezahlen",
    cash: "Später mit Bargeld bezahlen",
    credit_card: "Kreditkarte",
    pay_by_credit_card: "Mit Kreditkarte bezahlen",
    giftcard: "Geschenkkarte",
    points: "Punkte",
    points_and_giftcard: "Punkte und Geschenkkarte",
    wechat_pay: "WeChat Pay",
    address: "Adresse",
    address_customer_info: "Adresse Kundeninfo",
    c2c_regular: "Regulärer Versand",
    c2c_express: "Expressversand",
    S01: "Kostenloser Versand",
    S02: "Pauschalpreis",
    S03: "Tabellenpreis",
    credit_card_added: "Kreditkarte hinzugefügt",
    map_search_placeholder: "Straßenadresse eingeben",
    exceed_delivery_distance_select:
        "Die ausgewählte Lieferadresse überschreitet die maximale Lieferentfernung des Shops von {delivery_distance}{distance_unit}. Bitte wählen Sie eine andere Lieferadresse.",
    exceed_delivery_distance_input:
        "Die angegebene Lieferadresse überschreitet die maximale Lieferentfernung des Shops von {delivery_distance}{distance_unit}. Bitte geben Sie eine andere Lieferadresse ein.",
    address_not_in_delivery_zone:
        "Die ausgewählte Lieferadresse befindet sich nicht im Bereich der Lieferzonen des Shops. Bitte wählen Sie eine andere Lieferadresse.",
    item_oos_all:
        "Alle Artikel im Warenkorb sind nicht auf Lager, bitte fügen Sie weitere Artikel hinzu, um fortzufahren.",
    error_occured:
        "Die Authentifizierung Ihres Kontos ist leider fehlgeschlagen. Bitte loggen Sie sich erneut ein und versuchen Sie es erneut.",
    transaction_declined: "Transaktion abgelehnt. Ungültige Kreditkarte, bitte wählen Sie eine andere Zahlungsmethode.",
    invalid_first_name: "Ungültiger Vorname",
    payment_method_missing: "Zahlungsmethode fehlt",
    billing_address_missing: "Rechnungsadresse fehlt",
    giftcard_error: "Geschenkkartenfehler, bitte aktualisieren Sie die Seite und versuchen Sie es erneut",
    customer_info_missing: "Kundeninfo fehlt",
    table_number_missing: "Bitte geben Sie Ihre aktuelle Tischnummer ein",
    pickup_time_missing: "Abholzeit fehlt",
    delivery_time_missing: "Zustellzeit fehlt",
    delivery_address_missing: "Lieferadresse fehlt",
    shipping_method_missing: "Versandart fehlt",
    set: "Eingestellt",
    item_error:
        "Es ist ein Fehler aufgetreten. Ihr Einkaufswagen wurde geleert. Bitte fügen Sie einige Artikel hinzu und versuchen Sie es erneut.",
    no_items_error:
        "Es sind keine Artikel in Ihrem Warenkorb. Bitte fügen Sie einige Artikel hinzu und versuchen Sie es erneut.",
    use_profile_info: "Info über Profil verwenden",
    pick_up: "Abholung",
    delivery_option: "Lieferoption",
    required: "Erforderlich",
    first_name_placeholder: "Geben Sie Ihren Vornamen ein",
    last_name_placeholder: "Geben Sie Ihren Nachnamen ein",
    table_number_placeholder: "Geben Sie Ihre Tischnummer ein",
    unit_placeholder: "Geben Sie die Nummer Ihrer Einheit ein",
    buzz_placeholder: "Geben Sie Ihre Rufnummer ein",
    street_placeholder: "Geben Sie Ihre Adresse ein",
    city_placeholder: "Geben Sie Ihre Stadt ein",
    region_placeholder: "Geben Sie Ihre Provinz oder Ihr Bundesland ein",
    cntry_placeholder: "Geben Sie Ihr Land ein",
    zipcd_placeholder: "Geben Sie Ihre Postleitzahl ein",
    table_number_required: "Tischnummer ist erforderlich",
    are_you_sure_remove_gift_card: "Sind Sie sicher, dass Sie diese Geschenkkarte von Ihrer Zahlung entfernen möchten?",
    no_shipping_options: "Keine Versandart für die ausgewählte Adresse. Bitte wählen Sie eine andere Lieferadresse.",
    giftcard_pays_order_total:
        "Die ausgewählte Geschenkkarte bezahlt bereits den Gesamtbetrag der Bestellung. Es sind keine weiteren Zahlungsmethoden erforderlich.",
    delivery_time_changed: "Die Lieferzeit wurde geändert, bitte wählen Sie eine andere passende Zeit.",
    gift_card_payment: "Zahlung mit Geschenkkarte",
    expires: "Läuft ab",
    allowed_periods_unavailable:
        "Bitte wählen Sie eine passende Lieferadresse, um die verfügbaren Lieferzeiten zu erhalten.",
    delivery_method_unavailable:
        "Die Lieferung ist nicht mehr verfügbar, bitte gehen Sie zurück zum Shop und kassieren Sie erneut.",
    takeout_method_unavailable:
        "Abholung ist nicht mehr möglich, bitte gehen Sie zurück in die Filiale und kassieren Sie erneut.",
    in_store_method_unavailable:
        "Die Bestellung im Laden ist nicht mehr möglich, bitte gehen Sie zurück in den Laden und bezahlen Sie erneut.",
    agreement_notice:
        "Mit der Bestätigung des Kaufs stimme ich den {conditions_of_use} und der {privacy_policy} von Goopter zu",
    conditions_of_use: "Nutzungsbedingungen",
    privacy_policy: "Datenschutzrichtlinie",
    "sichere Verbindung": "Informationen werden über eine sichere Verbindung gesendet",
    set_payment_method: "Zahlungsmethode festlegen",
    set_billing_address: "Rechnungsadresse festlegen",
    set_delivery_address: "Lieferadresse festlegen",
    set_giftcard: "Geschenkkarte auswählen",
    set_points: "Tippen Sie, um Punkte einzulösen",
    redeem_points_dialog_title: "Punkte einlösen",
    redeem_x: "Einlösen: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "Kundeninfo eingeben",
    set_table_number: "Eingabe der Tischnummer",
    set_pickup_time: "Wählen Sie eine Abholzeit",
    set_delivery_time: "Wählen Sie eine Lieferzeit",
    set_shipping_method: "Versandart festlegen",
    adress_buch: "Adressbuch",
    "no-giftcard-text": "Sie haben keine Geschenkkarten für dieses Geschäft",
    delete_credit_card_failed:
        "Ihre Karte konnte leider nicht gelöscht werden, bitte versuchen Sie es später noch einmal.",
    checkout_success:
        "Vielen Dank für Ihren Einkauf, Ihre Bestellung wurde erstellt: #{order_id}. Sie werden benachrichtigt, wenn der Status der Bestellung aktualisiert wird.",
    new_order_created: "Neue Bestellung erstellt",
    distance_exceeded: "Distanz überschritten",
    min_delivery_amt_required:
        "Der Mindestlieferbetrag ist {required} vor Steuern und Liefergebühren, bitte fügen Sie {gap} mehr für die Lieferung hinzu.",
    insufficient_fund: "Unzureichendes Guthaben, bitte versuchen Sie es mit einer anderen Zahlungsmethode.",
    invalid_payment_info:
        "Ungültige Zahlungsinformationen, bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
    merchant_not_ready:
        "Der Händler ist nicht bereit, Ihre Zahlung zu akzeptieren. Bitte versuchen Sie es später noch einmal.",
    shipping_country_text: "Versand verfügbar für Adressen in den folgenden Ländern: {countries}",
    apply: "Einlösen",
    enter_coupon_code: "Gutscheincode eingeben",
    remove_coupon_alert: "Sind Sie sicher, dass Sie diesen Gutschein entfernen möchten?",
    replace_coupon_alert: "Aktueller aktiver Gutschein wird ersetzt, möchten Sie fortfahren?",
    invalid_alipay_coupon: "Dieser Gutscheincode ist nur für die Zahlungsmethode AliPay verfügbar",
    remove_current_alipay_coupon:
        "Der aktuelle Warenkorb enthält einen Gutschein, der nur für die AliPay-Zahlungsmethode verfügbar ist. Wenn Sie zu einer anderen Zahlungsmethode wechseln, geht der Rabatt verloren, möchten Sie die Zahlungsmethode trotzdem ändern?",
    use_wechat_dialog_message:
        "Um WeChatPay zu verwenden, öffnen Sie bitte den Shop auf Wechat, um mit der Bestellung fortzufahren. {br}{br}Optionen zum Öffnen des Shops auf Wechat:{br}1. Wenn Sie den QR-Code des Geschäfts haben, scannen Sie ihn mit Wechat;{br}2. Tippen Sie auf den Namen des Ladens oben auf der Seite, um zur Seite des Ladens zurückzukehren, und tippen Sie dann auf das Symbol in der rechten oberen Ecke, um das QR-Code-Pop-up-Fenster zu öffnen, speichern Sie den QR-Code, und öffnen Sie dann den QR-Code in Wechat, um mit der Bestellung fortzufahren.",
    discount_total: "Rabatt Gesamt",
    first_name_missing: "Bitte geben Sie Ihren Vornamen ein",
    phone_number_missing: "Bitte geben Sie Ihre Telefonnummer ein",
    payment: "Zahlung",
    select_payment: "Wählen Sie eine Zahlungsart",
    select_giftcards: "Wählen Sie eine Geschenkkarte",
    special_request: "Sonderwunsch",
    place_order_with_x: "Bestellung aufgeben - {x}",
    min_type_required_message:
        "Der Mindestbetrag für {type} ist {value}, bitte fügen Sie mehr Artikel hinzu, um {type} zu aktivieren.",
    select_pickup_location: "Wählen Sie den Abholort",
    please_choose_pickup_location: "Bitte wählen Sie den Abholort",
    invalid_delivery_zone_title: "Außerhalb der Liefergrenze",
    invalid_delivery_zone_content:
        "Die angegebene Adresse liegt außerhalb der Zustellgrenze. Bitte geben Sie eine andere Adresse ein. Lieferzonen und -termine wie folgt:",
    please_input_address: "Bitte geben Sie eine gültige Adresse ein",
    please_input_pickup_time: "Bitte wählen Sie die Abholzeit",
    please_input_delivery_time: "Bitte wählen Sie die Lieferzeit",
    recommend_a_good_restaurant: "Ich habe Ihnen ein gutes Geschäft empfohlen",
    recommend_a_good_merchat: "Ich empfehle Ihnen einen guten Händler",
    recommend_a_good_product: "Ich empfehle Ihnen ein gutes Produkt",
    guest_user: "Gast-Benutzer",
    require_utensil: "Fordern Sie Utensilien an, etc.",
    already_have_account: "Als Gast auschecken",
    order_success_table:
        "Vielen Dank für Ihre Bestellung. Wir bringen Ihre Bestellung an Ihren Tisch, sobald sie fertig ist.",
    order_success_parking_lot:
        "Vielen Dank für Ihre Bestellung. Wir bringen Ihre Bestellung zu Ihrem Auto, sobald sie fertig ist.",
    order_success_kiosk: "Vielen Dank für Ihre Bestellung. Wir werden Sie benachrichtigen, sobald sie fertig ist.",
    view_special_deals: "View special deals",
    more_items_to_get_special_deals: "Current order amount does not qualify for special deals. Please add more items.",
    special_deals_for_you: "Special deals for you",
    add_items: "Produkte hinzufügen",
    discounted_upsell_items_removed_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers.",
    discounted_upsell_items_removed_empty_cart_msg:
        "The discounted upsell items have been removed since the current order amount no longer qualifies for additional offers. Your cart is now empty. Please add items to continue.",
    menu: "Menu",
    category: "Kategorie",
    categories: "Kategorien",
    store_details: "Laden-Details",
    store_description: "Ladenbeschreibung",
    rank: "Rang",
    customer_reviews: "Kundenrezensionen",
    single_selection: "Einzelne Auswahl",
    optional: "Optional",
    pick_varied_options: "Wähle {count} Artikel",
    sold: "{count} verkaufte",
    only_x_left: "{count} links",
    sold_out: "Ausverkauft",
    pick_option: "Auswahloption",
    contact_vendor: "Verkäufer kontaktieren",
    call_vendor: "Anbieter anrufen",
    self_input: "Selbst eingeben",
    with_option: "Mit Option(en)",
    coupons: "{couponCount} Coupon(s)",
    coupons_title: "Gutscheine",
    redeemed: "Angewandt",
    redeem: "Anwenden",
    remove: "Entfernen",
    valid_until: "Gültig bis {endDate}",
    checkout: "Auschecken",
    shopping_cart: "Einkaufswagen",
    empty_cart: "Löschen",
    alert_empty_cart: "Sind Sie sicher, dass Sie Ihren Einkaufswagen leeren wollen?",
    discount_title: "Rabatt",
    qrcode: "QRcode",
    m: "m",
    km: "km",
    min: "min",
    hour: "h",
    rating: "Bewertung",
    rating_title: "Bewertung",
    base_delivery_fee: "Liefergebühr innerhalb {flatDistance} {distanceUnit}",
    min_delivery_amount: "Minimaler Lieferbetrag",
    max_delivery_distance: "Maximale Lieferentfernung",
    distance_title: "Entfernung",
    takes_reservations: "Nimmt Reservierungen an",
    more_info: "Mehr Infos",
    announcement: "Öffentliche Bekanntmachung",
    no_announcement: "Keine öffentliche Bekanntmachung",
    buy_x_discount_flat: "Kaufen Sie {currency}{price}, {currency}{discount} ab",
    buy_x_discount_percent: "Kaufen Sie {currency}{price}, {discount}% Rabatt",
    search_products_title: "Produkte suchen",
    search_products_input_placeholder: "Stichwort für die Suche eingeben...",
    search_products_no_match: "Ihre Suche '{text}' hat keine Produkte gefunden.",
    alert_delete_product_with_options: "Produkte mit Optionen können nur aus dem Einkaufswagen entfernt werden.",
    store_closed_allowed_preorder: "Der Shop ist derzeit geschlossen, Sie können vorbestellen.",
    store_closed_allowed_preorder_x_mins_before:
        "Der Laden ist derzeit geschlossen. Sie können {mins} Minuten vor Öffnung des Ladens vorbestellen.",
    store_closed_soon_not_allowed_order:
        "Der Laden wird bald geschlossen und nimmt derzeit keine neuen Bestellungen an.",
    results_colon: "Ergebnisse: ",
    price: "Preis",
    popularity: "Beliebtheit",
    service_title: "Dienstleistung",
    product_title: "Produkt",
    all_title: "Alle",
    satisfied_title: "Zufrieden",
    unsatisfied_title: "Unzufrieden",
    discount_rate: "{rate}% off",
    invalid_option_selection: "Ungültige Optionsauswahl",
    no_product_options: "Keine Produktoptionen",
    single_selection_error: "Bitte wählen Sie eine Option aus '{productOption}'",
    pick_ranged_options_error: "Die minimale Optionsauswahl für '{productOption}' ist {count} items",
    pick_fixed_options_error: "Bitte wählen Sie {count} Optionsauswahl aus '{productOption}'",
    update_cart: "Warenkorb aktualisieren",
    total_reviews: "Bewertungen",
    store_total_sold_count: "Verkauft",
    hot_products: "Heiße Produkte",
    related_products: "Ähnliche Produkte",
    store_hours: "Ladenöffnungszeiten",
    delivery_hours: "Lieferzeiten",
    add_for_delivery: "Für die Lieferung {currency}{amount} hinzufügen",
    open_in_app: "In App öffnen",
    qrcode_message: "Scannen Sie diesen QR-Code, um den Shop zu öffnen",
    review_submitted_validate:
        "Vielen Dank für das Einreichen Ihrer Bewertung, sie wird veröffentlicht, sobald sie validiert ist.",
    review_submitted: "Ihre Bewertung wurde eingereicht.",
    add_update_review: "Bitte fügen Sie Ihre Bewertung hinzu oder aktualisieren Sie sie",
    post_review_failed:
        "Die Bewertung wurde nicht übermittelt. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
    not_accepting_orders: "Wir nehmen zur Zeit keine Online-Bestellungen an",
    bookmark_sign_in_alert_store: "Melden Sie sich an, um diesen Shop zu bookmarken!",
    bookmark_sign_in_alert_product: "Melden Sie sich an, um dieses Produkt zu markieren!",
    no_store_bookmarks: "Keine Lesezeichen für den Laden",
    no_store_bookmarks_message:
        "Sie haben kein Lesezeichen für ein Geschäft. Drücken Sie auf das Herz-Symbol oben rechts auf dem Startbildschirm eines Geschäfts, um ein Lesezeichen für ein Geschäft zu setzen.",
    no_store_bookmarks_button: "Laden-Lesezeichen hinzufügen",
    no_product_bookmarks: "Keine Produkt-Lesezeichen",
    no_product_bookmarks_message:
        "Sie haben keine Lesezeichen für Produkte. Drücken Sie auf das Herzsymbol, das mit einem Produkt verbunden ist, um ein Lesezeichen für ein Produkt zu setzen.",
    no_product_bookmarks_button: "Ein Produkt-Lesezeichen hinzufügen",
    no_group_bookmarks: "Keine Deal-Lesezeichen",
    no_group_bookmarks_message:
        "Sie haben keine Lesezeichen für Deals. Drücken Sie auf das Herzsymbol, das mit einem Geschäft verbunden ist, um ein Lesezeichen zu setzen.",
    no_group_bookmarks_button: "Ein Deal-Lesezeichen hinzufügen",
    add_item_another_menu_warning:
        "Ihr Warenkorb enthält bereits Artikel aus einem anderen Menü ({menu}), möchten Sie den Warenkorb leeren und stattdessen diesen Artikel hinzufügen?",
    start_new_cart_question: "Neuen Warenkorb anlegen?",
    change_menu: "Menü ändern",
    direction: "Wegbeschreibung",
    website: "Website",
    we_also_recommend: "Wir empfehlen auch...",
    diff_delivery_price: "Fügen Sie {x} für die Lieferung hinzu",
    discount_info_format: "Kaufen Sie {x}",
    discount_add_x_get_y: "Fügen Sie {x} mehr hinzu, um {y} Rabatt zu erhalten",
    discount_get_percentage_discount: "Erhalten Sie {x} Rabatt",
    discount_get_value_discount: "Sie erhalten {x} Rabatt",
    discount_not_accepting_order: "Leider nehmen wir im Moment keine Online-Bestellungen an",
    discount_store_closed_preorder: "Der Laden ist geschlossen, Sie können jetzt vorbestellen",
    discount_x_off_add_y_get_z: "{x} Rabatt, fügen Sie {y} mehr hinzu, um {z} Rabatt zu erhalten",
    discount_x_off: "{x} Rabatt",
    switch_menu_warning_message:
        "Das ausgewählte Menü unterstützt eine andere Bestellart ({new_order_type}). Wenn Sie fortfahren, werden die Artikel im Einkaufswagen geleert. Möchten Sie zu dem neuen Menü wechseln?",
    logout_confirm_message: "Sind Sie sicher, dass Sie sich abmelden wollen?",
    logout_force_message:
        "Entschuldigung, Ihr Konto wurde von einem anderen Gerät aus angemeldet oder die Zeit wurde überschritten. Bitte melden Sie sich erneut an.",
    cannot_merge_account: "Ihr Konto existiert bereits, bitte verwenden Sie eine andere Telefonnummer.",
    duplicate_social_error:
        "Die Telefonnummer wurde in einem anderen Konto registriert, bitte versuchen Sie eine andere Telefonnummer. Um diese Nummer zu verwenden, loggen Sie sich bitte aus und melden Sie sich mit dieser Nummer an.",
    store_has_no_products: "Dieser Shop hat derzeit keine Produkte.",
    error_with_code: "[Fehler {code}]: {error}",
    unable_to_load_any_data: "Es können keine Daten geladen werden",
    unstable_network: "Langsames oder instabiles Netzwerk, bitte überprüfen Sie Ihre Netzwerkverbindung",
    logout_force_message1:
        "Leider wurde Ihr Konto von einem anderen Gerät aus angemeldet oder es wurde eine Zeitüberschreitung festgestellt. Bitte melden Sie sich erneut an.",
    incorrect_password: "Falsches Passwort",
    country_code_error: "Ländercode-Fehler",
    incorrect_username_password: "Falscher Benutzername/Passwort",
    verification_code_expired: "Verifizierungscode abgelaufen",
    verifcation_code_request_overlimit: "Verifizierungscode-Anforderung übersteigt das Limit",
    incorrect_verification_code: "Falscher Verifizierungscode",
    error_fetch_user_profile: "Benutzerprofil konnte nicht abgerufen werden",
    login_failed: "Anmeldung fehlgeschlagen",
    profile_update_success: "Profil aktualisieren erfolgreich",
    email_update_success: "E-Mail aktualisieren erfolgreich",
    phone_update_success: "Telefonnummer erfolgreich aktualisieren",
    failed_fetch_gift_card_detail: "Geschenkkartendetails konnten nicht abgerufen werden",
    gift_card_transfer_success: "Übertragene Geschenkkarte erfolgreich",
    gift_card_transfer_fail: "Geschenkkarte konnte nicht übertragen werden",
    record_not_found: "Datensatz nicht gefunden",
    failed_fetch_points_detail: "Die Punktedetails konnten nicht abgerufen werden",
    profile_update_failed: "Benutzerprofil konnte nicht aktualisiert werden",
    email_update_failed: "E-Mail konnte nicht aktualisiert werden",
    phone_update_failed: "Aktualisierung der Telefonnummer fehlgeschlagen",
    get_verification_code_success: "Der Verifizierungscode wurde an Ihr Telefon gesendet.",
    verification_too_many_requests:
        "Der Verifizierungscode wurde zu oft angefordert, bitte versuchen Sie es später erneut",
    invalid_table_number: "Bitte geben Sie eine Zahl ein",
    invalid_party_size: "Bitte geben Sie eine Zahl ein",
    email_required: "E-Mail ist erforderlich",
    password_required: "Passwort ist erforderlich",
    phone_required: "Telefon ist erforderlich",
    vc_required: "Verifizierungscode ist erforderlich",
    coupon_invalid: "Ungültiger Coupon",
    coupon_valid: "Gültiger Coupon",
    invalid_coupon: "Gutscheincode {couponCode} ist ungültig",
    coupon_applied: "Der Gutschein wurde auf den Warenkorb angewendet.",
    invalid_address: "Ungültige Adresse",
    remaining_stock_header: "Verbleibender Bestand",
    item_oos: "Die folgenden Artikel sind leider nicht mehr in ausreichender Menge auf Lager:",
    invalid_shipping_method: "Die Versandmethode ist nicht gültig. Bitte versuchen Sie eine andere Versandart",
    please_input_phone_number: "Bitte geben Sie eine gültige Telefonnummer ein",
    please_input_first_name: "Bitte geben Sie Ihren Vornamen ein",
    please_input_last_name: "Bitte geben Sie Ihren Nachnamen ein",
    please_input_at_least_two_characters: "Bitte geben Sie mindestens zwei Zeichen ein",
    please_input_shipping_method: "Bitte geben Sie die Versandart ein",
    please_input_sub_shipping_method: "Bitte Versandart eingeben",
    please_input_shipping_addresss: "Tippen Sie auf , um die Versandadresse einzugeben",
    please_input_pickup_addresss: "Tippen Sie, um den Abholort auszuwählen",
    please_input_table_number: "Bitte geben Sie die Tischnummer ein",
    please_input_payment_method: "Tippen Sie, um die Zahlungsmethode auszuwählen",
    please_input_valid_credit_card: "Bitte geben Sie eine gültige Kreditkarte ein",
    please_input_billing_address: "Bitte Rechnungsadresse eingeben",
    tap_to_show_on_map: "Tippen Sie hier, um es auf der Karte anzuzeigen",
    please_add_some_items: "Bitte fügen Sie einige Artikel hinzu",
    please_confirm_tips: "Tippen Sie hier, um den Trinkgeldbetrag festzulegen",
    please_set_minimum_tips: "Please Input Minimum Tip Amount",
    please_set_minimum_tips_detail: "The minimum tip amount for delivery is $\u200B{tips_amount} in order to secure a driver for your order.",
    bind_phone: "Telefonnummer bestätigen",
    please_bind_phone:
        "Eine verifizierte Telefonnummer ist erforderlich, um eine Bestellung aufzugeben. Möchten Sie Ihre Rufnummer jetzt verifizieren?",
    go_to_bind: "Gehe zu binden",
    bind_phone_success: "Telefon binden erfolgreich",
    invalid_credit_card: "Ungültige Kreditkarte. Bitte wählen Sie eine andere Zahlungsmethode",
    unsuccessful_checkout: "Fehlgeschlagener Checkout. Bitte versuchen Sie es erneut.",
    invalid_request: "Nicht erfolgreicher Checkout. Ungültige Anfrage.",
    price_over_limit: "Checkout fehlgeschlagen. Der Preis liegt über dem Zahlungslimit.",
    invalid_shp_mtd:
        "Fehlgeschlagener Checkout. Die Versandart ist nicht gültig. Bitte versuchen Sie eine andere Versandart",
    invalid_pay_mtd:
        "Checkout fehlgeschlagen. Die Zahlungsart ist nicht gültig. Versuchen Sie bitte eine andere Zahlungsmethode.",
    checkout_success1:
        "Vielen Dank für Ihr Interesse, Ihre Bestellung wurde erstellt: #{order_id}. Sie werden benachrichtigt, wenn der Status der Bestellung aktualisiert wird.",
    please_input_belows: "Bitte geben Sie die unten fehlenden Felder ein",
    unable_to_change_here: "Hier kann nicht geändert werden",
    please_select_on_previous_page: "Bitte wählen Sie auf der vorherigen Seite",
    invalid_card_number: "Ungültige Kartennummer",
    invalid_zipcd: "Ungültige Postleitzahl",
    click_one_more_time_to_deselect: "Klicken Sie auf mehr Zeit, um die Auswahl aufzuheben",
    paypal_payment_success_title: "Zahlung erfolgreich",
    paypal_payment_success_text: "Ihre Zahlung ist eingegangen. Sie werden zur Bestellseite weitergeleitet.",
    error_payment_failed: "Zahlung fehlgeschlagen",
    internal_server_error:
        "Sorry, es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal oder wenden Sie sich direkt an den Verkäufer.",
    unexpected_error: "Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später noch einmal",
    points_pays_order_total:
        "Der Gesamtbetrag der Bestellung ist bereits mit Ihren Punkten bezahlt. Es sind keine weiteren Zahlungsmethoden erforderlich.",
    giftcard_points_pays_order_total:
        "Ihre Geschenkkarte und Ihre Punkte haben bereits den Gesamtbetrag der Bestellung bezahlt. Es sind keine weiteren Zahlungsmethoden erforderlich.",
    digit_only: "Bitte nur Ziffern eingeben",
    please_selected_belows: "Bitte füllen Sie alle Auswahlfelder aus:",
    please_select_location_from_the_list: "Bitte wählen Sie eine Adresse aus der Liste aus.",
    alert_password_reset_success:
        "Ihr Passwort wurde erfolgreich aktualisiert, Sie können sich jetzt mit dem neuen Passwort anmelden.",
    alert_email_not_found: "Diese E-Mail wurde noch nicht registriert!",
    alert_password_reset_email_send: "E-Mail zum Zurücksetzen des Passworts wurde gesendet",
    x_is_required: " ist ein Pflichtfeld",
    location_permission_is_blocked_service_may_not_performed_correctly:
        "Die Standortfreigabe ist gesperrt, dieser Dienst kann beeinträchtigt sein.",
    x_missing: "Bitte geben Sie {x} ",
    x_invalid: "Ungültig {x}",
    x_select: "Bitte wählen Sie {x}",
    do_you_want_to_receive_notification: "Möchten Sie Benachrichtigungen erhalten?",
    gift_cards_points_alert:
        "Sie dürfen die Geschenkkarte nicht verwenden, wenn Sie Punkte einlösen. Um eine Geschenkkarte zu verwenden, entfernen Sie bitte zuerst die Prämienpunkte.",
    points_gift_cards_alert_message:
        "Sie dürfen die Prämienpunkte nicht verwenden, wenn Sie eine Geschenkkarte verwenden. Um Prämienpunkte zu verwenden, entfernen Sie bitte zuerst die Geschenkkarte.",
    not_found_description: "Oops! Seite wurde nicht gefunden",
    not_found_detail_description:
        "Die Seite, die Sie suchen, existiert leider nicht, wurde entfernt/umbenannt oder ist vorübergehend nicht verfügbar",
    back_to_home: "Zurück zur Homepage",
    braintree_credit_card_invalid_message:
        "Etwas ist schief gelaufen. Überprüfen Sie Ihre Kartendaten und versuchen Sie es erneut.",
    please_input_valid_postal_code: "Bitte geben Sie eine gültige Postleitzahl ein.",
    please_input_valid_x: "Bitte geben Sie einen gültigen {x} ein",
    too_many_card_attempt:
        "Die angegebene Karte wurde zu oft abgelehnt; bitte versuchen Sie eine andere Karte für die Zahlung.",
    too_many_checkout_attempt:
        "Maximales Limit für Wiederholungsversuche überschritten. Bitte versuchen Sie es später erneut.",
    payment_declined: "Zahlung abgelehnt",
    credit_card_declined:
        "Bitte überprüfen Sie die Kreditkarteninformationen, um sicherzustellen, dass sie korrekt eingegeben wurden, und versuchen Sie es erneut. Wenn das Problem dadurch nicht behoben werden kann, wenden Sie sich bitte an Ihre kartenausgebende Bank, um das Problem zu lösen",
    ppcp_payment_declined: "Zahlung abgelehnt. Bitte versuchen Sie es erneut oder verwenden Sie eine andere Karte zur Zahlung.",
    no_giftcard: "Sie haben keine verfügbare Geschenkkarte",
    giftcard_insuffienct_fund: "Ihr Guthaben auf der Geschenkkarte reicht für die Zahlung nicht aus.",
    invalid_request_body: "Ungültige Anfrage",
    pay_order: "Zahlungsauftrag",
    expiry_date: "Verfallsdatum",
    postal_code: "Postleitzahl",
    postal_code_zipcd: "Postleitzahl/Platzcode",
    country: "Land",
    last_four: "Letzte Vier",
    are_you_sure_delete_card: "Sind Sie sicher, dass Sie diese Karte löschen wollen",
    visa: "Visa",
    master_card: "Master-Karte",
    american_express: "American Express",
    discover: "Entdecken",
    jcb: "JCB",
    maestro: "Maestro",
    kredit_karte_beschreibung: "Visa, Master Card, American Express",
    confirm_pay: "Zahlung bestätigen",
    unsuccessful_payment: "Fehlgeschlagene Zahlung. Bitte versuchen Sie es erneut.",
    reset_password_title: "Passwort zurücksetzen",
    new_password: "Neues Passwort",
    password_reset_continue: "Absenden",
    reset_by_text: "Zurücksetzen durch Text",
    reset_by_email: "Zurücksetzen per E-Mail",
    alert_vc_expired:
        "Der von Ihnen angegebene Verifizierungscode ist leider abgelaufen. Bitte fordern Sie einen neuen Verifizierungscode an und versuchen Sie es erneut.",
    email_verification: "E-Mail-Bestätigung",
    verification_success: "E-Mail wurde verifiziert",
    verify_account: "Danke für die Verifizierung Ihres Kontos! {email}",
    verification_fail: "E-Mail-Überprüfung fehlgeschlagen",
    verify_error:
        "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut, indem Sie eine Verifizierungs-E-Mail senden. {email}",
    view_account: "Konto anzeigen",
    link_expired: "Die E-Mail-Adresse konnte nicht verifiziert werden",
    link_expired_message:
        "Der Verifizierungslink ist abgelaufen oder wurde bereits verwendet. Bitte versuchen Sie es erneut. {email}",
    email_already_verified: "Ihr Konto wurde bereits verifiziert.",
    resend_verification_email: "Verifizierungs-E-Mail erneut senden",
    recently_viewed: "Kürzlich angesehen",
    by_phone: "Per Telefon",
    by_email: "Per E-Mail",
    by_password: "Nach vorhandenem Passwort",
    op_incorrect: "Das ursprünglich übermittelte Kennwort ist falsch",
    password_change_success: "Passwort erfolgreich geändert.",
    password_request_sent: "Anfrage zum Ändern des Passworts gesendet",
    social_binding: "Soziale Bindung",
    linked: "Verknüpft",
    facebook: "Facebook",
    twitter: "Twitter",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Glibber",
    OK: "Одноклассники",
    or: "oder",
    connect_with_facebook: "Mit Facebook anmelden",
    connect_with_twitter: "Mit Twitter anmelden",
    connect_with_google: "Mit Google anmelden",
    connect_with_wechat: "Mit WeChat anmelden",
    connect_with_apple: "Mit Apple anmelden",
    dont_have_account: "Sie haben kein Konto?",
    vouchers: "Gutscheine",
    bookmarks: "Lesezeichen",
    bookmark_added: "Lesezeichen hinzugefügt",
    bookmark_deleted: "Lesezeichen entfernt",
    account_info: "Kontoinformationen",
    my_points: "Meine Punkte",
    my_giftcards: "Meine Geschenkkarten",
    my_bookmarks: "Meine Lesezeichen",
    my_messages: "Meine Nachrichten",
    my_orders: "Meine Bestellungen",
    avatar: "Avatar",
    name: "Name",
    my_address: "Meine Adresse",
    phone_num: "Telefon #",
    account_binding: "Kontobindung",
    connect: "Verbinden",
    safety_settings: "Sicherheitseinstellungen",
    login_password: "Anmeldekennwort",
    modify: "Ändern",
    delete: "Löschen",
    first_name: "Vorname",
    last_name: "Nachname",
    phone_number: "Telefonnummer",
    pick_place_on_map: "Auf der Karte suchen",
    done: "Erledigt",
    enter_address_manually: "Adresse manuell eingeben",
    use_name_account: "Name im Konto verwenden",
    new_address: "Neue Adresse",
    buzz_number: "Klingelnummer",
    unit: "Einheit",
    street: "Straße",
    city: "Stadt",
    region: "Region",
    cntry: "Land",
    zipcd: "Postleitzahl",
    postalcd: "Postleitzahl",
    required_text: "* zeigt an, dass ein Feld erforderlich ist",
    map_search: "Kartensuche",
    accept: "Akzeptieren",
    address_book: "Adressbuch",
    invalid_last_name: "Ungültiger Nachname",
    invalid_credit_card_number: "Ungültige Kreditkartennummer",
    invalid_credit_card_cvv: "Ungültiger CVV",
    invalid_cntry: "Ungültiges Land",
    Invalid_zipcd: "Ungültige Postleitzahl",
    street_missing: "Straße ist leer",
    city_missing: "Stadt ist leer",
    region_missing: "Region ist leer",
    cntry_missing: "Land fehlt",
    zipcd_missing: "Postleitzahl fehlt",
    billing_address: "Rechnungsadresse",
    confirm_delete_address: "Bestätigen Sie das Löschen der Adresse",
    delete_address_message: "Sind Sie sicher, dass Sie diese Adresse löschen wollen?",
    address_edit: "Adresse bearbeiten",
    default: "Standard",
    set_as_default: "Als Standard festlegen",
    nick_name: "Nickname",
    update_profile_success: "Ihr Profil wurde erfolgreich aktualisiert!",
    update_profile_failed: "Sorry, update profile failed. Bitte versuchen Sie es später erneut.",
    last_name_missing: "Nachname fehlt",
    nick_name_missing: "Spitzname fehlt",
    update: "Aktualisierung",
    user_info: "Benutzerinformationen",
    new_phone: "Neue Telefonnummer",
    new_email: "Neue E-Mail",
    vc1: "Überprüfungscode",
    reset_by_email1: "Per E-Mail zurücksetzen",
    phone_placeholder: "Telefonnummer",
    alert_phone_updated: "Ihre Rufnummer wurde erfolgreich aktualisiert!",
    alert_missing_fields: "Entschuldigung, es fehlen Eingabefelder",
    alert_forbidden_action: "Tut mir leid, diese Aktion ist verboten.",
    alert_email_updated: "Ihre E-Mail wurde erfolgreich aktualisiert!",
    alert_forbidden_email: "Tut mir leid, diese Aktion ist verboten.",
    alert_email_exists_sign_up: "Entschuldigung, diese E-Mail existiert bereits in unserem System",
    alert_email_exists_update: "Entschuldigung, diese E-Mail existiert bereits in unserem System",
    wallet: "Geldbörse",
    confirm_delete_credit_card: "Bestätigen Sie das Löschen der Kreditkarte",
    confirm_delete_credit_card_message: "Sind Sie sicher, dass Sie diese Kreditkarte löschen wollen?",
    add_credit_card_title: "Kreditkarte hinzufügen",
    add_credit_card: "Eine Kreditkarte hinzufügen",
    save_credit_card: "Kreditkarte speichern",
    card_number: "Kartennummer",
    Name_auf_der_Karte: "Name auf der Karte",
    cvv: "CVV",
    set_payment_password: "Zahlungskennwort festlegen",
    old_password: "Altes Passwort",
    confirm_new_password: "Bestätigen Sie das neue Passwort",
    update_password_success: "Ihr Passwort wurde erfolgreich aktualisiert!",
    password_not_detected:
        "Das alte Passwort stimmt nicht überein, bitte überarbeiten Sie es und versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück",
    server_error: "Entschuldigung, es ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.",
    unmatched_passwords:
        "Neues Kennwort und neues Bestätigungskennwort stimmen nicht überein, bitte überarbeiten Sie das Kennwort und versuchen Sie es erneut.",
    social_bind_not_allowed:
        "Anmeldung oder Bindung oder Aufhebung der Bindung über ein soziales Konto außer WeChat ist in WeChat nicht erlaubt, bitte externen Browser verwenden",
    social_bind_success:
        "Ihr Konto wurde erfolgreich mit Ihrem {socialType}-Konto verknüpft. Sie können sich jetzt mit Ihrem {socialType}-Konto anmelden!",
    social_bind_failed:
        "Beim Verknüpfen Ihres {socialType}-Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    connected: "Verbunden",
    confirm_unbind_social_account:
        "Sind Sie sicher, dass Sie die Verknüpfung Ihres {socialType}-Kontos aufheben möchten?",
    unbind_social_success: "Ihr {socialType}-Konto wurde freigeschaltet.",
    unbind_social_error:
        "Leider konnten wir Ihr {socialType}-Konto zu diesem Zeitpunkt nicht freischalten. Bitte versuchen Sie es später erneut.",
    create_new_vault_password: "Fügen Sie ein Kennwort hinzu, um Ihre Kreditkarte zu schützen",
    confirm_vault_password: "Bestätigen Sie Ihr Passwort",
    input_vault_password: "Geben Sie Ihr Zahlungspasswort ein",
    vault_password: "Passwort für die Zahlung",
    password_instructions: "Das Passwort muss 4 - 6 Zahlen lang sein",
    password_incorrect: "Falsches Passwort",
    add_address_invalid_address: "Ungültige Adresse. Bitte versuchen Sie, die Adresse auf der Karte zu wählen.",
    country_not_exists: "Ungültiges Land. Bitte versuchen Sie, die Adresse auf der Karte auszuwählen.",
    map_select_address: "Bitte füllen Sie die Suchleiste aus und wählen Sie eine vorgeschlagene Adresse aus.",
    error_current_location:
        "Es ist ein Fehler bei der Ermittlung Ihres aktuellen Standorts aufgetreten, bitte geben Sie Ihre Adresse in die Suchleiste ein.",
    map_address_accept_error:
        "Google Maps Fehler. Bitte versuchen Sie eine andere Adresse oder geben Sie die Adresse manuell ein.",
    invalid_name: "Bitte geben Sie den Namen des Karteninhabers ein.",
    invalid_cc_info:
        "Die Kreditkarteninformationen sind nicht gültig, bitte überarbeiten Sie sie und versuchen Sie es erneut.",
    invalid_expiry_date:
        "Das Gültigkeitsdatum der Kreditkarte ist ungültig, bitte überprüfen Sie es und versuchen Sie es erneut",
    alert_phone_length1: "Ungültige Telefonnummer!",
    alert_password_min_length: "Das Passwort muss mindestens 6 Zeichen lang sein",
    alert_guest_account:
        "Sie sind momentan als Gast angemeldet. Geben Sie Ihren Namen, Ihre E-Mail-Adresse und Ihre Telefonnummer ein, um Stammkunde zu werden und in Zukunft personalisierten Service oder Rabatte in Ihrem Geschäft zu erhalten. Möchten Sie fortfahren?",
    alert_missing_account_info: "Please fill in the missing information: {missingInfo}",
    address_amount_exceeded:
        "Die Anzahl der Adressen in der Adressliste wurde überschritten. Sie können nur maximal 10 Adressen speichern.",
    i_will_do_it_later: "Ich werde es später tun",
    finish_later: "Später erledigen",
    skip: "Überspringen",
    change_by_email: "Per E-Mail",
    change_by_text_message: "Per SMS",
    change_by_existing_password: "Nach bestehendem Passwort",
    set_password: "Passwort festlegen",
    change_password: "Kennwort ändern",
    update_password: "Kennwort aktualisieren",
    code: "Verifizierungscode",
    request_pass_reset: "Passwort zurückfordern",
    confirm_password: "Kennwort bestätigen",
    set_a_password: "Kennwort festlegen",
    set_a_password_caption: "Um sich beim nächsten Mal schneller anzumelden, setzen Sie ein Kennwort.",
    set_a_new_password: "Setzen Sie ein neues Kennwort",
    set_a_new_password_caption:
        "Sie haben Ihr Passwort vergessen? Setzen Sie ein neues, um sich beim nächsten Mal schneller anzumelden.",
    update_password_fail: "Aktualisierung des Passworts fehlgeschlagen, versuchen Sie es später erneut",
    birthday: "Geburtstag",
    gender: "Geschlecht",
    not_provided: "Nicht angegeben",
    currency: "Währung",
    male: "Männlich",
    female: "Weiblich",
    keine: "Keine",
    Jahr: "Jahr",
    Monat: "Monat",
    day: "Tag",
    cannot_update_profile: "Profil kann nicht aktualisiert werden.",
    updated_profile: "Aktualisiertes Profil.",
    giftcards: "Geschenkkarten",
    gift_card_details: "Geschenkkarte Detail",
    all: "Alle",
    active: "Aktiv",
    inactive: "Inaktiv",
    active_giftcard: "Aktiv",
    inactive_giftcard: "Inaktiv",
    used: "Benutzt",
    store_name1: "Name des Geschäfts",
    balance: "Saldo",
    book_value: "Buchwert",
    purchase_date: "Kaufdatum",
    status: "Geschenkkarten-Status",
    reassign: "Neu zuweisen",
    reassign_giftcard: "Geschenkkarte neu zuweisen",
    receiver_email_phone: "E-Mail/Telefon des Empfängers",
    giftcard_reassign_success: "Ihre Geschenkkarte wurde erfolgreich an {receiver} übertragen.",
    giftcard_reassign_missing_receiver: "Bitte geben Sie die Empfängerinformationen an",
    giftcard_reassign_missing_info: "Sie müssen einen gültigen Empfänger haben, um die Geschenkkarte zu versenden.",
    giftcard_reassign_failed:
        "Es ist leider ein Fehler bei der Neuzuweisung Ihrer Geschenkkarte aufgetreten. Bitte versuchen Sie es später noch einmal.",
    giftcard_reassign_failed_phone:
        "Diese Telefonnummer gehört zu keinem registrierten Benutzer, bitte versuchen Sie es mit einer anderen Telefonnummer.",
    giftcard_reassign_failed_email:
        "Diese E-Mail gehört zu keinem registrierten Benutzer, bitte versuchen Sie eine andere E-Mail.",
    no_giftcards: "Sie haben keine Geschenkkarten",
    no_active_giftcards: "Sie haben keine aktiven Geschenkkarten",
    no_active_giftcards_head: "Keine aktiven Geschenkkarten",
    no_used_giftcards_head: "Keine benutzten Geschenkkarten",
    no_used_giftcards: "Sie haben keine Geschenkkarten verwendet",
    purchase_a_giftcard: "Kaufen Sie eine Geschenkkarte",
    invalid_phone:
        "Die Telefonnummer ist ungültig, bitte vergewissern Sie sich, dass die Telefonnummer die richtige Länge hat.",
    giftcard_reassign_tip:
        "Die Neuzuweisung einer Geschenkkarte ermöglicht es Ihnen, eine gekaufte Geschenkkarte an einen anderen Benutzer über dessen registrierte E-Mail oder Telefonnummer zu senden",
    order_number: "Bestellnummer",
    gift_card_purchased: "Geschenkkarte gekauft",
    paid_with_gift_card: "Bezahlt mit Geschenkkarte",
    refunded: "Erstattet",
    invalid_phone_number: "Ungültige Telefonnummer",
    Punkte: "Punkte",
    comment: "Kommentar",
    transactions: "Transaktionen",
    details: "Details",
    date: "Datum",
    balance_change: "Saldoänderung",
    points_balance: "Punktestand",
    no_points: "Sie haben derzeit keine Prämienpunkte. Kaufen Sie jetzt ein, um Punkte zu sammeln.",
    no_points_head: "Sie haben keine Punkte",
    view_stores: "Geschäfte anzeigen",
    last_updated: "Zuletzt aktualisiert: {time}",
    point_balance: "Saldo: {balance}",
    point_store_name: "Filialname: {name}",
    points_received: "Empfangene Punkte",
    payment_review1: "Überprüfung der Zahlung",
    canceled1: "Storniert",
    no_orders: "Es wurden noch keine Bestellungen aufgegeben",
    no_past_orders: "Keine vergangenen Bestellungen",
    no_upcoming_orders: "Keine bevorstehenden Bestellungen",
    shop_now: "Jetzt einkaufen",
    pickup_method: "Abholzeit",
    eatin_method: "Fälligkeitszeit",
    delivery_method: "Zustellzeit",
    instantCheckout_method: "Sofortige Kaufabwicklung",
    items: "Artikel",
    ongoing: "Laufend",
    history: "Geschichte",
    upcoming: "Demnächst",
    past_orders: "Vergangene Bestellungen",
    buzz_code: "Klingelnummer",
    make_default: "Als Standard festlegen",
    add_address: "Neue Adresse",
    add_a_address: "Eine neue Adresse hinzufügen",
    are_you_sure_delete_address: "Sind Sie sicher, dass Sie diese Adresse löschen wollen?",
    select_address: "Adresse auswählen",
    search_address: "Adresse suchen",
    current_location: "Aktueller Standort",
    use_name_on_account: "Name auf dem Konto verwenden",
    confirm_address: "Adresse bestätigen",
    location_user_denied: "Standortdienst ist deaktiviert, bitte aktivieren Sie den Standortdienst",
    geolocation_not_supported:
        "Aktuelle Standortdienste werden nicht unterstützt, bitte Adresse in der Adressleiste suchen",
    location_general_error:
        "Ihr aktueller Standort konnte nicht ermittelt werden, bitte versuchen Sie es später erneut",
    self_checkout: "Selbstkassierung",
    amount: "Betrag",
    add_comment: "Einen Kommentar hinzufügen",
    contact_merchant: "Händler kontaktieren",
    powered_by_goopter: "Bereitgestellt von Goopter",
    pay: "Bezahlen",
    self_checkout_disabled: "Quick Pay ist für dieses Geschäft nicht verfügbar.",
    quick_pay_hint_1: "Quick Pay lets you pay for your order anytime!",
    quick_pay_hint_2: "Just enter the amount due and include any information about this payment in the notes.",
    got_it: "Got It!",
    surcharge_for: "Aufpreis für die Bestellung: #{oid}",
    phone_number_verification: "Phone Number Verification",
    please_verify_number: "Please verify your number",
    verification_code_sent_message: "A text message with a 4-digit verification code was just sent to {phoneNumber}",
    enter_code: "Enter Code",
    try_different_number: "Try a different number",
    number_verified_message: "Your number has been verified!",
    continue_checkout_message: "Please continue with your checkout",
    get_vc_descprtion_text: "Wir senden Ihnen einen Verifizierungscode an Ihr Telefon",
    payment_success_title: "Zahlung erfolgreich",
    payment_success_text: "Ihre Zahlung ist eingegangen. Sie werden zur Bestellseite weitergeleitet.",
    go_to_order: "Gehe zur Bestellung",
    qr_scanner: "Zum Bestellen scannen",
    camera_instructor_title: "Kamerazugang erforderlich",
    camera_instructor_description_dine_in:
        "Die Goopter App wird als nächstes Ihre Kamera öffnen. Bitte richten Sie die Kamera auf den QR-Code, um mit der Bestellung fortzufahren.",
    camera_instructor_button_text_dine_in: "Scannen, um zu bestellen",
    camera_error_title: "Kamerazugriff verweigert",
    camera_error_description_dine_in:
        "Bitte erlauben Sie den Kamerazugriff, um den QR-Code für die Bestellung zu scannen.",
    camera_error_button_text: "Kamera freigeben",
    error_invalid_code_dine_in:
        "Ungültiger QR-Code für die Dine-in-Bestellung in diesem Geschäft. Bitte scannen Sie den richtigen QR-Code.",
    error_invalid_code_in_store:
        "Ungültiger QR-Code für eine In-Store-Bestellung in dieser Filiale. Bitte scannen Sie den richtigen QR-Code.",
    parking_lot: "Parkplatz #",
    kiosk: "Kiosk #",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "Tippen Sie auf das Kamerasymbol, um zu wechseln",
    scan_again: "Erneut versuchen",
    scanner_title: "QR-Code/Barcode zum Scannen im Rahmen ausrichten",
    orders_today: "Heutige Aufträge",
    orders_total: "Aufträge gesamt",
    points_today: "Punkte heute",
    points_total: "Punkte gesamt",
    check_how_many_reward_points_from_referrals:
        "Prüfen Sie, wie viele Prämienpunkte Sie durch Empfehlungen erhalten haben",
    no_referrals_message:
        "Sie haben noch keine Referrals. Fangen Sie an zu werben, um Prämienpunkte zu sammeln, die Sie in Ihren Lieblingsgeschäften ausgeben können!",
    order_id: "Bestellung #",
    no_referral_orders_found: "Keine Empfehlungsbestellungen gefunden",
    from: "From",
    pts: "pts",
    rate_the_product: "Rate The Product",
    please_input_or_select: "Please input or select your reason.",
    intimate: "Intimate",
    discount_by_without_price: "{x} off",
    people_ordered: "{amt} people ordered",
    pickup_locations_schedule: "Pick-up Locations / Schedule",
    scheduled_time: "Scheduled time",
    address_info: "Address Information",
    discount_message_without_price: "{discount} off",
    gift_cards: "Gift Cards",
    estimate_shipping_fee: "Estimate Shipping Fee",
    "secure-connection": "Information is sent over a secure connection",
    location_permission_is_blocked_service_may_not_perform_correctly:
        "Location permission is blocked, this service may be affected. ",
    credit_card_description: "Visa, Master Card, American Express",
    change_address: "Change Address",
    name_on_card: "Name on Card",
    none: "None",
    year: "Year",
    month: "Month",
    receivers_email_phone: "Receiver's Email / Phone",
    msg_country_update: "Country successfully updated.",
    msg_currency_update: "Currency successfully updated.",
    msg_birthday_update: "Birthday successfully updated.",
    msg_email_update: "Email successfully updated.",
    msg_gender_update: "Gender successfully updated.",
    msg_name_update: "Name successfuly updated",
    msg_nickname_update: "Nickname successfully updated",
    no_thanks: "No Thanks",
    verify: "Verify",
    number_already_exist:
        "This number has been registered with another account. Please logout and login with the number if you want to use this number to place an order. Alternatively you may use another phone number for your current account.",
    sign_in_with_number: "Sign in with existing number",
    try_another_number: "Try another number",
    add_instructions: "Add Instructions",
    code_not_revieved: "Didn't get code?",
    click_to_resend: "Click to resend.",
    review_recommend_store: "How likely are you to recommend {storeName} to a friend or family?",
    example_review_score: "Tell us a bit more about why you chose {score}",
    change_score: "Change score?",
    not_likely: "Not Likely",
    very_likely: "Very Likely",
    review_thank_you_recieve_coupon: "Thank you! You will recieve a coupon via text in about 1 min.",
    review_share_your_heart: "Would you be kind to share your heart to us?",
    review_copy_comment: "1. Simply copy the comment you wrote.",
    and_share_your_review: "2. And share your review.",
    thank_you: "Thank you!",
    review_thank_you_note: "If you don't receive the coupon text, reply “my-coupon” to view your coupons, Thank you!",
    share_to_google: "Share to Google",
    powered_by: "Powered by",
    quick_pay_self_serve_order_hins: "I have a self-serve order from Kiosk or QR code scan.",
    quick_pay_staff_order_hins: "A staff placed an order for me.",
    quick_pay_order_type_self_serve: "Self-serve order",
    quick_pay_order_type_staff: "Staff-assisted order",
    quick_pay_option_title: "What are you paying for?",
    quick_pay_order_type_no_order: "I do not have an order number",
    add_payment_note: "Add Payment Note",
    select: "Please Select",
    leave_quick_pay_confirm_msg: "Are you sure you want to leave quick pay?",
    self_serve_order_number: "Self-Serve Order Number",
    staff_assisted_order_number: "Staff Assisted Order Number",
    self_serve_order_number_input_placeholder: "Input self-serve order number",
    please_confirm: "Please Confirm",
    staff_assisted_order_number_input_placeholder: "Input staff assisted order number",
    invalid_order_id: "Invalid order #, please try again.",
    quick_pay_order_type_no_order_placeholder: "No order number is associated with this payment",
    quick_pay_order_id: "Payment for {orderType} #: {orderId}",
    quick_pay_confirm_msg: "Are your sure you want to pay for",
    quick_pay_confirm_msg_2: "The following order?",
    amount_due: "Amount Due",
    quick_pay_confirm_hin: "Please choose the ",
    quick_pay_confirm_hin_2: " order options if you have same order number but a different amount due.",
    staff_assisted: "staff assisted",
    quick_pay_order_type_additional_payment: "Partial or additional payment of a self-service order ",
    refunded_details: "Refund details",
    refund_reason: "Refund reason",
    deactivate_account: "Account deactivation & deletion",
    msg_deactivation: "Your account will be permanently deleted. After you delete your Goopter account, you will not be able to log in with your current account and retrieve any past orders or address records.",
    account_deactivated: "Account has been deactivated & deleted"
};

export default exports;
